import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import LandingPage from "./components/LandingPage";
import ShowProjects from "./components/ShowProjects";
import ClientDocs from "./components/ClientDocuments";
import ProjectDashboard from "./components/ProjectDashboard";
import ProjectDocuments from "./components/ProjectDocuments";
import ProjectProgress from "./components/ProjectProgress";
import ShowDeliverables from "./components/ShowDeliverables";
import AddProject from "./components/AddProject";
import AddDeliverable from "./components/AddDeliverable";
import UpdateProject from "./components/UpdateProject";
import UpdateDeliverable from "./components/UpdateDeliverable";
import Layout from "./components/Layout";

import Login from "./components/Auth/Login";
import Signup from "./components/Auth/Signup";
import Activate from "./components/Auth/Activate";
import ResetPassword from "./components/Auth/ResetPassword";
import ResetPasswordMail from "./components/Auth/ResetPasswordMail";
import ResetPasswordConfirm from "./components/Auth/ResetPasswordConfirm";

import "bootstrap/dist/css/bootstrap.min.css";
import theme from "./Theme";
import { ThemeProvider } from "@mui/material";
import PDFReader from "./components/PDFReader";
import RecycleBin from "./components/Recyclebin";
import PrivateRoutes from "./components/utils/PrivateRoutes";
import { AuthProvider } from "./components/context/AuthContext";
import MyProfile from "./components/Auth/MyProfile";
import ProjectChangeNotification from "./components/Home/PCN";
import UpdatePCN from "./components/UpdatePCN";
import AddPCN from "./components/AddPCN";
import Queries from "./components/Queries";
import Adjudicate from "./components/Adjudicate";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <AuthProvider>
          <Routes>
            {/* Routes for authentication */}
            <Route path="/login" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />
            <Route path="/check-mail" element={<ResetPasswordMail />} />
            <Route path="/signup" element={<Signup />} />

            <Route
              path="/password/reset/confirm/:uid/:token"
              element={<ResetPasswordConfirm />}
            />
            <Route
              path="/activate/:otp_code/:encoded_email"
              element={<Activate />}
            />

            {/* Private routes */}
            <Route element={<PrivateRoutes />}>
              <Route
                path="/"
                element={
                  <Layout>
                    <LandingPage />
                  </Layout>
                }
              />
              <Route
                path="/adjudicate"
                element={
                  <Layout>
                    <Adjudicate />
                  </Layout>
                }
              />

              <Route
                path="/projectProgress"
                element={
                  <Layout>
                    <ProjectProgress />
                  </Layout>
                }
              />
              <Route
                path="/projects"
                element={
                  <Layout>
                    <ShowProjects />
                  </Layout>
                }
              />
              <Route
                path="/projects/dashboard/:projectId/:projectType/clientdocuments"
                element={
                  <Layout>
                    <ClientDocs />
                  </Layout>
                }
              />
              <Route
                path="/projectchangenotification"
                element={
                  <Layout>
                    <ProjectChangeNotification />
                  </Layout>
                }
              />
              <Route
                path="/pcn/:id/update"
                element={
                  <Layout>
                    <UpdatePCN />
                  </Layout>
                }
              />
              <Route
                path="/pdfviewer"
                element={
                  <Layout>
                    <PDFReader />
                  </Layout>
                }
              />
              <Route
                path="/addPCN"
                element={
                  <Layout>
                    <AddPCN />
                  </Layout>
                }
              />
              <Route
                path="/recyclebin"
                element={
                  <Layout>
                    <RecycleBin />
                  </Layout>
                }
              />
              <Route
                path="/projects/dashboard/:projectId/:projectType"
                element={
                  <Layout>
                    <ProjectDashboard />
                  </Layout>
                }
              />
              <Route
                path="/showDocuments"
                element={
                  <Layout>
                    <ProjectDocuments />
                  </Layout>
                }
              />
              <Route
                path="/projects/dashboard/:projectId/:projectType/deliverables"
                element={
                  <Layout>
                    <ShowDeliverables />
                  </Layout>
                }
              />
              <Route
                path="/projects/dashboard/:projectId/queries"
                element={
                  <Layout>
                    <Queries />
                  </Layout>
                }
              />
              <Route
                path="/addProject"
                element={
                  <Layout>
                    <AddProject />
                  </Layout>
                }
              />
              <Route
                path="/deliverables/:projectId/addDeliverable"
                element={
                  <Layout>
                    <AddDeliverable />
                  </Layout>
                }
              />
              <Route
                path="/projects/:id/update/:projectType"
                element={
                  <Layout>
                    <UpdateProject />
                  </Layout>
                }
              />
              <Route
                path="/deliverables/:projectId/:id/update"
                element={
                  <Layout>
                    <UpdateDeliverable />
                  </Layout>
                }
              />

              <Route
                path="/myprofile"
                element={
                  <Layout>
                    <MyProfile />
                  </Layout>
                }
              />
            </Route>
          </Routes>
        </AuthProvider>
      </Router>
    </ThemeProvider>
  );
}

export default App;
