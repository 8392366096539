import React from "react";
import { Link as RouterLink } from "react-router-dom";
import { Grid, Paper, Typography } from "@mui/material";
import AppsIcon from "@mui/icons-material/Apps";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ElectricMeterIcon from "@mui/icons-material/ElectricMeter";
import ListAltIcon from "@mui/icons-material/ListAlt";
import RequestQuoteIcon from "@mui/icons-material/RequestQuote";
import GavelIcon from "@mui/icons-material/Gavel";
import HandymanIcon from "@mui/icons-material/Handyman";
import InfoIcon from "@mui/icons-material/Info";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import Inventory2Icon from "@mui/icons-material/Inventory2";
import FolderIcon from "@mui/icons-material/Folder";
import { TrackChanges } from "@mui/icons-material";

function LandingPage() {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" sx={{textAlign: 'center', marginBottom: 4}}>What would you like to do?</Typography>

      <Grid
        container
        spacing={3}
        style={{ width: "70%" }}
        justifyContent="start"
      >
        {/* Dashboard */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to=""
          >
            <Paper elevation={3}>
              <AppsIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Dashboard
          </Typography>
        </Grid>

        {/* Projects */}
        <Grid item xs={6} sm={4} md={3} lg={2} align={"center"}>
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/projects"
          >
            <Paper elevation={3}>
              <EngineeringIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Projects
          </Typography>
        </Grid>

        {/* Project Change Notification */}
        <Grid item xs={6} sm={4} md={3} lg={2} align={"center"}>
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/projectchangenotification"
          >
            <Paper elevation={3}>
              <TrackChanges sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            PCN
          </Typography>
        </Grid>

        {/* Instrument Index */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/instrumentIndex"
          >
            <Paper elevation={3}>
              <ElectricMeterIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Instrument Index
          </Typography>
        </Grid>

        {/* Datasheets */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/datasheets"
          >
            <Paper elevation={3}>
              <ListAltIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Datasheets
          </Typography>
        </Grid>

        {/* Request Quotation */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/requestQuotation"
          >
            <Paper elevation={3}>
              <RequestQuoteIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Request Quotation
          </Typography>
        </Grid>

        {/* Adjudicate */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/adjudicate"
          >
            <Paper elevation={3}>
              <GavelIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Adjudicate
          </Typography>
        </Grid>

        {/* Instruments */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/instruments"
          >
            <Paper elevation={3}>
              <HandymanIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Instruments
          </Typography>
        </Grid>

        {/* Vendor Documentation Requirements */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/vendorDocumentationRequirements"
          >
            <Paper elevation={3}>
              <InfoIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Vendor Documentation Requirements
          </Typography>
        </Grid>

        {/* Project Management */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/projectManagement"
          >
            <Paper elevation={3}>
              <TrendingUpIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Project Management
          </Typography>
        </Grid>

        {/* Suppliers */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/suppliers"
          >
            <Paper elevation={3}>
              <Inventory2Icon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Suppliers
          </Typography>
        </Grid>

        {/* Records */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to="/records"
          >
            <Paper elevation={3}>
              <FolderIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Records
          </Typography>
        </Grid>
      </Grid>
    </div>
  );
}

export default LandingPage;