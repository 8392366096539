import React, { useState } from 'react';
import {
  ChakraProvider,
  Box,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Button,
  Heading,
  VStack,
  SimpleGrid,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  useToast,
} from '@chakra-ui/react';
import axios from 'axios';

const SupplierFillForm = () => {
  const instrumentData = [
    { tag: '363-FIT-100', type: 'flowmeter - magnetic' },
    { tag: '363-FCV-100', type: 'Vortex' },
    { tag: '364-FI-101', type: 'flowmeter - magnetic' },
    { tag: '363-LIT-123', type: 'level switch Analyser' },
    { tag: '363-LIT-124', type: 'level switch Analyser' },
    { tag: '363-LIT-123', type: 'level switch Analyser' },
  ];

  const [instruments, setInstruments] = useState(
    instrumentData.map((inst, i) => ({
      id: i + 1,
      tag: inst.tag,
      type: inst.type,
      generalInfo: {
        temperature: { min: '', max: '', normal: '', comments: '' },
        pressure: { min: '', max: '', normal: '', comments: '' },
        flowRate: { min: '', max: '', normal: '', comments: '' },
      },
      processConditions: {
        nominalDiameter: '',
        processConnection: '',
        materialFlange: '',
        sealingFace: '',
        form: '',
      },
      line: {
        size: '',
        connection: '',
      },
      element: {
        body: {
          size: '',
          linerType: '',
          material: '',
        },
      },
      transmitter: {
        signal: '',
        calibrationRange: '',
        accuracy: '',
      },
      optionsAndTests: '',
    }))
  );

  const toast = useToast();

  const handleInputChange = (id, section, parameter, field, value) => {
    setInstruments((prev) =>
      prev.map((inst) =>
        inst.id === id
          ? {
              ...inst,
              [section]: {
                ...inst[section],
                [parameter]: {
                  ...inst[section][parameter],
                  [field]: value,
                },
              },
            }
          : inst
      )
    );
  };

  const handleSimpleInputChange = (id, section, field, value) => {
    setInstruments((prev) =>
      prev.map((inst) =>
        inst.id === id
          ? {
              ...inst,
              [section]: {
                ...inst[section],
                [field]: value,
              },
            }
          : inst
      )
    );
  };

  const handleSubmit = async () => {
    console.log('Data to be submitted:', instruments);
    try {
      const response = await axios.post('http://localhost:8080/api/supplier-fill-info/', instruments);
      console.log('Response from supplier:', response.data);
    } catch (error) {
      toast({
        title: 'Error submitting data',
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderInstrumentForm = (instrument) => (
    <Box key={instrument.id} mb={4} p={4} borderWidth={1} borderRadius="md" borderColor="blue.500">
      <Heading size="md" mb={4}>
        {instrument.tag}
      </Heading>
      <VStack spacing={4}>
        <Box>
          <Heading size="sm" mb={2}>
            General Information
          </Heading>
          <SimpleGrid columns={5} spacing={4}>
            {['temperature', 'pressure', 'flowRate'].map((param) => (
              <Box key={param}>
                <FormLabel>{param.charAt(0).toUpperCase() + param.slice(1)}</FormLabel>
                <VStack align="start" spacing={1}>
                  {['min', 'normal', 'max', 'comments'].map((field) => (
                    <Input
                      key={field}
                      placeholder={field.charAt(0).toUpperCase() + field.slice(1)}
                      value={instrument.generalInfo[param][field]}
                      onChange={(e) => handleInputChange(instrument.id, 'generalInfo', param, field, e.target.value)}
                    />
                  ))}
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box>
          <Heading size="sm" mb={2}>
            Process Conditions
          </Heading>
          <SimpleGrid columns={5} spacing={0.5} mb={2}>
            {['nominalDiameter', 'process Conn.', 'materialFlange', 'sealingFace', 'form'].map((param) => (
              <Box key={param}>
                <FormLabel>{param.charAt(0).toUpperCase() + param.slice(1)}</FormLabel>
                <VStack align="start" spacing={1}>
                  <Input
                    placeholder="Value"
                    value={instrument.processConditions[param]}
                    onChange={(e) => handleSimpleInputChange(instrument.id, 'processConditions', param, e.target.value)}
                  />
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </Box>
        <Box>
          <Heading size="sm" mb={2}>
            LINE
          </Heading>
          <SimpleGrid columns={2} spacing={4}>
            <Box>
              <FormLabel>Size</FormLabel>
              <Input
                placeholder="Size"
                value={instrument.line.size}
                onChange={(e) => handleSimpleInputChange(instrument.id, 'line', 'size', e.target.value)}
              />
            </Box>
            <Box>
              <FormLabel>Connection</FormLabel>
              <Input
                placeholder="Connection"
                value={instrument.line.connection}
                onChange={(e) => handleSimpleInputChange(instrument.id, 'line', 'connection', e.target.value)}
              />
            </Box>
          </SimpleGrid>
        </Box>
        <Box>
          <Heading size="sm" mb={2}>
            ELEMENT
          </Heading>
          <SimpleGrid columns={3} spacing={4}>
            <Box>
              <FormLabel>Body Size</FormLabel>
              <Input
                placeholder="Size"
                value={instrument.element.body.size}
                onChange={(e) => handleInputChange(instrument.id, 'element', 'body', 'size', e.target.value)}
              />
            </Box>
            <Box>
              <FormLabel>Liner Type</FormLabel>
              <Input
                placeholder="Liner Type"
                value={instrument.element.body.linerType}
                onChange={(e) => handleInputChange(instrument.id, 'element', 'body', 'linerType', e.target.value)}
              />
            </Box>
            <Box>
              <FormLabel>Material</FormLabel>
              <Input
                placeholder="Material"
                value={instrument.element.body.material}
                onChange={(e) => handleInputChange(instrument.id, 'element', 'body', 'material', e.target.value)}
              />
            </Box>
          </SimpleGrid>
        </Box>
        <Box>
          <Heading size="sm" mb={2}>
            TRANSMITTER
          </Heading>
          <SimpleGrid columns={3} spacing={4}>
            <Box>
              <FormLabel>Signal</FormLabel>
              <Input
                placeholder="Signal"
                value={instrument.transmitter.signal}
                onChange={(e) => handleSimpleInputChange(instrument.id, 'transmitter', 'signal', e.target.value)}
              />
            </Box>
            <Box>
              <FormLabel>Calibration Range</FormLabel>
              <Input
                placeholder="Calibration Range"
                value={instrument.transmitter.calibrationRange}
                onChange={(e) => handleSimpleInputChange(instrument.id, 'transmitter', 'calibrationRange', e.target.value)}
              />
            </Box>
            <Box>
              <FormLabel>Accuracy</FormLabel>
              <Input
                placeholder="Accuracy"
                value={instrument.transmitter.accuracy}
                onChange={(e) => handleSimpleInputChange(instrument.id, 'transmitter', 'accuracy', e.target.value)}
              />
            </Box>
          </SimpleGrid>
        </Box>
        <Box>
          <Heading size="sm" mb={2}>
            OPTION and TEST
          </Heading>
          <Input
            placeholder="Options and Tests"
            value={instrument.optionsAndTests}
            onChange={(e) => handleSimpleInputChange(instrument.id, 'optionsAndTests', e.target.value)}
          />
        </Box>
      </VStack>
    </Box>
  );

  const instrumentTypes = ['flowmeter - magnetic', 'Vortex', 'level switch Analyser'];

  return (
    <ChakraProvider>
      <Box p={5}>
        <Heading mb={6}>Instrument Details Form</Heading>
        <Tabs mt={5}>
          <TabList>
            {instrumentTypes.map((type, index) => (
              <Tab key={index}>{type}</Tab>
            ))}
          </TabList>
          <TabPanels>
            {instrumentTypes.map((type, index) => (
              <TabPanel key={index}>
                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={5}>
                  {instruments
                    .filter((instrument) => instrument.type === type)
                    .map(renderInstrumentForm)}
                </SimpleGrid>
              </TabPanel>
            ))}
          </TabPanels>
        </Tabs>
        <Button mt={4} colorScheme="blue" onClick={handleSubmit}>
          Submit Instrument Details
        </Button>
      </Box>
    </ChakraProvider>
  );
};

export default SupplierFillForm;
