import React, { useState, useEffect } from "react";
import useAxios from "./utils/useAxios";
import {
  Select,
  MenuItem,
  TextField,
  Button,
  CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const schema = yup.object().shape({
  pcn: yup.string().required("Project Change Notification is required"),
});

const AddPCN = () => {
  const api = useAxios();
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState("");
  const [pcn, setPCN] = useState("");
  const [projectAreas, setAreas] = useState("");
  const [comment, setComment] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProjects = async () => {
      try {
        const response = await api.get("/projects/");
        setProjects(response.data);
      } catch (error) {
        console.error("Error fetching projects:", error);
      }
    };

    fetchProjects();
  }, []);

  useEffect(() => {
    if (project && project.pcn !== null) {
      setPCN(project.pcn);
    } else {
      setPCN("");
    }

    if (project && project.projectAreas !== null) {
      setAreas(project.projectAreas);
    } else {
      setAreas("");
    }
  }, [project]);

  const CancelTransaction = () => {
    navigate("/projectchangenotification");
  };

  const addPCNInfo = async () => {
    setIsLoading(true); // Start loading

    try {
      await schema.validate(
        {
          project,
          pcn, // Access pcn from the project object
        },
        { abortEarly: false }
      );

      const PCNData = new FormData();
      PCNData.append("project", project.id); // Send only project id
      PCNData.append("pcn", project.pcn); // Access pcn from the project object
      PCNData.append("projectAreas", projectAreas);
      PCNData.append("comment", comment);

      const PCNResponse = await api.post(
        "/projectchangenotification/",
        PCNData
      );
      toast.success("Request sent successfully");
      navigate(`/projectchangenotification`);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      } else {
        toast.error("Error", error);
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100vw",
        position: "absolute",
        top: "72px",
      }}
    >
      <h2 className="flex w-full justify-center">Add Request</h2>
      <div className="flex flex-col items-center">
        <div className="w-4/5 md:w-1/3">
          <div className="flex flex-col justify-between">
            <div className="flex justify-between my-4">
              <Select
                value={project}
                onChange={(e) => setProject(e.target.value)}
                fullWidth
                required
                error={!!errors.project}
                helperText={errors.project}
              >
                <MenuItem value="">Select Project</MenuItem>
                {/* Render dropdown options */}
                {projects.map((project) => (
                  <MenuItem key={project.id} value={project}>
                    {project.projectNumber} - {project.projectName}
                  </MenuItem>
                ))}
              </Select>
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Project Change Notification"
                value={pcn}
                onChange={(e) => setPCN(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.pcn}
                helperText={errors.pcn}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Project Areas"
                value={projectAreas}
                onChange={(e) => setAreas(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.projectAreas}
                helperText={errors.projectAreas}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.comment}
                helperText={errors.comment}
              />
            </div>
          </div>
        </div>
        <div
          style={{
            width: "50vw",
            display: "flex",
            flexDirection: "row",
            margin: "10px",
            justifyContent: "space-evenly",
          }}
        >
          <Button variant="contained" color="error" onClick={CancelTransaction}>
            Cancel
          </Button>
          <Button
            onClick={addPCNInfo}
            variant="contained"
            disabled={isLoading} // Disable button when loading
          >
            {isLoading ? (
              <CircularProgress size={24} color="inherit" />
            ) : (
              "Send Request"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddPCN;
