import React from "react";
import { Link } from "react-router-dom";

const ProjectDocuments = () => {
  return (
    <div className="flex flex-col w-full absolute top-20 items-center">
      <div className="flex w-4/5 flex-col items-start p-2">
        <h1 className="text-3xl">Project Name/Number here</h1>
        <button className="btn btn-primary">Add New Document</button>
      </div>
      <div className="flex w-4/5 text-lg justify-start">
        <Link className="h-32 w-32 no-underline mx-4" to={""}>
          <div className="rounded flex w-full h-full items-center justify-center shadow "></div>
          <div className="flex flex-wrap text-black">Doc 1</div>
        </Link>
        <Link className="h-32 w-32 no-underline mx-4" to={""}>
          <div className="rounded flex w-full h-full items-center justify-center shadow "></div>
          <div className="flex flex-wrap text-black">Doc 2</div>
        </Link>
        <Link className="h-32 w-32 no-underline mx-4" to={""}>
          <div className="rounded flex w-full h-full items-center justify-center shadow "></div>
          <div className="flex flex-wrap text-black">Doc 3</div>
        </Link>
      </div>
    </div>
  );
};

export default ProjectDocuments;
