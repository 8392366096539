import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useAxios from "./utils/useAxios";
import {
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
} from "@mui/material";
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
  name: yup.string().required("Deliverable Name is required"),
  description: yup.string().required("Description is required"),
});

const UpdateDeliverable = () => {
  const api = useAxios();
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(""); // Added state for file name
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [voteNo, setVoteNo] = useState("");
  const [invoicingProgress, setInvoicingProgress] = useState("");
  const [description, setDescription] = useState("");
  const [statuses, setStatuses] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [revNumber, setRevNumber] = useState("");
  const [selectedDisciplineType, setSelectedDisciplineType] = useState("");
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const { id, projectId } = useParams();

  const loadDeliverables = async () => {
    const { data } = await api.get(
      `/deliverables/${id}/`
    );
    console.log(data);

    setName(data.name);
    setArea(data.area);
    setVoteNo(data.voteNo);
    setInvoicingProgress(data.invoicingProgress);
    setDescription(data.description);
    setSelectedDisciplineType(data.disciplineType);
    setSelectedType(data.deliverableType);
    setSelectedStatus(data.status);
    setRevNumber(data.revNumber);
  };

  useEffect(() => {
    loadDeliverables();
  }, []);

  const CancelTransaction = () => {
    navigate(`/projects/dashboard/${projectId}/open/deliverables`);
  };
  const [isLoading, setIsLoading] = useState(false);

  const updateDeliverableInfo = async () => {
    setIsLoading(true);
    try {
      await schema.validate(
        {
          name,
          area,
          description,
          selectedDisciplineType,
          selectedType,
          selectedStatus,
          revNumber,
        },
        { abortEarly: false }
      );

      const formField = new FormData();
      formField.append("project", projectId);
      formField.append("name", name);
      formField.append("area", area);
      formField.append("voteNo", voteNo);
      formField.append("invoicingProgress", invoicingProgress);
      formField.append("description", description);
      formField.append("disciplineType", selectedDisciplineType);
      formField.append("deliverableType", selectedType);
      formField.append("status", selectedStatus);
      formField.append("revNumber", revNumber);

      // Append file to form data if it's not null
      if (file !== null) {
        formField.append("file", file);
      }

      const response = await api.put(
        `/deliverables/${id}/`,
        formField
      );
      console.log(response.data);
      toast.success("Update Success!");
      navigate(`/projects/dashboard/${projectId}/open/deliverables`);
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      } else {
        toast.error("Update Failure!");
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const fetchedStatuses = ["Not Started", "IDR", "ICR", "IFC", "As Built"];
    setStatuses(fetchedStatuses);
  }, []);

  // Function to handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <h2 className="flex w-full justify-center">Update Deliverable</h2>
      <div className="flex w-full justify-center">
        <div className="w-4/5 md:w-1/3">
          <div className="flex flex-col justify-between">
            <div className="flex justify-between my-2">
              <TextField
                label="Deliverable Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.name}
                helperText={errors.name}
              />
            </div>
            <div className="flex justify-between my-2">
              {/* Button to trigger file selection */}
              <label htmlFor="fileInput">
                <Button
                  variant="contained"
                  component="span"
                  style={{ backgroundColor: "#007DAB", color: "white" }}
                >
                  Upload File
                </Button>
              </label>
              {/* Display selected file name */}
              {file && <div className="">{fileName}</div>}
              {/* Hidden file input triggered by button click */}
              <input
                type="file"
                accept=".pdf,.doc,.docx"
                className="p-2 w-1/2 h-10 focus:outline-none"
                onChange={handleFileChange}
                style={{ display: "none" }}
                id="fileInput"
              />
            </div>
            <div className="flex justify-between my-2">
              <TextField
                label="Area"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.area}
                helperText={errors.area}
              />
            </div>
            <div className="flex justify-between my-2">
              <TextField
                label="Vote Number"
                value={voteNo}
                onChange={(e) => setVoteNo(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.voteNo}
                helperText={errors.voteNo}
              />
            </div>
            <div className="flex justify-between my-2">
              <TextField
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.description}
                helperText={errors.description}
              />
            </div>
            <div className="flex justify-between my-2">
              <TextField
                label="Invoicing Progress"
                value={invoicingProgress}
                onChange={(e) => setInvoicingProgress(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.invoicingProgress}
                helperText={errors.invoicingProgress}
              />
            </div>
            <div className="flex justify-between my-2">
              <FormControl fullWidth>
                <InputLabel>Status</InputLabel>
                <Select
                  value={selectedStatus}
                  onChange={(e) => setSelectedStatus(e.target.value)}
                >
                  {statuses.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="flex justify-between my-2">
              <TextField
                label="Revision Number"
                value={revNumber}
                onChange={(e) => setRevNumber(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.revNumber}
                helperText={errors.revNumber}
              />
            </div>
            <div className="flex justify-around my-4">
              <Button
                onClick={CancelTransaction}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Button
          onClick={updateDeliverableInfo}
          variant="contained"
          disabled={isLoading} // Disable button when loading
        >
          {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Update Deliverable'}
        </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDeliverable;
