import React from "react";
import Switch from "@mui/material/Switch";
import { Brightness4 as MoonIcon } from "@mui/icons-material";

function MoonSwitch({ checked, onChange }) {
  return (
    <Switch
      checked={checked}
      onChange={onChange}
      icon={<MoonIcon />}
      checkedIcon={<MoonIcon />}
    />
  );
}

export default MoonSwitch;
