import React from 'react';
import { ChakraProvider, Box } from '@chakra-ui/react';

const alphabet = Array.from({ length: 26 }, (_, index) => String.fromCharCode(65 + index));

const GridGrouping = () => {
  return (
    <ChakraProvider>
      <Box p={4} overflowX="auto">
        <table style={{ width: '100%' }}>
          <thead>
            <tr>
              {alphabet.map(letter => (
                <th key={letter} style={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                  Large Column  {letter}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            <tr>
              {alphabet.map((letter, index) => (
                <td key={index} style={{ minWidth: '150px', whiteSpace: 'nowrap' }}>
                  Column Data {letter}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </Box>
    </ChakraProvider>
  );
};

export default GridGrouping
