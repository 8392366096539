import React from 'react';
import {
  ChakraProvider,
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  VStack,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import axios from "axios";
import { Formik, Form, Field, FieldArray } from 'formik';
import * as Yup from 'yup';
import { AddIcon, MinusIcon } from '@chakra-ui/icons';
import useAxios from './utils/useAxios';
const validationSchema = Yup.object().shape({
  suppliers: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required('Name is required'),
        email: Yup.string().email('Invalid email').required('Email is required'),
        companyName: Yup.string().required('Company name is required'),
      })
    )
    .required('Must have suppliers')
    .min(1, 'Must have at least one supplier'),
});

const SupplierForm = () => {
  const api = useAxios();
  const apiURL = '/supplier'; // Replace with your actual API endpoint

  const handleSubmit = async (values) => {
    try {
      const response = await api.post(apiURL, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values.suppliers),
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Invitations sent to:', data);
      } else {
        console.error('Error sending invitations:', response.statusText);
      
      }
    } catch (error) {
      console.error('Error sending invitations:', error);
      console.log(values)
    }
  };

  return (
    <ChakraProvider>
      <Box p={4}>
        <Formik
          initialValues={{ suppliers: [{ name: '', email: '', companyName: '' }] }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ values, errors, touched, isSubmitting }) => (
            <Form>
              <FieldArray name="suppliers">
                {({ insert, remove, push }) => (
                  <VStack spacing={4}>
                    {values.suppliers.map((supplier, index) => (
                      <Box
                        key={index}
                        borderWidth="1px"
                        borderRadius="lg"
                        p={4}
                        width="100%"
                      >
                        <HStack spacing={4} alignItems="flex-start">
                          <Field name={`suppliers.${index}.name`}>
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.suppliers &&
                                  form.errors.suppliers[index] &&
                                  form.errors.suppliers[index].name &&
                                  form.touched.suppliers &&
                                  form.touched.suppliers[index] &&
                                  form.touched.suppliers[index].name
                                }
                              >
                                <FormLabel htmlFor={`suppliers.${index}.name`}>
                                  Name
                                </FormLabel>
                                <Input
                                  {...field}
                                  id={`suppliers.${index}.name`}
                                  placeholder="Name"
                                />
                              </FormControl>
                            )}
                          </Field>
                          <Field name={`suppliers.${index}.email`}>
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.suppliers &&
                                  form.errors.suppliers[index] &&
                                  form.errors.suppliers[index].email &&
                                  form.touched.suppliers &&
                                  form.touched.suppliers[index] &&
                                  form.touched.suppliers[index].email
                                }
                              >
                                <FormLabel htmlFor={`suppliers.${index}.email`}>
                                  Email
                                </FormLabel>
                                <Input
                                  {...field}
                                  id={`suppliers.${index}.email`}
                                  placeholder="Email"
                                />
                              </FormControl>
                            )}
                          </Field>
                          <Field name={`suppliers.${index}.companyName`}>
                            {({ field, form }) => (
                              <FormControl
                                isInvalid={
                                  form.errors.suppliers &&
                                  form.errors.suppliers[index] &&
                                  form.errors.suppliers[index].companyName &&
                                  form.touched.suppliers &&
                                  form.touched.suppliers[index] &&
                                  form.touched.suppliers[index].companyName
                                }
                              >
                                <FormLabel htmlFor={`suppliers.${index}.companyName`}>
                                  Company Name
                                </FormLabel>
                                <Input
                                  {...field}
                                  id={`suppliers.${index}.companyName`}
                                  placeholder="Company Name"
                                />
                              </FormControl>
                            )}
                          </Field>
                          <IconButton
                            icon={<MinusIcon />}
                            onClick={() => remove(index)}
                            variant="outline"
                            colorScheme="red"
                            aria-label="Remove supplier"
                          />
                        </HStack>
                      </Box>
                    ))}
                    <Button
                      leftIcon={<AddIcon />}
                      onClick={() =>
                        push({ name: '', email: '', companyName: '' })
                      }
                    >
                      Add Supplier
                    </Button>
                  </VStack>
                )}
              </FieldArray>
              <Button
                mt={4}
                colorScheme="blue"
                isLoading={isSubmitting}
                onClick={handleSubmit}
              >
                Send Invitations
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </ChakraProvider>
  );
};

export default SupplierForm;
