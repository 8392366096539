import React, { useContext } from "react";
import loginLogo from "../images/AdobeStock_306624047.jpeg";
import logo from "../images/logo.png";
import { Link as RouterLink } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Grid,
  InputLabel,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../../components/FormComponents/TextFieldWrapper";
import AuthContext from '../context/AuthContext';
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Login = () => {
  const navigate = useNavigate();
  const {loginUser} = useContext(AuthContext)
 
  const handleLogin = async (values) => {
    try {
      const lowercaseEmail = values.email.toLowerCase(); // Convert email to lowercase
      await loginUser(lowercaseEmail, values.password);
      // Redirect to home page or any other route after successful login
      navigate("/");
    } catch (error) {
      // Show error message using toast
      toast.error("Email or Password is wrong!");
    }
  };
  

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", backgroundColor: "secondary.main" }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${loginLogo})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} sx={{height: "100vh"}} component={Paper} elevation={6} square>
      <Grid>
      <Box
          sx={{
            my: 8,
            mx: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <img src={logo} alt="Logo" width={"70%"} height={120} />

          <Typography component="h1" variant="h5">
              Login to your Account
            </Typography>
          <Stack width="100%">
            <Formik
              initialValues={{
                email: "",
                password: "",
              }}
              validationSchema={Yup.object().shape({
                email: Yup.string().required("Please enter your email"),
                password: Yup.string().required("Password required"),
              })}
              onSubmit={(values) => {
                handleLogin(values);
              }}
            >
              {() => {
                return (
                  <Form>
                    <Grid container spacing={2}>
                      <Grid item xs={12} md={12}>
                        <InputLabel sx={{ mb: 1 }}>Email Address</InputLabel>
                        <TextFieldWrapper name="email" label="Email" />
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <InputLabel sx={{ mb: 1 }}>Password</InputLabel>
                        <TextFieldWrapper
                          name="password"
                          type="password"
                          label="Password"
                        />
                      </Grid>

                      <Grid item xs={12} md={12}>
                        <Button
                          variant="contained"
                          sx={{
                            width: "100%",
                            backgroundColor: "secondary.main",
                          }}
                          type="submit"
                          // onClick={handleLogin}
                        >
                          Login
                        </Button>
                      </Grid>
                      <Grid item xs={12} md={12}>
                        <Stack direction="row" spacing={1}>
                          <Typography
                            sx={{ color: "secondary.main", cursor: "pointer" }}
                            onClick={() => navigate("/signup")}
                          >
                            Register
                          </Typography>
                          <Divider
                            orientation="vertical"
                            flexItem
                            sx={{
                              fontWeight: "bolder",
                              border: 1,
                              color: "secondary.main",
                            }}
                          />
                          <Typography
                            component={RouterLink}
                            to={`/reset-password`}
                            sx={{ color: "secondary.main", cursor: "pointer", textDecoration: 'none' }}
                          >
                            Forgot Password?
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Form>
                );
              }}
            </Formik>
          </Stack>
        </Box>
      </Grid>
      <Typography 
        variant="h7" 
        sx={{
          position: "absolute", 
          bottom: "0",
          left: "80%",
          display: "flex",
          textAlign: "center"
        }}
      >
        <a href="mailto:samkelom@frequencyshift.co.za?subject=Support Request&cc=makhabanem@frequencyshift.co.za" style={{ textDecoration: "none", color: "#007DAB" }}>Support</a>
      </Typography>

      </Grid>
    </Grid>
  );
};

export default Login;
