import { Box } from "@mui/material";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";

function BasicBreadcrumbs() {
  const location = useLocation();
  const pathnames = location.pathname.split("/").filter((x) => x);

  // State to manage hover effect
  const [hoveredLink, setHoveredLink] = useState(null);

  // Define excluded paths using regular expressions
  const excludedPaths = [
    /^\/$/,
    /^\/myprofile$/,
    /^\/addProject$/,
    /^\/deliverables\/[^/]+\/addDeliverable$/,
    /^\/projects\/[^/]+\/update$/,
    /^\/deliverables\/[^/]+\/[^/]+\/update$/,
    /^\/pcn\/[^/]+\/update$/,
    /^\/addPCN$/,
    /^\/projects\/dashboard\/[^/]+\/queries$/,
  ];

  // Check if the current path is excluded
  const isExcludedPath = excludedPaths.some((path) => path.test(location.pathname));

  // If on excluded paths or home page, don't render breadcrumbs
  if (pathnames.length === 0 || isExcludedPath) {
    return null;
  }

  // Remove IDs and specific segments like "open" from pathnames
  const filteredPathnames = pathnames.filter((pathname, index) => {
    // If current segment is numeric (ID) or "open" or "closed", exclude it
    if (/^\d+$/.test(pathname) || pathname === "open" || pathname === "closed") {
      return false;
    }
    // If previous segment is numeric (ID), and this segment is not "open", exclude it
    if (index > 0 && /^\d+$/.test(pathnames[index - 1])) {
      return false;
    }
    return true;
  });

  return (
    <Box component="nav" aria-label="breadcrumb" sx={{ flexGrow: 1, p: 3 }}>
      <Link
        style={{
          textDecoration: "none",
          color: "#3498DB",
          fontSize: "18px",
          padding: "2px 5px",
          borderRadius: "3px",
          backgroundColor: hoveredLink === "home" ? "#D6EAF8" : "transparent",
        }}
        to="/"
        onMouseEnter={() => setHoveredLink("home")}
        onMouseLeave={() => setHoveredLink(null)}
      >
        Home
      </Link>
      {filteredPathnames.map((pathname, index) => {
        const routeTo = `/${filteredPathnames.slice(0, index + 1).join("/")}`;
        const isLast = index === filteredPathnames.length - 1;
        const capitalizedPathname = pathname.charAt(0).toUpperCase() + pathname.slice(1); // Capitalizing pathname
        // Define the correct URL for Dashboard
        const projectIdIndex = pathnames.findIndex(item => /^\d+$/.test(item)); // Find the index of the project ID
        const status = pathnames[projectIdIndex + 1]; // Get the status ('open' or 'closed')
        const dashboardUrl = projectIdIndex !== -1 ? `/projects/dashboard/${pathnames[projectIdIndex]}/${status}` : null;
        return (
          <React.Fragment key={index}>
            <span style={{ fontSize: "18px" }}> / </span>
            {isLast ? (
              <span style={{ fontSize: "18px" }}>{capitalizedPathname}</span>
            ) : (
              <Link
                style={{
                  textDecoration: "none",
                  color: "#3498DB",
                  fontSize: "18px",
                  padding: "2px 5px",
                  borderRadius: "3px",
                  backgroundColor:
                    hoveredLink === index.toString() ? "#D6EAF8" : "transparent",
                }}
                to={pathname === "dashboard" && dashboardUrl ? dashboardUrl : routeTo}
                onMouseEnter={() => setHoveredLink(index.toString())}
                onMouseLeave={() => setHoveredLink(null)}
              >
                {capitalizedPathname}
              </Link>
            )}
          </React.Fragment>
        );
      })}
    </Box>
  );
}

export default BasicBreadcrumbs;
