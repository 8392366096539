import axios from "axios";
import { Button, CircularProgress, Grid, InputLabel, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import TextFieldWrapper from '../FormComponents/TextFieldWrapper';
import { useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../images/logo.png";

const ResetPassword = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // State for loading

    const requestPasswordReset = async (values) => {        
        setLoading(true); // Set loading state to true when request starts
        try {
            const lowercaseEmail = values.email.toLowerCase(); // Convert email to lowercase
            const response = await axios.post(`https://3ebingo.co.za/api/password-reset/`, {
                ...values,
                email: lowercaseEmail, // Replace email with the lowercase version
            });
            if (response.statusText === "OK") {
                toast.success("Check your email for instructions");
                navigate("/check-mail");
            }
        } catch (error) {
            toast.error(error.response.data.error);
        } finally {
            setLoading(false); // Set loading state to false when request completes
        }
    };
  
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <img src={logo} alt="Logo" width={"30%"} marginBottom={10} />
            <Typography variant="h6" gutterBottom>
                Request Password Reset.
            </Typography>
            <Formik
                initialValues={{
                    email: ""
                }}
                validationSchema={Yup.object().shape({
                    email: Yup.string().required("This field cannot be empty")
                })}
                onSubmit={(values) => {
                    requestPasswordReset(values);
                }}
            >
                {() => (
                    <Form style={{ width: '100%' }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs={12} sx={{flexDirection: 'column', width: "30vw"}}>
                                <InputLabel sx={{ mb: 1 }}>Email</InputLabel>
                                <TextFieldWrapper
                                    name="email"
                                    label="Email address"
                                    type="email"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{backgroundColor: "#007DAB"}}
                                    disabled={loading} // Disable button when loading
                                    startIcon={loading ? <CircularProgress size={20} color="inherit" /> : null} // Show loading indicator
                                >
                                    Reset Password
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};

export default ResetPassword;
