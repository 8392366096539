import React, { useState, useRef } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import workerSrc from 'pdfjs-dist/build/pdf.worker.min.js';
import { useLocation } from 'react-router-dom';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RotateRightIcon from '@mui/icons-material/RotateRight';
import GetAppIcon from '@mui/icons-material/GetApp';
import { IconButton } from '@mui/material';
import Paper from '@mui/material/Paper';

pdfjs.GlobalWorkerOptions.workerSrc = workerSrc;

const PDFReader = () => {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber] = useState(1);
  const [scale, setScale] = useState(1.3);
  const [rotation, setRotation] = useState(0);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const fileUrl = searchParams.get('fileUrl');
  const containerRef = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    console.log('file: ', fileUrl)
  };

  const handleZoomIn = () => {
    setScale(scale + 0.3);
  };

  const handleZoomOut = () => {
    setScale(scale - 0.3);
  };

  const handleRotateRight = () => {
    setRotation((rotation + 90) % 360);
  };

  const handleDownload = () => {
    const fileName = fileUrl.split("/").pop();
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleReset = () => {
    setRotation(0);
    setScale(1.3);
  }

  return (
    <div
      ref={containerRef}
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "center",
      }}
    >
      <Paper elevation={2} sx={{width: 'auto', alignItems: 'center', display: 'flex', marginBottom: '30px', position: 'sticky', top: '70px', zIndex: '1', paddingX: '15px'}}>
        <IconButton onClick={handleZoomIn}>
          <ZoomInIcon fontSize="large" />
        </IconButton>
        <IconButton onClick={handleZoomOut}>
          <ZoomOutIcon fontSize="large" />
        </IconButton>
        <IconButton onClick={handleRotateRight}>
          <RotateRightIcon fontSize="large" />
        </IconButton>
        <IconButton onClick={handleDownload}>
          <GetAppIcon fontSize="large" />
        </IconButton>
        <p>Page {pageNumber} of {numPages}</p>
        <IconButton variant="contained" color='error' onClick={handleReset}>
          <p>Reset</p>
        </IconButton>
      </Paper>
      <div style={{ width: "90%", height: "80vh" }}>
        <Document
          file={fileUrl}
          onLoadSuccess={onDocumentLoadSuccess}
        >
          {Array.from({ length: numPages }, (_, i) => i + 1).map((page) => (
            <Page 
            key={page}
            pageNumber={page} 
            renderTextLayer={false} 
            renderAnnotationLayer={false} 
            scale={scale}
            rotate={rotation}
          />
          ))}
        </Document>
      </div>
    </div>
  );
};

export default PDFReader;
