import React, { useState, useEffect } from "react";
import useAxios from "./utils/useAxios";
import {
  Tabs,
  Tab,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Checkbox,
} from "@mui/material";
import {
  Delete,
  DeleteForeverOutlined,
  Folder,
  Note,
  Restore,
} from "@mui/icons-material";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import emptyBin from "./images/emptyBin.png";

const RecycleBin = () => {
  const api = useAxios();
  const [deliverables, setDeliverables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [openIndividualDialog, setOpenIndividualDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0); // State to manage active tab
  const [projects, setProjects] = useState([]);
  const [selectedDeliverableRows, setSelectedDeliverableRows] = useState([]);
  const [selectedProjectRows, setSelectedProjectRows] = useState([]);

  const handleRowClick = (id, type) => {
    let newSelectedRows = [];

    if (type === "deliverable") {
      newSelectedRows = [...selectedDeliverableRows];
      const selectedIndex = newSelectedRows.indexOf(id);
      if (selectedIndex === -1) {
        newSelectedRows.push(id);
      } else {
        newSelectedRows.splice(selectedIndex, 1);
      }
      setSelectedDeliverableRows(newSelectedRows);
    } else if (type === "project") {
      newSelectedRows = [...selectedProjectRows];
      const selectedIndex = newSelectedRows.indexOf(id);
      if (selectedIndex === -1) {
        newSelectedRows.push(id);
      } else {
        newSelectedRows.splice(selectedIndex, 1);
      }
      setSelectedProjectRows(newSelectedRows);
    }
  };

  const isSelected = (id, type) => {
    if (type === "deliverable") {
      return selectedDeliverableRows.indexOf(id) !== -1;
    } else if (type === "project") {
      return selectedProjectRows.indexOf(id) !== -1;
    }
    return false;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await api.get(`/projectRecycleBin/`);
        setProjects(projectResponse.data);
        const deliverablesResponse = await api.get(`/deliverableRecycleBin/`);
        setDeliverables(deliverablesResponse.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const deleteAll = async () => {
    try {
      for (let i = 0; i < deliverables.length; i++) {
        const deliverableId = deliverables[i].id;
        await api.delete(`/deliverableRecycleBin/${deliverableId}/`);
        console.log(`Deliverable ${deliverables[i].id} deleted successfully`);
        setDeliverables((prevDeliverables) =>
          prevDeliverables.filter(
            (deliverable) => deliverable.id !== deliverableId
          )
        );
      }
      for (let i = 0; i < projects.length; i++) {
        const projectId = projects[i].id;
        await api.delete(`/projectRecycleBin/${projectId}/`);
        console.log(`Project ${projects[i].id} deleted successfully`);
        setProjects((prevProjects) =>
          prevProjects.filter((project) => project.id !== projectId)
        );
      }
      toast.success("Delete success!");
      setDeliverables([]); // Clear the deliverables array after deletion
      setProjects([]);
    } catch (error) {
      console.error("Error emptying recycle bin:", error);
      toast.error("Delete Failed!");
    }
  };

  const deleteElement = async () => {
    // Permanently delete selected deliverables
    await Promise.all(
      selectedDeliverableRows.map(async (deliverableId) => {
        try {
          await api.delete(`/deliverableRecycleBin/${deliverableId}/`, {
            data: { is_deleted: false },
          });
          // Remove the restored deliverable from the deleted deliverables list
          setDeliverables((prevDeliverables) =>
            prevDeliverables.filter(
              (deliverable) => deliverable.id !== deliverableId
            )
          );
          toast.success("Delete success!");
        } catch (error) {
          toast.error(error);
        }
      })
    );

    // Permanently delete selected projects
    await Promise.all(
      selectedProjectRows.map(async (projectId) => {
        try {
          await api.delete(`/projectRecycleBin/${projectId}/`, {
            data: { is_deleted: false },
          });
          // Remove the restored project from the deleted projects list
          setProjects((prevProjects) =>
            prevProjects.filter((project) => project.id !== projectId)
          );
          toast.success("Delete success!");
        } catch (error) {
          toast.error(error);
        }
      })
    );
  };

  const handleEmptyRecycleBin = () => {
    setOpenDialog(true);
  };

  const handleDeleteElement = () => {
    setOpenIndividualDialog(true);
  };

  const handleConfirmDelete = () => {
    deleteAll();
    setOpenDialog(false);
  };

  const handleCancelDelete = () => {
    setOpenDialog(false);
    setOpenIndividualDialog(false);
  };

  const handleConfirmDeleteElement = () => {
    deleteElement();
    setOpenIndividualDialog(false);
  };

  const restore = () => {
    // Restore selected deliverables
    selectedDeliverableRows.forEach((deliverableId) => {
      api
        .patch(`/deliverableRecycleBin/${deliverableId}/`, {
          is_deleted: false,
        })
        .then((res) => {
          // Remove the restored deliverable from the deleted deliverables list
          toast.success(res);
          setDeliverables(
            deliverables.filter(
              (deliverable) => deliverable.id !== deliverableId
            )
          );
        })
        .catch((err) => toast.error(err));
    });

    // Restore selected projects
    selectedProjectRows.forEach((projectId) => {
      api
        .patch(`/projectRecycleBin/${projectId}/`, { is_deleted: false })
        .then((res) => {
          toast.success(res);
          // Remove the restored project from the deleted projects list
          setProjects(projects.filter((project) => project.id !== projectId));
        })
        .catch((err) => toast.error(err));
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <Paper
        elevation={0}
        sx={{
          width: "90vw",
          alignItems: "center",
          display: "flex",
          marginBottom: "30px",
          top: "70px",
          paddingX: "15px",
        }}
      >
        <Box>
          {deliverables.length === 0 && projects.length === 0 ? null : (
            <>
              {selectedDeliverableRows.length === 0 &&
              selectedProjectRows.length === 0 ? (
                <Button variant="text" onClick={handleEmptyRecycleBin}>
                  <DeleteForeverOutlined
                    color="error"
                    sx={{ marginX: "4px" }}
                  />{" "}
                  Empty Recycle Bin
                </Button>
              ) : (
                <>
                  <Button variant="text" onClick={() => handleDeleteElement()}>
                    <Delete color="error" /> Delete
                  </Button>
                  <Button variant="text" onClick={() => restore()}>
                    <Restore /> Restore
                  </Button>
                </>
              )}
            </>
          )}
        </Box>
      </Paper>

      <Tabs
        value={tabValue}
        onChange={(e, newValue) => setTabValue(newValue)}
        style={{ width: "80%" }}
      >
        <Tab label="Deliverables" />
        <Tab label="Projects" />
      </Tabs>
      {/* Render content based on active tab */}
      {tabValue === 0 ? (
        <>
          {loading ? (
            <p>
              <CircularProgress />
            </p>
          ) : (
            <div style={{ width: "80%", marginTop: "10px" }}>
              <Box component="section" sx={{ p: 2, fontStyle: "bold" }}>
                Recycle Bin
              </Box>
              <TableContainer elevation={0} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedDeliverableRows.length > 0 &&
                            selectedDeliverableRows.length < deliverables.length
                          }
                          checked={
                            deliverables.length > 0 &&
                            selectedDeliverableRows.length ===
                              deliverables.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newSelectedRows = deliverables.map(
                                (row) => row.id
                              );
                              setSelectedDeliverableRows(newSelectedRows);
                            } else {
                              setSelectedDeliverableRows([]);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Deliverable
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Date Deleted
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Deleted by
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Created by
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {deliverables.map((deliverable) => (
                      <TableRow
                        key={deliverable.id}
                        hover
                        onClick={() =>
                          handleRowClick(deliverable.id, "deliverable")
                        }
                        role="checkbox"
                        selected={isSelected(deliverable.id, "deliverable")}
                        aria-checked={isSelected(deliverable.id, "deliverable")}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(deliverable.id, "deliverable")}
                            onChange={(e) =>
                              handleRowClick(deliverable.id, "deliverable")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Note sx={{ marginRight: "4px" }} />
                            {deliverable.name}
                          </Box>
                        </TableCell>
                        <TableCell>{deliverable.deleted_at}</TableCell>
                        <TableCell>{deliverable.deleted_by}</TableCell>
                        <TableCell>{deliverable.created_by}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5vw",
                  minHeight: "100vh", // Ensure the div takes the full height of the viewport
                }}
              >
                {deliverables.length === 0 ? (
                  <div style={{}}>
                    <img
                      src={emptyBin}
                      style={{ width: "10vw" }}
                      alt="empty bin"
                    />
                    <p style={{ fontWeight: "900px", marginTop: "10px" }}>
                      Your deliverable bin is empty
                    </p>
                    <p className="font-thin">There is nothing to recycle</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <p>
              <CircularProgress />
            </p>
          ) : (
            <div style={{ width: "80%", marginTop: "10px" }}>
              <Box component="section" sx={{ p: 2, fontStyle: "bold" }}>
                Recycle Bin
              </Box>
              <TableContainer elevation={0} component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell padding="checkbox">
                        <Checkbox
                          indeterminate={
                            selectedProjectRows.length > 0 &&
                            selectedProjectRows.length < projects.length
                          }
                          checked={
                            projects.length > 0 &&
                            selectedProjectRows.length === projects.length
                          }
                          onChange={(e) => {
                            if (e.target.checked) {
                              const newSelectedRows = projects.map(
                                (row) => row.id
                              );
                              setSelectedProjectRows(newSelectedRows);
                            } else {
                              setSelectedProjectRows([]);
                            }
                          }}
                        />
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>Project</TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Date Deleted
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Deleted by
                      </TableCell>
                      <TableCell sx={{ fontWeight: "bold" }}>
                        Created by
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {projects.map((project) => (
                      <TableRow
                        key={project.id}
                        hover
                        onClick={() => handleRowClick(project.id, "project")}
                        role="checkbox"
                        selected={isSelected(project.id, "project")}
                        aria-checked={isSelected(project.id, "project")}
                      >
                        <TableCell padding="checkbox">
                          <Checkbox
                            checked={isSelected(project.id, "project")}
                            onChange={(e) =>
                              handleRowClick(project.id, "project")
                            }
                          />
                        </TableCell>
                        <TableCell>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Folder sx={{ marginRight: "4px" }} />
                            {project.projectNumber}
                          </Box>
                        </TableCell>
                        <TableCell>{project.deleted_at}</TableCell>
                        <TableCell>{project.deleted_by}</TableCell>
                        <TableCell>{project.created_by}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "5vw",
                  minHeight: "100vh", // Ensure the div takes the full height of the viewport
                }}
              >
                {projects.length === 0 ? (
                  <div style={{}}>
                    <img
                      src={emptyBin}
                      style={{ width: "10vw" }}
                      alt="empty bin"
                    />
                    <p style={{ fontWeight: "900px", marginTop: "10px" }}>
                      Your project bin is empty
                    </p>
                    <p className="font-thin">There is nothing to recycle</p>
                  </div>
                ) : null}
              </div>
            </div>
          )}
        </>
      )}

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Empty Recycle Bin!</DialogTitle>
        <DialogContent>
          <DialogContentText>
            All items will be deleted forever!
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleCancelDelete}
            variant="contained"
            style={{ backgroundColor: "#007DAB", color: "#FFFFFF" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete individual Confirmation Dialog */}
      <Dialog open={openIndividualDialog} onClose={handleCancelDelete}>
        <DialogTitle>Delete!</DialogTitle>
        <DialogContent>
          <DialogContentText>Delete forever!</DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleCancelDelete}
            variant="contained"
            style={{ backgroundColor: "#007DAB", color: "#FFFFFF" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDeleteElement}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default RecycleBin;
