// Layout.js
import PersistentDrawerLeft from "./DesktopNavigation";

const Layout = ({ children, open, handleDrawerOpen, handleDrawerClose }) => {
  return (
    <>
      <PersistentDrawerLeft
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
      />

      <div>{children}</div>
    </>
  );
};

export default Layout;
