import * as React from 'react';
import Box from '@mui/material/Box';

import Stack from '@mui/material/Stack';
import { styled } from '@mui/material/styles';
import { Tabs } from '@mui/base/Tabs';
import { TabsList as BaseTabsList } from '@mui/base/TabsList';
import { TabPanel as BaseTabPanel } from '@mui/base/TabPanel';
import { buttonClasses } from '@mui/base/Button';
import { Tab as BaseTab, tabClasses } from '@mui/base/Tab';
import EquipmentList from './EquipmentList';
import SupplierForm from './SupplierInformation';
import GroupedData from './GroupedData';
import TestGroup from './TestGroup';
// import ComparisonLayout from './InstrumentControl/ComparisionLayout';
import SupplierFillForm from './InstrumentControl/SupplierFillForm';
import Grouping from './InstrumentControl/Grouping';
import GridGrouping from './InstrumentControl/GridGrouping';
import ExcelUploadComponent from './InstrumentControl/ExcelUPloadComponent';
import SyncfusionGrid from './InstrumentControl/InstrumentList';
import TableGrid from './InstrumentControl/TableGrid';
  
 


const Adjudicate =()=>{


    return(<>
    
        
    <Box sx={{ width: '100%', padding: '80px' }}>
      <Stack spacing={2}>
        
        <Tabs defaultValue={0}>
      <TabsList>
        <Tab value={0}>INSTRUMENT DATA</Tab>
        <Tab value={1}>SUPPLIERS DETAILS </Tab>
        <Tab value={2}> PACKAGE RECEIVED BY SUPPLIER</Tab>
        <Tab value={3}>ADJUDICATE INFORMATION </Tab>
      </TabsList>
      {/* <TabPanel value={0}><ComparisonLayout/></TabPanel> */}
      <TabPanel value={1}><SupplierForm/></TabPanel>
      <TabPanel value={2}><SupplierFillForm/></TabPanel>
      <TabPanel value={3}><TableGrid/></TabPanel>
    </Tabs>
      </Stack>
    </Box>

    </>)
}

export default Adjudicate;


const blue = {
    50: '#F0F7FF',
    100: '#C2E0FF',
    200: '#80BFFF',
    300: '#66B2FF',
    400: '#008d8a',
    500: '#007DAB',
    600: '#0072E5',
    700: '#0059B2',
    800: '#004C99',
    900: '#003A75',
  };
  
  const grey = {
    50: '#F3F6F9',
    100: '#E5EAF2',
    200: '#DAE2ED',
    300: '#C7D0DD',
    400: '#B0B8C4',
    500: '#9DA8B7',
    600: '#6B7A90',
    700: '#434D5B',
    800: '#303740',
    900: '#1C2025',
  };
  
  const Tab = styled(BaseTab)`
    font-family: 'IBM Plex Sans', sans-serif;
    color: #fff;
    cursor: pointer;
    font-size: 0.875rem;
    font-weight: 600;
    background-color: transparent;
    width: 100%;
    padding: 10px 12px;
    margin: 6px;
    border: none;
    border-radius: 7px;
    display: flex;
    justify-content: center;
  
    &:hover {
      background-color: ${blue[400]};
    }
  
    &:focus {
      color: #fff;
      outline: 3px solid ${blue[200]};
    }
  
    &.${tabClasses.selected} {
      background-color: #fff;
      color: ${blue[600]};
    }
  
    &.${buttonClasses.disabled} {
      opacity: 0.5;
      cursor: not-allowed;
    }
  `;
  
  const TabPanel = styled(BaseTabPanel)(
    ({ theme }) => `
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 0.875rem;
    padding: 20px 12px;
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    border-radius: 12px;
    opacity: 0.6;
    `,
  );
  
  const TabsList = styled(BaseTabsList)(
    ({ theme }) => `
    min-width: 400px;
    background-color: ${blue[500]};
    border-radius: 12px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: space-between;
    box-shadow: 0px 4px 30px ${theme.palette.mode === 'dark' ? grey[900] : grey[200]};
    `,
  );