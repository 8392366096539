import useAxios from "./utils/useAxios";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, CircularProgress, FormControlLabel, Checkbox } from '@mui/material';
import * as yup from 'yup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
    projectNumber: yup.string().required('Project Number is required'),
    projectName: yup.string().required('Project Name is required'),
    customer: yup.string().required('Customer is required'),
    epcm: yup.string().required('EPCM is required'),
    site: yup.string().required('Site is required'),
    projectAreas: yup.string().required('Add at least one Area'),
});

const UpdateProject = () => {
    const api = useAxios();
    const [projectNumber, setNumber] = useState("");
    const [projectName, setName] = useState("");
    const [salesOrderNumber, setSalesOrderNumber] = useState("");
    const [customer, setCustomer] = useState("");
    const [epcm, setEPCM] = useState("");
    const [site, setSite] = useState("");
    const [projectVoteNo, setProjectVoteNo] = useState("");
    const [pcn, setPCN] = useState("");
    const [projectAreas, setprojectAreas] = useState("");
    const [is_closed, setClosed] = useState(false);
    const [errors, setErrors] = useState({});
    const { projectType, id } = useParams();
    const navigate = useNavigate();

    const loadProjects = async () => {
        try {
            let response;
            let data;
            if (projectType === "open") {
                response = await api.get(`/projects/${id}/`);
                data = response.data;
            } else if (projectType === "closed") {
                response = await api.get(`/closedProjects/${id}/`);
                data = response.data;
            }
            
            console.log(data);
            setNumber(data.projectNumber);
            setName(data.projectName);
            setSalesOrderNumber(data.salesOrderNumber);
            setCustomer(data.customer);
            setEPCM(data.epcm);
            setSite(data.site);
            setProjectVoteNo(data.projectVoteNo);
            setPCN(data.pcn);
            setprojectAreas(data.projectAreas);
            setClosed(data.is_closed);
        } catch (error) {
            console.error("Error loading project:", error);
        }
    };

    useEffect(() => {
        loadProjects();
    }, []);

    const CancelTransaction = () => {
        navigate('/projects');
    }

    const [isLoading, setIsLoading] = useState(false);

    const updateProjectInfo = async () => {
        setIsLoading(true); // Start loading
        try {
            await schema.validate({ projectNumber, projectName, projectAreas, customer, site, epcm }, { abortEarly: false });
            
            const formField = new FormData();
            formField.append('projectNumber', projectNumber);
            formField.append('projectName', projectName);
            formField.append('salesOrderNumber', salesOrderNumber);
            formField.append('customer', customer);
            formField.append('epcm', epcm);
            formField.append('site', site);
            formField.append('projectVoteNo', projectVoteNo);
            formField.append('pcn', pcn);
            formField.append('projectAreas', projectAreas);
            formField.append('is_closed', is_closed);

            if (projectType === "open") {
                await api({
                    method: 'PUT',
                    url: `/projects/${id}/`,
                    data: formField
                });
            } else if (projectType === "closed") {
                await api({
                    method: 'PUT',
                    url: `/closedProjects/${id}/`,
                    data: formField
                });
            }
            

            console.log("Project updated successfully");
            toast.success("Update Success!");
            navigate('/projects');
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const newErrors = {};
                error.inner.forEach((e) => {
                    newErrors[e.path] = e.message;
                });
                setErrors(newErrors);
            } else {
                toast.error("Update Failure!");
            }
        } finally {
            setIsLoading(false); // Stop loading
          }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'absolute', top: '120px', alignItems: 'center' }}>
            <h2 className='flex w-full justify-center'>Update Project</h2>
            <div className='flex w-full justify-center'>
                <div className="w-4/5 md:w-1/3">
                    <div className="flex flex-col justify-between">
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Number'
                                value={projectNumber}
                                onChange={(e) => setNumber(e.target.value)}
                                variant='outlined'
                                fullWidth
                                required
                                error={!!errors.projectNumber}
                                helperText={errors.projectNumber}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Name'
                                value={projectName}
                                onChange={(e) => setName(e.target.value)}
                                variant='outlined'
                                required
                                fullWidth
                                error={!!errors.projectName}
                                helperText={errors.projectName}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Sales Order Number'
                                value={salesOrderNumber}
                                onChange={(e) => setSalesOrderNumber(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.salesOrderNumber}
                                helperText={errors.salesOrderNumber}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Customer Name'
                                value={customer}
                                onChange={(e) => setCustomer(e.target.value)}
                                variant='outlined'
                                required
                                fullWidth
                                error={!!errors.customer}
                                helperText={errors.customer}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='EPCM'
                                value={epcm}
                                onChange={(e) => setEPCM(e.target.value)}
                                variant='outlined'
                                required
                                fullWidth
                                error={!!errors.epcm}
                                helperText={errors.epcm}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Site'
                                value={site}
                                onChange={(e) => setSite(e.target.value)}
                                variant='outlined'
                                required
                                fullWidth
                                error={!!errors.site}
                                helperText={errors.site}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Vote Number'
                                value={projectVoteNo}
                                onChange={(e) => setProjectVoteNo(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.projectVoteNo}
                                helperText={errors.projectVoteNo}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='PCN'
                                value={pcn}
                                onChange={(e) => setPCN(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.pcn}
                                helperText={errors.pcn}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Areas'
                                value={projectAreas}
                                onChange={(e) => setprojectAreas(e.target.value)}
                                variant='outlined'
                                required
                                fullWidth
                                error={!!errors.projectAreas}
                                helperText={errors.projectAreas}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={is_closed} // Corrected prop
                                        onChange={(e) => setClosed(e.target.checked)} // Corrected handler
                                    />
                                }
                                label="Closed"
                            />
                        </div>
                        <div className='flex justify-around my-4'>
                            <Button
                                variant='contained'
                                onClick={CancelTransaction}
                                color='error'
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                onClick={updateProjectInfo}
                                disabled={isLoading} // Disable button when loading
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Update Project'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdateProject;
