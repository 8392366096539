import { Outlet, Navigate } from 'react-router-dom';
import { useContext } from "react";
import AuthContext from "../context/AuthContext";

const PrivateRoutes = ({ children, ...rest }) => {
    const { user } = useContext(AuthContext);
    return (
        <>
            {user ? (
                <Outlet {...rest} />
            ) : (
                <Navigate to="/login" />
            )}
            {children}
        </>
    );
};

export default PrivateRoutes;
