import React, { useState, useEffect, useRef, useContext, useMemo } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  ListItemText,
  Modal,
  Button as Button2,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { HolderOutlined } from "@ant-design/icons";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import TimelineIcon from "@mui/icons-material/Timeline";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import ChecklistIcon from "@mui/icons-material/Checklist";
import useAxios from "./utils/useAxios";
import HelpIcon from "@mui/icons-material/Help";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SendIcon from "@mui/icons-material/Send";
import { toast } from "react-toastify";
import {
  Table,
  List,
  Button,
  Spin,
  Form,
  Input,
  Popconfirm,
  Checkbox,
  Popover,
} from "antd";

const style = {
  width: "85vw",
  bgcolor: "background.paper",
  p: 4,
};

const styleEditComments = {
  position: "absolute",
  top: "0",
  right: "0",
  width: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "100vh",
  overflow: "auto",
};

const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);
  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);
  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};

const RowContext = React.createContext({});
const DragHandle = () => {
  const { setActivatorNodeRef, listeners } = useContext(RowContext);
  return (
    <Button
      type="text"
      size="small"
      icon={<HolderOutlined />}
      style={{
        cursor: "move",
      }}
      ref={setActivatorNodeRef}
      {...listeners}
    />
  );
};

const Row = (props) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props["data-row-key"],
  });
  const style = {
    ...props.style,
    transform: CSS.Translate.toString(transform),
    transition,
    ...(isDragging
      ? {
          position: "relative",
          zIndex: 9999,
        }
      : {}),
  };
  const contextValue = useMemo(
    () => ({
      setActivatorNodeRef,
      listeners,
    }),
    [setActivatorNodeRef, listeners]
  );
  return (
    <RowContext.Provider value={contextValue}>
      <tr {...props} ref={setNodeRef} style={style} {...attributes} />
    </RowContext.Provider>
  );
};

const ProjectDashboard = () => {
  const api = useAxios();
  const [project, setProject] = useState(null);
  const { projectId, projectType } = useParams();
  const [deliverables, setDeliverables] = useState([]);
  const [projectStages, setProjectStages] = useState([]);
  const [stageComments, setStageComments] = useState([]);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const [comment, setComment] = useState("");
  const [updateComment, setUpdateComment] = useState("");
  const [stageId, setStageId] = useState(null);
  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [authTokens, setAuthTokens] = useState(
    localStorage.getItem("authTokens")
  );
  const [editingMode, setEditingMode] = useState(false);
  const [userId, setUserId] = useState(null);
  const [editingComment, setEditingComment] = useState("");
  const [editingCommentId, setEditingCommentId] = useState(null);
  const [hideAddComment, setHideAddComment] = useState(false);

  const [loading, setLoading] = useState(false);
  const [openEditComments, setOpenEditComments] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authTokens) {
      const decode = jwtDecode(authTokens);
      setUserId(decode.id);
    }
  }, [authTokens]);

  const content = (
    <div>
      <Button2 onClick={() => handleEditCommentClick()}>
        Edit
      </Button2>
      <Button2
        color="error"
        onClick={() => handleDeleteComment(selectedCommentId)}
      >
        Delete
      </Button2>
    </div>
  );

  const onDragEnd = ({ active, over }) => {
    if (active.id !== over?.id) {
      setDataSource((prevState) => {
        const activeIndex = prevState.findIndex(
          (record) => record.key === active?.id
        );
        const overIndex = prevState.findIndex(
          (record) => record.key === over?.id
        );
        return arrayMove(prevState, activeIndex, overIndex);
      });
    }
  };

  const handlePopoverOpen = (event, id, editComment) => {
    setAnchorEl(event.currentTarget);
    setSelectedCommentId(id);
    setEditingComment(editComment);
    setEditingCommentId(id);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
    setSelectedCommentId(null);
  };

  const handleExpand = async (expanded, record) => {
    if (expanded) {
      try {
        const response = await api.get(
          `/stageComments/?projectStage=${record.id}`
        );
        setStageComments((prevState) => ({
          ...prevState,
          [record.id]: response.data,
        }));
        setExpandedRowKeys([record.id]);
      } catch (error) {
        console.error("Error fetching comments:", error);
      }
    } else {
      setExpandedRowKeys([]);
    }
  };

  const expandedRowRender = (record) => {
    const comments = stageComments[record.id] || [];
    return (
      <Box>
        <List
          itemLayout="horizontal"
          dataSource={comments}
          renderItem={(comment, index) => (
            <List.Item
              key={index}
              style={{ display: "flex", justifyContent: "space-between" }}
            >
              <List.Item.Meta
                description={
                  <ListItemText
                    primary={`${comment.commentBy} ${comment.commentModified}`}
                  />
                }
                title={
                  <div style={{ display: "flex" }}>
                    <Typography variant="h6">
                      <ListItemText primary={comment.comment} />
                    </Typography>
                    <Popover
                      open={anchorEl && selectedCommentId === comment.id}
                      anchorEl={anchorEl}
                      trigger="click"
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "center",
                      }}
                      content={
                        <div>
                          {content}
                          <IconButton
                            sx={{ position: "absolute", top: 0, right: 0 }}
                            onClick={handlePopoverClose}
                          >
                            <CloseIcon />
                          </IconButton>
                        </div>
                      }
                    >
                      <IconButton
                        onClick={(event) =>
                          handlePopoverOpen(event, comment.id, comment.comment)
                        }
                      >
                        <MoreHoriz />
                      </IconButton>
                    </Popover>
                  </div>
                }
              />
            </List.Item>
          )}
        />
        <Box>
          {!hideAddComment && (
            <>
              <TextField
                variant="standard"
                label="Add comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
                sx={{ width: "80%" }}
                multiline
                maxRows={4}
              />
              <Tooltip title="Send Comment">
                <SendIcon
                  fontSize="large"
                  style={{
                    marginLeft: 2,
                    marginBottom: 16,
                    color: "#007DAB",
                    ...(comment.trim()
                      ? {}
                      : { color: "gray", cursor: "not-allowed" }),
                  }}
                  onClick={() => {
                    if (comment.trim()) {
                      handleAddComment(record);
                    }
                  }}
                />
              </Tooltip>
            </>
          )}

          {editingMode && (
            <>
              <TextField
                variant="standard"
                label="Edit comment"
                value={updateComment}
                onChange={(e) => setUpdateComment(e.target.value)}
                sx={{ width: "80%" }}
                multiline
                maxRows={4}
              />
              <Tooltip title="Update Comment">
                <SendIcon
                  fontSize="large"
                  style={{
                    marginLeft: 2,
                    marginBottom: 16,
                    color: "#007DAB",
                    ...(updateComment.trim()
                      ? {}
                      : { color: "gray", cursor: "not-allowed" }),
                  }}
                  onClick={() => {
                    if (updateComment.trim()) {
                    handleEditComment(record)
                  }}}
                />
              </Tooltip>
            </>
          )}
        </Box>
      </Box>
    );
  };

  const [dataSource, setDataSource] = useState([
    {
      key: "0",
      name: "Edward King 0",
      age: "32",
      address: "London, Park Lane no. 0",
    },
    {
      key: "1",
      name: "Edward King 1",
      age: "32",
      address: "London, Park Lane no. 1",
    },
  ]);

  const [count, setCount] = useState(projectStages.length + 1);

  useEffect(() => {
    setCount(projectStages.length + 1);
  }, [projectStages]);

  const handleDelete = async (id) => {
    try {
      // Make an API call to delete the stage from the backend
      const response = await api.delete(`/projectStages/${id}/`);

      // If the API call is successful, update the frontend state
      setProjectStages((prevStages) =>
        prevStages.filter((item) => item.id !== id)
      );
      setCount(count - 1);
      if (!response.ok) {
        throw new Error("Failed to delete the stage");
      }
    } catch (error) {
      console.error("Error deleting stage:", error);
      // Optionally, handle the error, e.g., show a notification to the user
    }
  };

  const handleAddComment = async (record) => {
    try {
      const commentsData = new FormData();
      commentsData.append("project", projectId);
      commentsData.append("stage", record.id);
      commentsData.append("comment", comment);

      const CommentsResponse = await api.post("/stageComments/", commentsData);
      setComment("");

      const response = await api.get(
        `/stageComments/?projectStage=${record.id}`
      );
      setStageComments((prevState) => ({
        ...prevState,
        [record.id]: response.data,
      }));
    } catch (error) {
      toast.error("Error sending comment.");
    }
  };

  const handleEditCommentClick = () => {
    setStageId(comment.stage);
    setUpdateComment(editingComment);
    setEditingMode(true);
    setHideAddComment(true);
    handlePopoverClose();
  };

  const handleEditComment = async (updatedComment) => {
    try {
      // Update the comment on the server
      await api({
        method: "PUT",
        url: `/stageComments/${editingCommentId}/`,
        data: { comment: updateComment },
      });

      // Fetch the updated comments for the specific stage
      const response = await api.get(
        `/stageComments/?projectStage=${updatedComment.id}`
      );
      setStageComments((prevState) => ({
        ...prevState,
        [updatedComment.id]: response.data,
      }));

      // Clear the comment state and reset the editing state
      setEditingMode(false);
      setHideAddComment(false);
      setUpdateComment("");
      setEditingCommentId(null);
    } catch (error) {
      toast.error("Error updating comment.");
    }
  };

  const handleDeleteComment = async (commentId) => {
    try {
      await api.delete(`/stageComments/${commentId}/`);

      // Filter out the deleted comment from stageComments
      setStageComments((prevState) => {
        const newStageComments = { ...prevState };
        for (const stage in newStageComments) {
          newStageComments[stage] = newStageComments[stage].filter(
            (comment) => comment.id !== commentId
          );
        }
        return newStageComments;
      });

      handlePopoverClose();
    } catch (error) {
      toast.error("Error deleting comment.");
    }
  };

  const defaultColumns = [
    {
      key: "sort",
      align: "center",
      width: 80,
      render: () => <DragHandle />,
    },
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
      width: "40%",
      editable: true,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      width: "5%",
      render: (text, record) => (
        <Checkbox
          checked={record.status}
          onChange={(e) => {
            const updatedRecord = { ...record, status: e.target.checked };
            handleSave(updatedRecord);
          }}
        />
      ),
    },
    {
      title: "Person Responsible",
      dataIndex: "personResponsible",
      key: "personResponsible",
      editable: true,
    },
    {
      title: "Action",
      dataIndex: "action",
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Button2 color="error" onClick={() => handleDelete(record.id)}>
            Delete
          </Button2>
        ) : null,
    },
  ];

  const handleAdd = async () => {
    try {
      const newStage = {
        project: projectId, // Assuming you have the projectId available
        stage: `stage name`,
        status: false, // Default to false as per your model
        personResponsible: `person name`,
        dateCompleted: null,
        stagePosition: projectStages.length + 1, // Setting the position as the next in line
      };

      const response = await api.post("/projectStages/", newStage);
      const addedStage = response.data;

      setProjectStages([
        ...projectStages,
        { ...addedStage, key: addedStage.id },
      ]);
    } catch (error) {
      console.error("Error adding stage:", error);
    }
  };

  const handleAddDefaults = async () => {
    try {
      setLoading(true);

      const defaultStages = [
        { stage: "Order placed/received", personResponsible: "NA" },
        { stage: "PCN number received", personResponsible: "NA" },
        { stage: "Vote number received", personResponsible: "NA" },
        {
          stage: "The latest client input documents received",
          personResponsible: "NA",
        },
        {
          stage: "Details of EPCM/client technical personnel received",
          personResponsible: "NA",
        },
        {
          stage:
            "Details of the 3rd Party QC/QA team, responsible Project Administrator, and Project Manager to approve the Invoice and book it for GRV confirmed",
          personResponsible: "NA",
        },
        { stage: "Working layer received", personResponsible: "NA" },
        {
          stage: "Issued for IDR (1ST review stage)",
          personResponsible: "NA",
        },
        {
          stage: "Latest IDR feedback received",
          personResponsible: "NA",
        },
        {
          stage: "Issued for IDR deliverables completed",
          personResponsible: "NA",
        },
        {
          stage:
            "Deliverables ready for ICR uploaded on SharePoint and 3EBingo before issuing to the Client",
          personResponsible: "NA",
        },
        {
          stage: "Issued for ICR (2nd review stage) and CC Accounts",
          personResponsible: "NA",
        },
        {
          stage: "Latest ICR feedback received",
          personResponsible: "NA",
        },
        {
          stage: "Comment/ redlines saved on SharePoint",
          personResponsible: "NA",
        },
        {
          stage: "Issued for ICR stage deliverables",
          personResponsible: "NA",
        },
        {
          stage: "Issued for IFC (3rd review stage) and CC Accounts",
          personResponsible: "NA",
        },
        {
          stage: "Latest IFC feedback received",
          personResponsible: "NA",
        },
        {
          stage: "Comments/ feedback saved on SharePoint",
          personResponsible: "NA",
        },
        {
          stage: "Issued for IFC stage completed",
          personResponsible: "NA",
        },
        {
          stage: "Issued for As-built (final review stage) and CC Accounts",
          personResponsible: "NA",
        },
        {
          stage: "Latest As-built feedback received",
          personResponsible: "NA",
        },
        { stage: "As-built stage approved", personResponsible: "NA" },
      ];

      const addedStages = [];
      for (let i = 0; i < defaultStages.length; i++) {
        const newStage = {
          project: projectId,
          stage: defaultStages[i].stage,
          status: false,
          personResponsible: defaultStages[i].personResponsible,
          dateCompleted: null,
          stagePosition: projectStages.length + 1 + i,
        };

        const response = await api.post("/projectStages/", newStage);
        const addedStage = response.data;
        addedStages.push({ ...addedStage, key: addedStage.id });
      }

      setProjectStages([...projectStages, ...addedStages]);
      setLoading(false); // Set loading state to false after stages are added
    } catch (error) {
      console.error("Error adding default stages:", error);
      setLoading(false);
    }
  };

  const handleSave = async (row) => {
    try {
      const response = await api.put(`/projectStages/${row.id}/`, row);
      const updatedStage = response.data;

      const newData = [...projectStages];
      const index = newData.findIndex((item) => row.id === item.id);
      const item = newData[index];
      newData.splice(index, 1, {
        ...item,
        ...updatedStage,
      });
      setProjectStages(newData);
    } catch (error) {
      console.error("Error updating stage:", error);
    }
  };

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const stageColumns = [
    {
      title: "Stage",
      dataIndex: "stage",
      key: "stage",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (text, record) => <Checkbox checked={record.status} />,
    },
    {
      title: "Person Responsible",
      dataIndex: "personResponsible",
      key: "personResponsible",
    },
    {
      title: "Date Completed",
      dataIndex: "dateCompleted",
      key: "dateCompleted",
    },
    {
      title: "Last Modified",
      dataIndex: "lastModified",
      key: "lastModified",
    },
  ];

  const stageRows = projectStages.map((stage) => ({
    ...stage,
    key: stage.id, // Add a key for each row, assuming stage.id is unique
  }));

  const handleEditClick = (deliverableId) => {
    setOpenEditComments(true);
  };

  const handleEditClose = () => {
    setOpenEditComments(false);
  };

  const getStatusProgress = (status) => {
    switch (status) {
      case "IDR":
        return 45;
      case "ICR":
        return 75;
      case "IFC":
        return 95;
      case "As Built":
        return 100;
      default:
        return 0;
    }
  };

  const calculateProjectProgress = (projectId) => {
    const filteredDeliverables = deliverables.filter(
      (deliverable) => deliverable.project === projectId
    );
    if (filteredDeliverables.length === 0) return 0;
    const totalProgress = filteredDeliverables.reduce(
      (acc, deliverable) => acc + getStatusProgress(deliverable.status),
      0
    );

    return Math.round(totalProgress / filteredDeliverables.length);
  };

  const calculateProgressColor = (progress) => {
    if (progress >= 0 && progress <= 44) {
      return "red"; // Color for 0-44 progress
    } else if (progress >= 45 && progress <= 75) {
      return "orange"; // Color for 45-75 progress
    } else {
      return "green"; // Color for 76-100 progress
    }
  };

  const updateProjectStages = async () => {
    setIsLoading(true);

    try {
      console.log("Hello people.");
    } catch (error) {
      console.log("Error");
    } finally {
      setIsLoading(false);
    }
    handleEditClose();
  };

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let response;
        if (projectType === "closed") {
          response = await api.get(`/closedProjects/${projectId}/`);
        } else {
          response = await api.get(`/projects/${projectId}/`);
        }
        setProject(response.data);
      } catch (error) {
        console.error("Error fetching Project:", error);
      }
    };

    const fetchDeliverables = async () => {
      try {
        if (projectId) {
          const deliverablesResponse = await api.get(
            `/deliverables/?project=${projectId}`
          );
          setDeliverables(deliverablesResponse.data);
          setLoading(false);
        }
      } catch (error) {
        console.log("Error fetching deliverables");
      }
    };

    const fetchStages = async () => {
      try {
        const response = await api.get(`/projectStages/?project=${projectId}`);
        setProjectStages(response.data);
      } catch (error) {
        console.log("error fetching stages");
      }
    };

    fetchProject();
    fetchDeliverables();
    fetchStages();
  }, [projectId, projectType]);

  useEffect(() => {
    if (project && project.project_admins) {
      setAdmins(project.project_admins);
      setIsAdmin(project.project_admins.includes(userId));
    }
  }, [project, userId]);

  useEffect(() => {
    console.log("admins: ", admins);
    console.log("isAdmin: ", isAdmin);
  }, [admins, isAdmin]);

  if (!project) {
    return <p>Loading...</p>;
  }

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      {/* Displaying the dynamically fetched project name */}
      <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 4 }}>
        {project.projectNumber} {project.projectName}
      </Typography>
      <Box sx={style}>
        <div style={{ margin: 10 }}>
          <Typography
            variant="h5"
            sx={{ textAlign: "center", marginBottom: 4 }}
          >
            Project Details
          </Typography>
          <Grid container spacing={2} sx={{ marginBottom: 4 }}>
            <Grid container spacing={2} item xs={8}>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Project Name: {project.projectName}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Project Number: {project.projectNumber}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Customer: {project.customer}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">EPCM: {project.epcm}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">Site: {project.site}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Project Vote Number: {project.projectVoteNo}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Project Status: {project.projectStatus}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6">
                  Project Areas: {project.projectAreas}
                </Typography>
              </Grid>
            </Grid>
            <Grid container spacing={2} item xs={4}>
              <Grid
                container
                item
                xs={12}
                justifyContent="center"
                alignItems="center"
              >
                <Box sx={{ position: "relative", display: "inline-flex" }}>
                  <CircularProgress
                    size={150}
                    thickness={5}
                    value={calculateProjectProgress(project.id)}
                    variant="determinate"
                    style={{
                      color: calculateProgressColor(
                        calculateProjectProgress(project.id)
                      ),
                    }}
                  />
                  <Box
                    sx={{
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      variant="caption"
                      component="div"
                      color="text.secondary"
                      fontSize={20}
                    >{`${calculateProjectProgress(project.id)}%`}</Typography>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
          <Accordion>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3-content"
              id="panel3-header"
            >
              <Typography variant="h5">Project Stages and Comments</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Tooltip title="Update Stages">
                <Button style={{ marginBottom: 10 }} onClick={() => handleEditClick()}>
                  <Edit color="primary" /> Edit
                </Button>
              </Tooltip>

              <Table
                columns={stageColumns}
                dataSource={stageRows}
                expandable={{
                  expandedRowRender,
                  onExpand: handleExpand,
                  expandedRowKeys: expandedRowKeys,
                }}
              />
            </AccordionDetails>
          </Accordion>
        </div>
      </Box>
      <Grid
        container
        spacing={3}
        justifyContent="start"
        sx={{ marginTop: "4px", width: "85vw", marginBottom: 4 }}
      >
        {/* Dashboard */}
        <Grid item xs={6} sm={4} md={3} lg={2} align={"center"}>
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to={
              projectType === "open"
                ? `/projects/dashboard/${projectId}/open/deliverables`
                : `/projects/dashboard/${projectId}/closed/deliverables`
            }
          >
            <Paper elevation={3}>
              <ChecklistIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Deliverables ({deliverables.length})
          </Typography>
        </Grid>

        {/* Projects */}
        <Grid
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
          sx={{ display: "none" }}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to=""
          >
            <Paper elevation={3}>
              <TimelineIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Progress
          </Typography>
        </Grid>

        {/* Instrument Index */}
        <Grid item xs={6} sm={4} md={3} lg={2} align={"center"}>
          <RouterLink className="rounded flex items-center justify-center shadow h-32 w-32" 
          to={
            projectType === "open"
              ? `/projects/dashboard/${projectId}/open/clientdocuments`
              : `/projects/dashboard/${projectId}/closed/clientdocuments`
          }>
            <Paper elevation={3}>
              <PictureAsPdfIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Client Documents
          </Typography>
        </Grid>

        {/* Query */}
        <Grid
          sx={{ display: "none" }}
          item
          xs={6}
          sm={4}
          md={3}
          lg={2}
          align={"center"}
        >
          <RouterLink
            className="rounded flex items-center justify-center shadow h-32 w-32"
            to={`/projects/dashboard/${projectId}/queries`}
          >
            <Paper elevation={3}>
              <HelpIcon sx={{ fontSize: 40 }} />
            </Paper>
          </RouterLink>
          <Typography variant="body1" className="text-black" align="center">
            Queries
          </Typography>
        </Grid>
      </Grid>
      <Modal open={openEditComments} onClose={handleEditClose}>
        <Box sx={styleEditComments}>
          {/* Close button */}
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleEditClose}
          >
            <CloseIcon />
          </IconButton>
          <div className="flex flex-col justify-between">
            <h2 className="flex w-full justify-center">Update Stages</h2>
            <div>
              {projectStages.length >= 1 ? (
                <Button
                  onClick={handleAdd}
                  type="primary"
                  style={{
                    marginBottom: 16,
                    backgroundColor: "#007DAB", // Custom blue color
                    color: "white", // Text color
                  }}
                >
                  Add a stage
                </Button>
              ) : (
                <>
                  <Button
                    onClick={handleAddDefaults}
                    type="primary"
                    style={{
                      marginBottom: 16,
                      marginRight: 4,
                      backgroundColor: "#007DAB", // Custom blue color
                      color: "white", // Text color
                    }}
                    disabled={loading} // Disable the button when loading
                  >
                    {loading ? <Spin size="small" /> : "Default"}
                  </Button>
                  <Button
                    onClick={handleAdd}
                    type="primary"
                    style={{
                      marginBottom: 16,
                      backgroundColor: "#007DAB", // Custom blue color
                      color: "white", // Text color
                    }}
                    disabled={loading} // Disable the button when loading
                  >
                    {loading ? <Spin size="small" /> : "Add a stage"}
                  </Button>
                </>
              )}
              <DndContext
                modifiers={[restrictToVerticalAxis]}
                onDragEnd={onDragEnd}
              >
                <SortableContext
                  items={projectStages.map((i) => i.key)}
                  strategy={verticalListSortingStrategy}
                >
                  <Table
                    components={components}
                    rowKey="key"
                    rowClassName={() => "editable-row"}
                    bordered
                    dataSource={projectStages}
                    columns={columns}
                    pagination={false}
                  />
                </SortableContext>
              </DndContext>
            </div>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default ProjectDashboard;
