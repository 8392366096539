import useAxios from "./../utils/useAxios";
import React, { useState, useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Fab,
  Typography,
} from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import TablePagination from "@mui/material/TablePagination";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Cancel, CheckCircle } from "@mui/icons-material";

const ProjectChangeNotification = () => {
  const api = useAxios();
  const [projectPCN, setPCN] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [project, setProject] = useState({});


  const fetchProjectData = async (projectId) => {
    try {
      const response = await api.get(`/projects/${projectId}/`);
      const { projectNumber, projectName } = response.data;
      return `${projectNumber} ${projectName}`;
    } catch (error) {
      console.error("Error fetching project data:", error);
      return null;
    }
  };
  
  // Inside fetchPCN function
  const fetchPCN = async () => {
    try {
      const response = await api.get("/projectchangenotification/");
      setPCN(response.data);
  
      const names = {};
      for (const pcnItem of response.data) {
        const projectData = await fetchProjectData(pcnItem.project);
        names[pcnItem.project] = projectData;
      }
      setProject(names);
    } catch (error) {
      console.error("Error fetching PCN:", error);
    }
  };

  const fetchPermissions = async () => {
    try {
      // Make the API request with the access token included in the headers
      const response = await api.get("/user/permissions/");

      // Set the permissions state with the data received from the API
      setPermissions(response.data.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    fetchPCN();
    fetchPermissions();
  }, []); // Fetch PCN data and permissions on component mount

  const deleteProject = async (projectId) => {
    try {
      await api.delete(`/projectchangenotification/${projectId}/`);
      // Filter out the deleted project from the projects state
      setPCN((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );
      console.log("Project deleted successfully");
      toast.success("PCN successfully deleted");
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting project:", error);
      toast.error("PCN could not be deleted!");
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleDeleteClick = (projectId) => {
    setSelectedProject(projectId);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setSelectedProject(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (selectedProject) {
      deleteProject(selectedProject);
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <Typography variant="h4">Project Change Notification</Typography>

      <div className="w-4/5">
      {permissions.length > 0 && (
        <Fab
          sx={{ marginBottom: "10px" }}
          component={RouterLink}
          to="/addPCN"
          variant="contained"
          style={{ backgroundColor: "#007DAB", color: "#FFFFFF" }}
        >
          <AddIcon />
        </Fab>
      )}
        <Paper sx={{ width: "100%", overflow: "hidden" }}>
          <TableContainer sx={{ maxHeight: 500 }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Project
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Project Change Notification
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Areas
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Status
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Comment
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Requested by
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>
                    Requested at
                  </TableCell>
                  <TableCell sx={{ fontWeight: "bold" }}>Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {projectPCN
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((projectCN, index) => (
                    <TableRow key={projectCN.id || index}>
                      <TableCell>
                        {/* Fetch project name inside the TableCell */}
                        {project[projectCN.project] || "Loading..."}
                      </TableCell>
                      <TableCell>
                        {projectCN.pcn}
                      </TableCell>
                      <TableCell>
                        {projectCN.projectAreas}
                      </TableCell>
                      <TableCell>
                        {projectCN.status ? (
                          <CheckCircle style={{ color: "green" }} />
                        ) : (
                          <Cancel style={{ color: "red" }} />
                        )}
                      </TableCell>
                      <TableCell>
                        {projectCN.comment}
                      </TableCell>
                      <TableCell>
                        {projectCN.created_by}
                      </TableCell>
                      <TableCell>
                        {projectCN.created}
                      </TableCell>
                      <TableCell>
                      {permissions.length > 0 && (
                        <>
                        <IconButton
                          component={RouterLink}
                          to={`/pcn/${projectCN.id}/update`}
                          variant="contained"
                          sx={{ color: "#007DAB" }}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          variant="contained"
                          color="error"
                          onClick={() => handleDeleteClick(projectCN.id)}
                        >
                          <DeleteIcon />
                        </IconButton>
                        </>
                      )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={projectPCN.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
      </div>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Delete PCN</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this PCN?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: 'space-around' }}>
          <Button onClick={handleCancelDelete} variant='contained' style={{ backgroundColor: '#007DAB', color: '#FFFFFF' }}>
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} variant='contained' color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ProjectChangeNotification;
