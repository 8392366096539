import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Box, Button, Spinner, Stack } from '@chakra-ui/react';
import ReactDataGrid from 'react-data-grid';
import { AddIcon } from '@chakra-ui/icons';

const TableGrid = () => {
  const [columns, setColumns] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await axios.get('https://3ebingo.co.za/api/upload/');
      console.log('Response data:', response);

      const data = response.data.map(item => item.data) || []; // Ensure data is an array
      setRows(data);
      setSelectedRows(data.map(() => false)); // Initialize selectedRows

      // Generate column definitions from the keys of the first row
      if (data.length > 0) {
        const firstRow = data[0];
        const columns = [
          {
            key: 'selected',
            name: '',
            width: 50,
            formatter: ({ row, rowIdx }) => (
              <input
                type="checkbox"
                checked={row.selected}
                onChange={() => handleCheckboxChange(rowIdx)}
              />
            ),
          },
          ...Object.keys(firstRow).filter(key => key !== 'selected').map(key => ({
            key: key,
            name: key,
            editable: true, // Make the columns editable
            formatter: ({ row }) => (
              <Box style={{ padding: '10px 10px' }}>
                {row[key]}
              </Box>
            ),
          })),
        ];
        setColumns(columns);
      } else {
        setColumns([]); // Ensure columns are an empty array if there's no data
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (rowIdx) => {
    const updatedRows = [...rows];
    const row = updatedRows[rowIdx]; // Retrieve the row object
  
    if (row) {
      // Check if row is defined
      row.selected = !row.selected; // Toggle the selected property
      setRows(updatedRows); // Update the rows state
    } else {
      console.error(`Row at index ${rowIdx} is undefined.`);
    }
  };

  const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
    const updatedRows = rows.slice();
    for (let i = fromRow; i <= toRow; i++) {
      updatedRows[i] = { ...updatedRows[i], ...updated };
    }
    setRows(updatedRows);

    // Send updated row data to backend
    axios.post('https://3ebingo.co.za/api/upload/', { data: { Sheet1: updatedRows } })
      .then(response => {
        console.log('Data saved successfully:', response.data);
      })
      .catch(error => {
        console.error('Error saving data:', error);
      });
  };

  const onRowsSelected = (rows) => {
    const selectedRowIds = rows.map(r => r.rowIdx);
    console.log('Rows selected:', selectedRowIds);
    setSelectedRows(selectedRowIds);
  };

  return (
    <Box p={4}>
      {loading ? (
        <Stack direction="row" spacing={4}>
          <Spinner
            size="xl"
            color="blue.500"
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            margin="auto"
          />
        </Stack>
      ) : (
        columns.length > 0 && rows.length > 0 ? (
          <>
            {selectedRows.length > 0 && (
              <Button>
                <AddIcon /> Add suppliers
              </Button>
            )}
            <ReactDataGrid
              columns={columns}
              rowGetter={i => rows[i]}
              rowsCount={rows.length}
              onGridRowsUpdated={onGridRowsUpdated}
              onRowsSelected={onRowsSelected}
              onCellSelected={({ rowIdx }) => handleCheckboxChange(rowIdx)}
              enableCellSelect={true}
            />
          </>
        ) : (
          <Box>No data available</Box>
        )
      )}
    </Box>
  );
};

export default TableGrid;





// import React, { useState, useEffect } from 'react';
// import axios from 'axios';
// import { Box, Spinner, Stack } from '@chakra-ui/react';
// import ReactDataGrid from 'react-data-grid';
// import CheckboxFormatter from './CheckboxFormatter';

// const TableGrid = () => {
//   const [columns, setColumns] = useState([]);
//   const [rows, setRows] = useState([]);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     fetchData();
//   }, []);

//   const fetchData = async () => {
//     try {
//       setLoading(true);
//       const response = await axios.get('https://3ebingo.co.za/api/upload/');
//       console.log('Response data:', response);

//       const data = response.data.map(item => item.data) || []; // Ensure data is an array
//       setRows(data);

//       // Generate column definitions from the keys of the first row
//       if (data.length > 0) {
//         const firstRow = data[0];
//         const columns = [
//           {
//             key: 'checkbox',
//             name: '',
//             formatter: CheckboxFormatter,
//             width: 50,
//           },
//           ...Object.keys(firstRow).map(key => ({
//             key: key,
//             name: key,
//             editable: true, // Make the columns editable
//             formatter: ({ row }) => (
//               <Box style={{ padding: '10px 10px' }}>
//                 {row[key]}
//               </Box>
//             ),
//           })),
//         ];
//         setColumns(columns);
//       }
//     } catch (error) {
//       console.error('Error fetching data:', error);
//     } finally {
//       setLoading(false);
//     }
//   };

//   const onGridRowsUpdated = ({ fromRow, toRow, updated }) => {
//     const updatedRows = rows.slice();
//     for (let i = fromRow; i <= toRow; i++) {
//       updatedRows[i] = { ...updatedRows[i], ...updated };
//     }
//     setRows(updatedRows);

//     // Send updated row data to backend
//     axios.post('https://3ebingo.co.za/api/upload/', { data: { Sheet1: updatedRows } })
//       .then(response => {
//         console.log('Data saved successfully:', response.data);
//       })
//       .catch(error => {
//         console.error('Error saving data:', error);
//       });
//   };

//   return (
//     <Box p={4}>
//       {loading ? (
//         <Stack direction="row" spacing={4}>
//           <Spinner
//             size="xl"
//             color="blue.500"
//             thickness="4px"
//             speed="0.65s"
//             emptyColor="gray.200"
//             margin="auto"
//           />
//         </Stack>
//       ) : (
//         columns.length > 0 && rows.length > 0 ? (
//           <ReactDataGrid
//             columns={columns}
//             rowGetter={i => rows[i]}
//             rowsCount={rows.length}
//             onGridRowsUpdated={onGridRowsUpdated}
//             enableCellSelect={true}
//           />
//         ) : (
//           <Box>No data available</Box>
//         )
//       )}
//     </Box>
//   );
// };

// export default TableGrid;




