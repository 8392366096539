import React from "react";

const ProjectProgress = () => {
  return (
    <div className="flex flex-col w-full absolute top-20 items-center">
      <div className="flex w-4/5 flex-col items-start p-2">
        <h1 className="text-3xl">Project Progress</h1>
        <div className="progress">
          <div>Project Progress</div>
        </div>
      </div>
    </div>
  );
};

export default ProjectProgress;
