/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect, useRef } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import useAxios from "./utils/useAxios";
import {
  TableBody,
  TableCell,
  Tabs,
  Tab,
  IconButton,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Typography,
  Box,
  Button,
  Modal,
  TextField,
  FormControl,
  Autocomplete,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { deliverableTypes, disciplineTypes, statuses } from "../menuItems";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";
import CloseIcon from "@mui/icons-material/Close";
import { SearchOutlined, DeleteFilled } from "@ant-design/icons";
import {
  Input,
  Space,
  Table,
  Divider,
  Modal as Modal2,
  Button as Button2,
} from "antd";
import Highlighter from "react-highlight-words";
import { Delete, Edit } from "@mui/icons-material";
import * as yup from "yup";

const { confirm } = Modal2;

const styleEditDeliverable = {
  position: "absolute",
  top: "0",
  right: "0",
  width: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  height: "100vh",
  overflow: "auto",
};

// Schema for single deliverable
const singleDeliverableSchema = yup.object().shape({
  name: yup.string().required("Name is required"),
  description: yup.string().required("Description is required"),
  disciplineType: yup.string().required("Discipline Type is required"),
  deliverableType: yup.string().required("Deliverable Type is required"),
  deliverableStatus: yup.string().required("Deliverable Status is required"),
  revNumber: yup.string().required("Revision Number is required"),
});

// Schema for multiple deliverables (excluding name and description)
const multipleDeliverablesSchema = yup.object().shape({
  disciplineType: yup.string().required("Discipline Type is required"),
  deliverableType: yup.string().required("Deliverable Type is required"),
  deliverableStatus: yup.string().required("Deliverable Status is required"),
  revNumber: yup.string().required("Revision Number is required"),
});

const ShowDeliverables = () => {
  const api = useAxios();
  const [deliverables, setDeliverables] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDeliverableId, setSelectedDeliverableId] = useState();
  const { projectId, projectType } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [tabValue, setTabValue] = useState(0); // State to manage active tab
  const [search] = useState(projectId);
  const [project, setProject] = useState({});
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [selectedDeliverableRows, setSelectedDeliverableRows] = useState([]);

  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState(""); // Added state for file name
  const [name, setName] = useState("");
  const [area, setArea] = useState("");
  const [voteNo, setVoteNo] = useState("");
  const [invoicingProgress, setInvoicingProgress] = useState("");
  const [description, setDescription] = useState("");
  const [disciplineType, setDisciplineType] = useState("");
  const [deliverableType, setDeliverableType] = useState("");
  const [deliverableStatus, setDeliverableStatus] = useState("");
  const [revNumber, setRevNumber] = useState("");
  const [errors, setErrors] = useState({});
  const [selectedDeliverable, setSelectedDeliverable] = useState(null);
  const [openEditDeliverable, setOpenEditDeliverable] = useState(false);
  const [isEditingMultiple, setIsEditingMultiple] = useState(false);
  const [originalValues, setOriginalValues] = useState({});

  const [isLoading, setIsLoading] = useState(false);

  const handleEditClick = (deliverableId) => {
    const deliverable = deliverables.find(
      (deliverable) => deliverable.id === deliverableId
    );
    setSelectedDeliverable(deliverable);
    setOpenEditDeliverable(true);
    setName(deliverable.name);
    setArea(deliverable.area);
    setVoteNo(deliverable.voteNo);
    setInvoicingProgress(deliverable.invoicingProgress);
    setDescription(deliverable.description);
    setDisciplineType(deliverable.disciplineType);
    setDeliverableType(deliverable.deliverableType);
    setDeliverableStatus(deliverable.status);
    setRevNumber(deliverable.revNumber);
  };

  const handleEditMultiple = () => {
    // Gather selected deliverables
    const selectedDeliverables = deliverables.filter((d) =>
      selectedRowKeys.includes(d.id)
    );

    // Store original values for each selected deliverable
    const values = {};
    selectedDeliverables.forEach((deliverable) => {
      values[deliverable.id] = {
        name: deliverable.name,
        description: deliverable.description,
        area: deliverable.area,
        voteNo: deliverable.voteNo,
        invoicingProgress: deliverable.invoicingProgress,
        disciplineType: deliverable.disciplineType,
        deliverableType: deliverable.deliverableType,
        deliverableStatus: deliverable.status,
        revNumber: deliverable.revNumber,
      };
    });
    setOriginalValues(values);

    // Set the common values (if any)
    const commonValues = {
      name: selectedDeliverables[0]?.name || "",
      description: selectedDeliverables[0]?.description || "",
      area: selectedDeliverables[0]?.area || "",
      voteNo: selectedDeliverables[0]?.voteNo || "",
      invoicingProgress: selectedDeliverables[0]?.invoicingProgress || "",
      disciplineType: selectedDeliverables[0]?.disciplineType || "",
      deliverableType: selectedDeliverables[0]?.deliverableType || "",
      deliverableStatus: selectedDeliverables[0]?.status || "",
      revNumber: selectedDeliverables[0]?.revNumber || "",
    };

    // Update state with common values and open edit dialog
    setSelectedDeliverable(selectedDeliverables); // Assuming you may want to store selected deliverables
    setName(commonValues.name);
    setDescription(commonValues.description);
    setArea(commonValues.area);
    setVoteNo(commonValues.voteNo);
    setInvoicingProgress(commonValues.invoicingProgress);
    setDisciplineType(commonValues.disciplineType);
    setDeliverableType(commonValues.deliverableType);
    setDeliverableStatus(commonValues.deliverableStatus);
    setRevNumber(commonValues.revNumber);
    setOpenEditDeliverable(true);

    setIsEditingMultiple(true); // Set flag for multiple editing
  };

  const handleEditClose = () => {
    setOpenEditDeliverable(false);
    setSelectedDeliverable(null);
    setIsEditingMultiple(false);
    setOriginalValues();
  };

  // Function to handle file selection
  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    setFile(selectedFile);
    setFileName(selectedFile.name);
  };

  const fetchPermissions = async () => {
    try {
      // Make the API request with the access token included in the headers
      const response = await api.get("/user/permissions/");

      // Set the permissions state with the data received from the API
      setPermissions(response.data.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  const calculateProjectProgress = () => {
    const filteredDeliverables = deliverables.filter(
      (deliverable) => deliverable.project.toString() === projectId
    );
    if (filteredDeliverables.length === 0) return 0;
    const totalProgress = filteredDeliverables.reduce(
      (acc, deliverable) => acc + getStatusProgress(deliverable.status),
      0
    );
    return Math.round(totalProgress / filteredDeliverables.length);
  };

  const fetchData = async () => {
    try {
      if (projectId && projectType === "open") {
        const projectResponse = await api.get(`/projects/${projectId}/`);
        setProject(projectResponse.data);
      } else if (projectId && projectType === "closed") {
        const projectResponse = await api.get(`/closedProjects/${projectId}/`);
        setProject(projectResponse.data);
      }

      if (projectId) {
        const deliverablesResponse = await api.get(
          `/deliverables/?project=${projectId}`
        );
        setDeliverables(deliverablesResponse.data);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    fetchPermissions();
  }, [projectId, projectType]);

  const deleteDeliverable = async (id) => {
    try {
      await api.delete(`/deliverables/${id}/`);
      setDeliverables((prevDeliverables) =>
        prevDeliverables.filter((deliverable) => deliverable.id !== id)
      );
      toast.success("Deliverable has been moved to Recycle Bin");
      setOpenDialog(false);
    } catch (error) {
      toast.error("Error Deleting deliverable!");
    }
  };

  const handleDeleteClick = (deliverableId) => {
    setSelectedDeliverableId(deliverableId);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setSelectedDeliverableId(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (selectedDeliverableId) {
      deleteDeliverable(selectedDeliverableId);
    }
  };

  const updateDeliverableInfo = async () => {
    setIsLoading(true);
    try {
      const schemaToUse = isEditingMultiple
        ? multipleDeliverablesSchema
        : singleDeliverableSchema;

      // Validate input data using the appropriate schema
      await schemaToUse.validate(
        {
          name: isEditingMultiple ? undefined : name, // Conditionally include name
          description: isEditingMultiple ? undefined : description, // Conditionally include description
          area,
          disciplineType,
          deliverableType,
          deliverableStatus,
          revNumber,
        },
        { abortEarly: false }
      );

      // Create FormData object
      const formField = new FormData();
      formField.append("project", projectId);
      formField.append("name", name);
      formField.append("area", area);
      formField.append("voteNo", voteNo);
      formField.append("invoicingProgress", invoicingProgress);
      formField.append("description", description);
      formField.append("disciplineType", disciplineType);
      formField.append("deliverableType", deliverableType);
      formField.append("status", deliverableStatus);
      formField.append("revNumber", revNumber);

      // Append file to form data if it's not null
      if (file !== null) {
        formField.append("file", file);
      }

      if (isEditingMultiple) {
        // Update multiple deliverables with their original names and descriptions
        await Promise.all(
          selectedDeliverable.map((deliverable) => {
            const data = new FormData();
            data.append("project", projectId);
            data.append("area", area);
            data.append("voteNo", voteNo);
            data.append("invoicingProgress", invoicingProgress);
            data.append("disciplineType", disciplineType);
            data.append("deliverableType", deliverableType);
            data.append("status", deliverableStatus);
            data.append("revNumber", revNumber);

            if (selectedRowKeys.length === 1) {
              data.append("name", name);
              data.append("description", description);

              // Append file to form data if it's not null
              if (file !== null) {
                data.append("file", file);
              }
            } else {
              // Use the original name and description for this deliverable
              data.append("name", originalValues[deliverable.id].name);
              data.append(
                "description",
                originalValues[deliverable.id].description
              );
            }

            return api.put(`/deliverables/${deliverable.id}/`, data);
          })
        );
      } else {
        // Single deliverable update
        const response = await api.put(
          `/deliverables/${selectedDeliverable.id}/`,
          formField
        );
      }

      await fetchData();
      toast.success("Update Success!");
      navigate(`/projects/dashboard/${projectId}/open/deliverables`);

      handleEditClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      } else {
        toast.error("Update Failure!");
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const getStatusProgress = (status) => {
    switch (status) {
      case "IDR":
        return 45;
      case "ICR":
        return 75;
      case "IFC":
        return 95;
      case "As Built":
        return 100;
      default:
        return 0;
    }
  };

  const getType = (deliverableType) => {
    const deliverable = deliverableTypes.find(
      (item) => item.value === deliverableType
    );
    return deliverable ? deliverable.title : "P&ID"; // Default to "P&ID" if not found
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selectedDeliverables = deliverables.filter((deliverable) =>
      newSelectedRowKeys.includes(deliverable.id)
    );
    setSelectedDeliverableRows(selectedDeliverables);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };

  const getColumnSearchProps = (dataIndex, transformFunction = (x) => x) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button2
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button2>
          <Button2
            type="link"
            size="small"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            style={{
              width: 90,
            }}
          >
            Filter
          </Button2>
          <Button2
            onClick={() => close()}
            type="link"
            size="small"
            style={{
              width: 90,
            }}
          >
            Close
          </Button2>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) => {
      const transformedValue = transformFunction(record[dataIndex]);
      return transformedValue
        .toString()
        .toLowerCase()
        .includes(value.toLowerCase());
    },
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const deliverableColumns = [
    {
      title: "No.",
      dataIndex: "no",
      rowScope: "row",
      width: 60,
      fixed: "left",
    },
    {
      title: "Drawing No.",
      width: 200,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      ...getColumnSearchProps("name"),
      sorter: (a, b) =>
        a.name.localeCompare(b.name, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
      render: (text, record) => (
        <>
          {record.file ? (
            <RouterLink
              style={{ textDecoration: "none", color: "#007FFF" }}
              to={`/pdfviewer?fileUrl=${encodeURIComponent(
                `https://3ebingo.co.za${record.file}`
              )}`}
            >
              {text}
            </RouterLink>
          ) : (
            <Typography sx={{ textDecoration: "none" }}>{text}</Typography>
          )}
        </>
      ),
    },
    {
      title: "Area",
      dataIndex: "area",
      key: "area",
      width: 100,
      ...getColumnSearchProps("area"),
      sorter: (a, b) =>
        a.area.localeCompare(b.area, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Vote No.",
      dataIndex: "voteNo",
      key: "voteNo",
      width: 100,
      ...getColumnSearchProps("voteNo"),
      sorter: (a, b) =>
        a.voteNo.localeCompare(b.voteNo, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Description",
      width: 200,
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
      sorter: (a, b) =>
        a.description.localeCompare(b.description, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Discipline Type",
      dataIndex: "disciplineType",
      key: "disciplineType",
      width: 200,
      ...getColumnSearchProps("disciplineType"),
      sorter: (a, b) =>
        a.disciplineType.localeCompare(b.disciplineType, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Deliverable Type",
      dataIndex: "deliverableType",
      key: "deliverableType",
      width: 200,
      ...getColumnSearchProps("deliverableType"),
      sorter: (a, b) =>
        a.deliverableType.localeCompare(b.deliverableType, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
      render: (text, record) => getType(record.deliverableType),
    },
    {
      title: "Progress %",
      dataIndex: "progress",
      key: "progress",
      width: 200,
      sorter: (a, b) => {
        const aValue = getStatusProgress(a.status).toString();
        const bValue = getStatusProgress(b.status).toString();
        return aValue.localeCompare(bValue, undefined, { numeric: true });
      },
      sortDirections: ["ascend", "descend"],
      render: (text, record) => `${getStatusProgress(record.status)}%`,
    },
    {
      title: "Invoicing Progress %",
      dataIndex: "invoicingProgress",
      key: "invoicingProgress",
      width: 200,
      sorter: (a, b) =>
        a.invoicingProgress.localeCompare(b.invoicingProgress, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Revision No.",
      dataIndex: "revNumber",
      key: "revNumber",
      width: 200,
      // ...getColumnSearchProps("revNumber"),
      sorter: (a, b) =>
        a.revNumber.localeCompare(b.revNumber, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Modified",
      dataIndex: "modified",
      key: "modified",
      width: 200,
      sorter: (a, b) =>
        a.modified.localeCompare(b.modified, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Modified by",
      dataIndex: "modified_by",
      key: "modified_by",
      width: 200,
      ...getColumnSearchProps("modified_by"),
      sorter: (a, b) =>
        a.modified_by.localeCompare(b.modified_by, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created",
      dataIndex: "created",
      key: "created",
      width: 200,
      ...getColumnSearchProps("created"),
      sorter: (a, b) =>
        a.created.localeCompare(b.created, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      width: 200,
      ...getColumnSearchProps("created_by"),
      sorter: (a, b) =>
        a.created_by.localeCompare(b.created_by, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Action",
      key: "operation",
      width: 120,
      render: (_, record) => (
        <Space size="middle">
          {projectType === "open" && permissions.length > 0 && (
            <>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleEditClick(record.id)}>
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton
                  type="link"
                  danger
                  icon={<DeleteFilled />}
                  onClick={() => handleDeleteClick(record.id)}
                >
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
            </>
          )}
        </Space>
      ),
    },
  ];

  const deliverableRows = deliverables.map((deliverable, index) => ({
    no: index + 1 + ".",
    ...deliverable,
  }));

  const handleDeleteSelected = () => {
    confirm({
      title: "Are you sure you want to delete these deliverables?",
      content:
        "Once deleted, the deliverables will be moved to the Recycle Bin.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        selectedRowKeys.forEach((key) => deleteDeliverable(key));
        setSelectedRowKeys([]);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 4 }}>
        {project.projectNumber} {project.projectName} (
        {calculateProjectProgress()}%)
      </Typography>
      <Tabs
        value={tabValue}
        onChange={(e, newValue) => setTabValue(newValue)}
        style={{ width: "88vw" }}
      >
        <Tab
          label="Deliverables"
          component={RouterLink}
          to={`/deliverables/${projectId}`}
        />
        <Tab
          sx={{ display: "none" }}
          label="Invoicing"
          component={RouterLink}
          to=""
        />
      </Tabs>

      {/* Render content based on active tab */}
      {tabValue === 0 ? (
        <>
          {loading ? (
            <p>
              <CircularProgress />
            </p>
          ) : (
            <div style={{ width: "88vw", marginTop: "10px" }}>
              <Box sx={{ display: "flex", width: "88%", direction: "column" }}>
                {permissions.length > 0 && projectType === "open" && (
                  <Button
                    sx={{
                      marginBottom: "10px",
                      backgroundColor: "#007DAB",
                      color: "#FFFFFF",
                    }}
                    component={RouterLink}
                    to={`/deliverables/${projectId}/addDeliverable`}
                    variant="contained"
                  >
                    <Tooltip title={`Add new deliverable`}>
                      <AddIcon /> New
                    </Tooltip>
                  </Button>
                )}
                {selectedRowKeys.length > 0 && (
                  <div style={{ marginBottom: 16 }}>
                    <Button2
                      onClick={handleDeleteSelected}
                      style={{ color: "red", border: 0 }}
                    >
                      <DeleteIcon color="error" /> Delete
                    </Button2>
                    {projectType === "open" && (
                      <Tooltip title="Edit deliverables">
                        <Button2 onClick={() => handleEditMultiple()}>
                          <Edit color="primary" /> Edit
                        </Button2>
                      </Tooltip>
                    )}
                  </div>
                )}
              </Box>
              <Divider />
              <Paper
                style={{ width: "93vw", marginLeft: 10 }}
                overflow="hidden"
              >
                <Table
                  style={{ width: "100%", marginBottom: 8 }}
                  rowSelection={rowSelection}
                  columns={deliverableColumns}
                  dataSource={deliverableRows}
                  pagination={false}
                  loading={loading}
                  rowKey={(record) => record.id}
                  size="small"
                  scroll={{
                    y: 1000,
                    x: 1300,
                  }}
                />
              </Paper>
            </div>
          )}
        </>
      ) : (
        <>
          {loading ? (
            <p>
              <CircularProgress />
            </p>
          ) : (
            <div style={{ width: "88%", marginTop: "10px" }}>
              <Paper style={{ width: "100%", overflow: "hidden" }}>
                <TableContainer style={{ maxHeight: "md" }}>
                  <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          Deliverable
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          Description
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          Total Cost
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          Progress
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          Cost
                        </TableCell>
                        <TableCell
                          style={{ fontWeight: "bold", whiteSpace: "nowrap" }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {deliverables
                        .filter((deliverable) => {
                          return deliverable.project.toString() === search;
                        })
                        .map((deliverable, index) => (
                          <TableRow key={deliverable.id || index}>
                            <TableCell style={{ whiteSpace: "nowrap" }}>
                              {deliverable.name}
                            </TableCell>
                            <TableCell>{deliverable.description}</TableCell>
                            <TableCell>{"R" + 5000}</TableCell>
                            <TableCell>
                              {getStatusProgress(deliverable.status) + "%"}
                            </TableCell>
                            <TableCell>{"R" + 100}</TableCell>
                            <TableCell
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                width: "100%",
                              }}
                            >
                              <IconButton
                                component={RouterLink}
                                to={`/deliverables/${projectId}/${deliverable.id}/update`}
                                style={{ color: "#007DAB" }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                color="error"
                                onClick={() =>
                                  handleDeleteClick(deliverable.id)
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </div>
          )}
        </>
      )}

      {/* Drawer for edit deliverable */}
      <Modal open={openEditDeliverable} onClose={handleEditClose}>
        <Box sx={styleEditDeliverable}>
          {/* Close button */}
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleEditClose}
          >
            <CloseIcon />
          </IconButton>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              height: "100vh",
              marginLeft: "10px",
            }}
          >
            <h2 className="flex w-full justify-center">Update Deliverable</h2>

            {selectedRowKeys.length === 1 && (
              <div className="flex justify-between my-2">
                <TextField
                  label="Drawing No."
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  variant="outlined"
                  fullWidth
                  error={!!errors.name}
                  helperText={errors.name}
                />
              </div>
            )}

            {selectedRowKeys.length === 1 && (
              <div className="flex justify-between my-2">
                <label htmlFor="fileInput">
                  <Button
                    variant="contained"
                    component="span"
                    style={{ backgroundColor: "#007DAB", color: "white" }}
                  >
                    Upload File
                  </Button>
                </label>
                {file && <div className="">{fileName}</div>}
                <input
                  type="file"
                  accept=".pdf,.doc,.docx"
                  className="p-2 w-1/2 h-10 focus:outline-none"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="fileInput"
                />
              </div>
            )}

            <div className="flex justify-between my-2">
              <TextField
                label="Area"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.area}
                helperText={errors.area}
              />
            </div>

            <div className="flex justify-between my-2">
              <TextField
                label="Vote Number"
                value={voteNo}
                onChange={(e) => setVoteNo(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.voteNo}
                helperText={errors.voteNo}
              />
            </div>

            {selectedRowKeys.length === 1 && (
              <div className="flex justify-between my-2">
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  variant="outlined"
                  fullWidth
                  error={!!errors.description}
                  helperText={errors.description}
                />
              </div>
            )}

            <div className="flex justify-between my-2">
              <Autocomplete
                disablePortal
                id="disciplineType"
                fullWidth
                options={disciplineTypes}
                getOptionLabel={(option) => option.title}
                value={
                  disciplineTypes.find((opt) => opt.title === disciplineType) ||
                  null
                }
                onChange={(e, newValue) =>
                  setDisciplineType(newValue?.title || "")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Discipline Type"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <div className="flex justify-between my-2">
              <Autocomplete
                disablePortal
                id="deliverableType"
                options={deliverableTypes}
                fullWidth
                getOptionLabel={(option) => option.title}
                value={
                  deliverableTypes.find(
                    (option) => option.value === deliverableType
                  ) || null
                }
                onChange={(e, newValue) =>
                  setDeliverableType(newValue?.value || "")
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Deliverable Type"
                    variant="outlined"
                  />
                )}
              />
            </div>

            <div className="flex justify-between my-2">
              <TextField
                label="Invoicing Progress"
                value={invoicingProgress}
                onChange={(e) => setInvoicingProgress(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.invoicingProgress}
                helperText={errors.invoicingProgress}
              />
            </div>

            <div className="flex justify-between my-2">
              <FormControl fullWidth>
                <Autocomplete
                  disablePortal
                  id="status"
                  options={statuses}
                  fullWidth
                  getOptionLabel={(option) => option.title}
                  value={
                    statuses.find(
                      (option) => option.title === deliverableStatus
                    ) || null
                  }
                  onChange={(event, newValue) =>
                    setDeliverableStatus(newValue?.title || "")
                  }
                  renderInput={(params) => (
                    <TextField {...params} label="Status" variant="outlined" />
                  )}
                />
              </FormControl>
            </div>

            <div className="flex justify-between my-2">
              <TextField
                label="Revision Number"
                value={revNumber}
                onChange={(e) => setRevNumber(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.revNumber}
                helperText={errors.revNumber}
              />
            </div>

            <div className="flex justify-around my-4">
              <Button
                onClick={handleEditClose}
                variant="contained"
                color="error"
              >
                Cancel
              </Button>
              <Button
                onClick={updateDeliverableInfo}
                variant="contained"
                disabled={isLoading} // Disable button when loading
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Update Deliverable"
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Delete Deliverable</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this deliverable?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleCancelDelete}
            variant="contained"
            style={{ backgroundColor: "#007DAB", color: "#FFFFFF" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShowDeliverables;
