import * as React from "react";
import useAxios from "./utils/useAxios";
import { jwtDecode } from "jwt-decode";
import { useState, useEffect, useContext } from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";

import CssBaseline from "@mui/material/CssBaseline";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import MuiDrawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";

import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import theme from "../Theme";
import MailIcon from "@mui/icons-material/Mail";
import { alpha } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import NotificationsIcon from "@mui/icons-material/Notifications";
import MoreIcon from "@mui/icons-material/MoreVert";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DeleteIcon from "@mui/icons-material/Delete";
import SettingsIcon from "@mui/icons-material/Settings";
import Tooltip from "@mui/material/Tooltip";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { MoreHoriz } from "@mui/icons-material";
import logo from "../components/images/FS.png";
import {
  Avatar,
  Button,
  Checkbox,
  ListItemAvatar,
  Menu,
  MenuItem,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import AuthContext from "./context/AuthContext";
import {
  ArrowBack,
  Person,
  PersonAdd,
  Search as SearchIcon,
} from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";

import MoonSwitch from "./MoonSwitch";

import {
  Home,
  Description, // Replace with actual Material-UI icons
  ViewList,
  Assignment,
  Category,
  Assessment,
  Folder,
  Note,
  Settings,
  Logout,
} from "@mui/icons-material";
import {
  NavLink,
  Link,
  useLocation,
  useParams,
  useNavigate,
} from "react-router-dom";
import BasicBreadcrumbs from "./BreadCrumbs";
import recycleBin from "./images/recycle-bin.svg";
import { Popover } from "antd";

const drawerWidth = 240;

const style = {
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, -15%)",
  width: "30vw",
  minHeight: "80vh",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const openedMixin = (theme) => ({
  width: drawerWidth,

  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 2px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

function ChildModal() {
  const api = useAxios();
  const [open, setOpen] = useState(false);
  const [openSetAdmin, setOpenAdmin] = useState(false);
  const [users, setUsers] = useState([]);
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const { projectId } = useParams();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [adminStatus, setAdminStatus] = useState({});

  // const handleOpenAdmin = (event, id) => {
  //   const rect = event.currentTarget.getBoundingClientRect();
  //   setModalStyle({
  //     top: rect.top + window.scrollY + 30,
  //     left: rect.left + window.scrollX,
  //   });
  //   setUserId(id);
  //   setOpenAdmin(true);
  // };

  // const handleCloseAdmin = () => {
  //   setOpenAdmin(false);
  //   setUserId(null);
  // };

  const commentStyle = {
    position: "absolute",
    top: "50%",
    left: "80%",
    transform: "translate(-50%, -50%)",
    width: 200,
    height: 50,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: "8px",
    justifyContent: "center",
  };

  const content = <Checkbox>Admin</Checkbox>;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const fetchData = async () => {
    try {
      // Fetch project details
      if (projectId === undefined) {
        projectId = 0;
      }
      const response = await api.get(`/projects/${projectId}/`);
      const projectData = response.data;
      setSelectedUsers(projectData.users); // Initialize selected users

      // Fetch all users
      const usersResponse = await api.get("/users/");
      setUsers(usersResponse.data);

      // Initialize admin status for selected users
      const initialAdminStatus = projectData.users.reduce((acc, user) => {
        acc[user.id] = user.isAdmin || false; // Adjust based on your data structure
        return acc;
      }, {});
      setAdminStatus(initialAdminStatus);
    } catch (error) {
      console.error("Error loading data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [location.pathname]);

  const handleAdminCheckboxChange = (userId) => {
    setAdminStatus((prevStatus) => ({
      ...prevStatus,
      [userId]: !prevStatus[userId],
    }));
    setAdmins((prevStatus) => ({
      ...prevStatus,
      [userId]: !prevStatus[userId],
    }));
  };

  const handleAddMembers = async () => {
    try {
      // Prepare user ids from selectedUsers array
      const user_ids = selectedUsers.map((user) => user.id);

      // Send request to update project with selected users
      await api.put(`/projects/${projectId}/`, { users: user_ids });

      // After adding members, proceed to assign admins
      await handleAssignAdmin(user_ids);

      // Close modal after successful update
      handleClose();
    } catch (error) {
      console.error("Error adding members:", error);
    }
  };

  const handleAssignAdmin = async (user_ids) => {
    try {
      // Loop through each userId to assign admin
      for (const userId of user_ids) {
        await api.post(`/projects/${projectId}/assign-admin/${userId}/`, {
          users: [userId],
        });
      }
  
      // Optionally handle success logic here
    } catch (error) {
      console.error("Error assigning admins:", error);
    }
  };
  const [isAdmin, setIsAdmin] = useState(false);
  const [admins, setAdmins] = useState([]);

  

  return (
    <React.Fragment>
      <Button
        variant="contained"
        sx={{ backgroundColor: "#007DAB" }}
        onClick={handleOpen}
      >
        <PersonAdd /> Add members
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"
      >
        <Box sx={{ ...style }}>
          <h2 id="child-modal-title">Add Members</h2>
          <Typography variant="h6" sx={{ marginBottom: 2 }}>
            Add members to this project.
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            Project owners have full control of projects, permission settings.
          </Typography>
          <Typography sx={{ marginBottom: 2 }}>
            Project members can edit and view site content, including files.
          </Typography>
          <Autocomplete
            limitTags={3}
            multiple
            disableCloseOnSelect
            id="users"
            options={users}
            value={selectedUsers} // Ensure this matches the initial selectedUsers state
            onChange={(event, newValue) => {
              setSelectedUsers(newValue);
            }}
            getOptionLabel={(option) =>
              `${option.first_name} ${option.last_name}`
            }
            renderOption={(props, option, { selected }) => {
              const isChecked =
                selected || selectedUsers.some((user) => user.id === option.id);
              return (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={isChecked}
                  />
                  {option.first_name} {option.last_name}
                </li>
              );
            }}
            sx={{ width: "95%" }}
            renderInput={(params) => (
              <TextField {...params} label="Add members" />
            )}
          />
          {/* Title above the list of selected users */}
          <Typography
            variant="h6"
            sx={{
              marginTop: 2,
              marginBottom: 1,
              textAlign: "right",
            }}
          >
            Admin
          </Typography>

          {selectedUsers.map((user, index) => (
            <List key={index} sx={{ width: "100%" }}>
              <ListItem
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  {index + 1}.
                </Typography>
                <Avatar sx={{ marginRight: 2 }}>
                  {user.first_name.charAt(0)}
                  {user.last_name.charAt(0)}
                </Avatar>
                <Typography variant="body1" sx={{ marginRight: 2 }}>
                  {user.first_name} {user.last_name}
                </Typography>
                {/* Checkbox aligned to the right */}
                <Checkbox
                  checked={adminStatus[user.id] || false}
                  onChange={() => handleAdminCheckboxChange(user.id)}
                  sx={{ ml: "auto" }}
                />
              </ListItem>
            </List>
          ))}

          <Box
            sx={{
              display: "flex",
              width: "100%",
              marginTop: 2,
              justifyContent: "space-between",
            }}
          >
            <Button
              onClick={handleClose}
              variant="outlined"
              sx={{ color: "gray", borderColor: "gray" }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              sx={{ backgroundColor: "#007DAB" }}
              onClick={handleAddMembers}
            >
              Save
            </Button>
          </Box>
          <IconButton
            sx={{ position: "absolute", top: 6, left: 8, color: "#007dab" }}
            onClick={handleClose}
          >
            <ArrowBack />
          </IconButton>
        </Box>
      </Modal>
    </React.Fragment>
  );
}

const PersistentDrawerLeft = () => {
  const api = useAxios();
  const token = localStorage.getItem("authTokens");
  const { logoutUser } = useContext(AuthContext);
  const [projects, setProjects] = useState([]);
  const [closedProjects, setClosedProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [members, setMembers] = useState([]);
  const [userId, setUserId] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [admins, setAdmins] = useState([]);
  const [project, setProject] = useState([]);
  
  if (token) {
    const decode = jwtDecode(token);
    var first_name = decode.first_name;
    var last_name = decode.last_name;
  }

  useEffect(() => {
    if (token) {
      const decode = jwtDecode(token);
      setUserId(decode.id);
    }
  }, [token]);

  const isMatchingUrl = () => {
    const { pathname } = location;
    const projectDashboardPattern = /^\/projects\/dashboard\/[^\/]+\/[^\/]+$/;
    const deliverablesPattern =
      /^\/projects\/dashboard\/[^\/]+\/[^\/]+\/deliverables$/;

    return (
      projectDashboardPattern.test(pathname) ||
      deliverablesPattern.test(pathname)
    );
  };

  const [searchClicked, setSearchClicked] = useState(false);

  // Function to toggle search icon click state
  const handleSearchClick = () => {
    setSearchClicked(!searchClicked);
  };

  const shouldRenderAutocomplete = (searchClicked) => {
    const screenWidth = window.innerWidth;
    // Logic to determine whether autocomplete should be rendered
    return searchClicked && screenWidth < 600; // Render autocomplete if search icon is clicked and screen width is less than 600px
  };

  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [darkMode, setDarkMode] = useState(false);
  const [toggle, setToggle] = useState("light");
  const [options, setOptions] = useState([]);
  const location = useLocation();
  let { projectId } = useParams();
  const navigate = useNavigate();

  const [openModal, setModalOpen] = useState(false);
  const handleOpen = () => setModalOpen(true);
  const handleClose = () => setModalOpen(false);

  useEffect(() => {
    const fetchMembers = async () => {
      try {
        if (projectId === undefined) {
          projectId = 0;
        }

        const response = await api.get(`/projects/${projectId}/`);
        let membersData = response.data;
        setProject(response.data);

        setMembers(membersData.users);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };
    const fetchUsers = async () => {
      try {
        const response = await api.get("/users/");
        let usersData = response.data;

        setUsers(usersData);
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    fetchMembers();
    fetchUsers();
  }, [location.pathname]);

  useEffect(() => {
    // Function to handle window resize event
    const handleResize = () => {
      setSearchClicked(false); // Reset search icon click state when screen size changes
    };
    

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
  const fetchData = async () => {
    try {
      let response = [];
      let projectIds = [];
      let projectsData = [];
      let closedProjectsData = [];

      if (location.pathname === "/projects" || location.pathname === "/") {
        // Fetch projects and closed projects
        const projectsResponse = await api.get("/projects/");
        const closedProjectsResponse = await api.get("/closedProjects/");
        projectsData = projectsResponse.data;
        closedProjectsData = closedProjectsResponse.data;

        setProjects(projectsData); // Set the projects array
        setClosedProjects(closedProjectsData);

        // Collect all project IDs from both responses
        projectIds = [
          ...projectsData.map((project) => project.id),
          ...closedProjectsData.map((project) => project.id),
        ];
      } else {
        // If not on projects page, fetch only deliverables for a specific project
        projectIds = [projectId];
      }

      // Fetch deliverables for each project ID
      const deliverablesData = await Promise.all(
        projectIds.map(async (id) => {
          const deliverablesResponse = await api.get(
            `/deliverables/?project=${id}`
          );
          return deliverablesResponse.data;
        })
      );

      // Flatten the array of arrays into a single array
      const deliverables = deliverablesData.flat();

      // Combine projects, closed projects, and deliverables into a single response array
      response = [
        ...(location.pathname === "/projects" || location.pathname === "/"
          ? projectsData.concat(closedProjectsData)
          : []),
        ...deliverables,
      ];

      // Map the response to a uniform structure and set options state
      setOptions(
        response.map((item) => {
          if (item.id && item.project && item.name) {
            // Assuming the item represents a deliverable
            return {
              id: item.id,
              project: item.project,
              name: item.name,
              file: item.file,
              type: "deliverable", // Adding a type property
            };
          } else if (item.id && item.projectName) {
            // Assuming the item represents a project
            return {
              id: item.id,
              name: item.projectName,
              type: "project", // Adding a type property
            };
          }
          // Handling unexpected item structures
          return null;
        }).filter(Boolean) // Filter out any null values from the map
      );
    } catch (error) {
      console.error("Error fetching data:", error); // Log any errors
    }
  };

  fetchData();
}, [location, projectId]);



  const handleDarkModeChange = () => {
    if (darkMode && toggle) {
      setToggle((theme.palette.mode = "light"));
      setDarkMode(false);
    } else {
      setToggle((theme.palette.mode = "dark"));
      setDarkMode(true);
    }

    // Your dark mode change logic here
  };

  const list = [
    {
      name: "Home",
      url: "/",
      icon: Home,
    },
    {
      name: "Instrument index",
      url: "/",
      icon: Description,
    },
    {
      name: "DataSheets",
      url: "/",
      icon: ViewList,
    },
    {
      name: "Packages",
      url: "/",
      icon: Assignment,
    },
    {
      name: "Vendor Docs",
      url: "/",
      icon: CreditCardIcon,
    },
    {
      name: "Reports",
      url: "/",
      icon: Assessment,
    },
    {
      name: "Administration",
      url: "/",
      icon: SettingsIcon,
    },
    {
      name: "Suppliers",
      url: "/",
      icon: Category,
    },
    {
      name: "Recycle Bin",
      url: "/recyclebin",
      icon: DeleteIcon,
    },
  ];

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };
  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const menuId = "primary-search-account-menu";
  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      id={menuId}
      keepMounted
      PaperProps={{
        elevation: 0,
        sx: {
          overflow: "visible",
          filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
          mt: 1.5,
          "& .MuiAvatar-root": {
            width: 72,
            height: 72,
            color: "black",
            fontSize: "h4.fontSize",
            border: 1,
            ml: -0.5,
            mr: 1,
          },
          "&::before": {
            content: '""',
            display: "block",
            position: "absolute",
            top: 0,
            right: 14,
            width: 10,
            height: 10,
            bgcolor: "background.paper",
            transform: "translateY(-50%) rotate(45deg)",
            zIndex: 0,
          },
        },
      }}
      transformOrigin={{ horizontal: "right", vertical: "top" }}
      anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <Avatar>
          {first_name.charAt(0)}
          {last_name.charAt(0)}
        </Avatar>
      </MenuItem>
      <MenuItem sx={{ fontWeight: "bold" }} onClick={handleMenuClose}>
        {first_name} {last_name}
      </MenuItem>
      <Divider />
      <MenuItem component={Link} onClick={handleMenuClose} to="/myprofile">
        <ListItemIcon>
          <Settings fontSize="small" />
        </ListItemIcon>
        Settings
      </MenuItem>
      <MenuItem onClick={logoutUser}>
        <ListItemIcon>
          <Logout fontSize="small" />
        </ListItemIcon>
        Logout
      </MenuItem>
    </Menu>
  );

  const mobileMenuId = "primary-search-account-menu-mobile";
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem>
        <IconButton size="large" aria-label="show 4 new mails" color="inherit">
          <Badge badgeContent={0} color="error">
            <MailIcon />
          </Badge>
        </IconButton>
        <p>Messages</p>
      </MenuItem>
      <MenuItem>
        <IconButton
          size="large"
          aria-label="show 17 new notifications"
          color="inherit"
        >
          <Badge badgeContent={0} color="error">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Notifications</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <Avatar>
            {first_name.charAt(0)}
            {last_name.charAt(0)}
          </Avatar>
        </IconButton>
        <Typography sx={{ minWidth: 100 }}>Profile</Typography>
      </MenuItem>
    </Menu>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        elevation={3}
        position="fixed"
        open={open}
        sx={{ backgroundColor: "secondary.main" }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{ mr: 2, ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>

          <NavLink
            to={"/"}
            className="no-underline text-white"
            variant="h6"
            nowrap
            component="div"
            sx={{ display: { xs: "none", sm: "block" } }}
          >
            <img className="h-10 rounded-full bg-white" alt="logo" src={logo} />
          </NavLink>
          <Stack spacing={2} sx={{ width: "20vw" }}>
            {location.pathname !== "/recyclebin" && (
              <Box sx={{ position: "relative" }}>
                {/* Display Autocomplete by default on screens bigger than xs */}
                <Box sx={{ display: { xs: "none", md: "block" } }}>
                  <Autocomplete
                    id="search"
                    freeSolo
                    options={options}
                    autoHighlight
                    getOptionLabel={(option) => option.name}
                    onChange={(event, option) => {
                      if (option) {
                        if (
                          option.type === "deliverable" &&
                          option.file !== null
                        ) {
                          const fileUrl = `https://3ebingo.co.za${option.file}`;
                          navigate(
                            `/pdfviewer?fileUrl=${encodeURIComponent(fileUrl)}`
                          );
                        } else if (option.type === "project") {
                          navigate(`/projects/dashboard/${option.id}/open`);
                        } else if (option.type === "deliverable") {
                          const associatedProject = projects.find(
                            (project) => project.id === option.project
                          );
                          if (associatedProject) {
                            const projectType = associatedProject.is_closed
                              ? "closed"
                              : "open";
                            navigate(
                              `/projects/dashboard/${option.project}/${projectType}/deliverables`
                            );
                          } else {
                            const closedProject = closedProjects.find(
                              (project) => project.id === option.project
                            );
                            if (closedProject) {
                              navigate(
                                `/projects/dashboard/${option.project}/closed/deliverables`
                              );
                            } else {
                              console.error("Project not found");
                            }
                          }
                        }
                      }
                    }}
                    renderInput={(params) => (
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          {...params}
                          placeholder="Search this library"
                          sx={{
                            position: "relative",
                            color: "white",
                            backgroundColor: alpha(
                              theme.palette.common.white,
                              0.15
                            ),
                            "&:hover": {
                              backgroundColor: alpha(
                                theme.palette.common.white,
                                0.25
                              ),
                            },
                            marginRight: theme.spacing(2),
                            marginLeft: 0,
                            width: "100%",
                            [theme.breakpoints.up("sm")]: {
                              marginLeft: theme.spacing(3),
                              width: "50ch",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            autoComplete: "new-password", // disable autocomplete and autofill
                            startAdornment: (
                              <ListItemIcon>
                                <SearchIcon />
                              </ListItemIcon>
                            ),
                          }}
                        />
                      </Box>
                    )}
                    renderOption={(props, option, { selected }) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <ListItemIcon>
                          {option.type === "project" ? (
                            <Folder /> // Folder icon for projects
                          ) : (
                            <Note /> // Note icon for deliverables
                          )}
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                      </Box>
                    )}
                  />
                </Box>
                {/* Display search icon button on xs screens */}
                <IconButton
                  onClick={handleSearchClick}
                  sx={{
                    position: "absolute",
                    top: "50%",
                    transform: "translateY(-50%)",
                    left: "8px",
                    color: "white",
                    display: { xs: "block", md: "none" },
                  }}
                >
                  <SearchIcon />
                </IconButton>
                {/* Render Autocomplete when showAutocomplete is true (on small screens) */}
                {shouldRenderAutocomplete(searchClicked) && (
                  <Autocomplete
                    id="search"
                    freeSolo
                    options={options}
                    onChange={(event, option) => {
                      if (option) {
                        if (
                          option.type === "deliverable" &&
                          option.file !== null
                        ) {
                          const fileUrl = `https://3ebingo.co.za${option.file}`;
                          // Navigate to PDFReader component with file URL as parameter
                          navigate(
                            `/pdfviewer?fileUrl=${encodeURIComponent(fileUrl)}`
                          );
                        } else if (option.type === "project") {
                          navigate(`/projects/dashboard/${option.id}`);
                        } else if (option.type === "deliverable") {
                          navigate(
                            `/projects/dashboard/${option.project}/deliverables`
                          );
                        }
                      }
                    }}
                    getOptionLabel={(option) => option.name}
                    renderInput={(params) => (
                      <Box sx={{ position: "relative" }}>
                        <TextField
                          {...params}
                          placeholder="Search this library"
                          sx={{
                            position: "relative",
                            color: "white",
                            backgroundColor: alpha(
                              theme.palette.common.white,
                              0.15
                            ),
                            "&:hover": {
                              backgroundColor: alpha(
                                theme.palette.common.white,
                                0.25
                              ),
                            },
                            marginRight: theme.spacing(2),
                            marginLeft: 0,
                            width: "100%",
                            [theme.breakpoints.up("sm")]: {
                              marginLeft: theme.spacing(3),
                              width: "50ch",
                            },
                          }}
                          InputProps={{
                            ...params.InputProps,
                            startAdornment: (
                              <ListItemIcon>
                                <SearchIcon />
                              </ListItemIcon>
                            ),
                          }}
                        />
                      </Box>
                    )}
                    renderOption={(props, option, { selected }) => (
                      <Box
                        component="li"
                        {...props}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <ListItemIcon>
                          {option.type === "project" ? (
                            <Folder /> // Folder icon for projects
                          ) : (
                            <Note /> // Note icon for deliverables
                          )}
                        </ListItemIcon>
                        <ListItemText primary={option.name} />
                      </Box>
                    )}
                  />
                )}
              </Box>
            )}
          </Stack>

          <Box sx={{ flexGrow: 1 }} />
          <MoonSwitch checked={darkMode} onChange={handleDarkModeChange} />
          <Box sx={{ display: { xs: "none", md: "flex" } }}>
            <Tooltip title={`Messages`}>
              <IconButton
                size="large"
                aria-label="show 4 new mails"
                color="inherit"
              >
                <Badge badgeContent={0} color="error">
                  <MailIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <Tooltip title={`Notifications`}>
              <IconButton
                size="large"
                aria-label="show 17 new notifications"
                color="inherit"
              >
                <Badge badgeContent={0} color="error">
                  <NotificationsIcon />
                </Badge>
              </IconButton>
            </Tooltip>
            <IconButton
              size="large"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <Tooltip title={`${first_name} ${last_name}`}>
                <Avatar>
                  {first_name.charAt(0)}
                  {last_name.charAt(0)}
                </Avatar>
              </Tooltip>
            </IconButton>
          </Box>

          <Box sx={{ display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {renderMobileMenu}
      {renderMenu}

      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {open && <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List
          component={Stack}
          alignItems="center"
          justifyContent="center"
          disablePadding
        >
          {list.map((item, index) => (
            <Tooltip title={item.name}>
              <ListItem
                key={index}
                disablePadding
                sx={{ width: "80%" }}
                disableGutters
              >
                <Link
                  to={`${item.url}`}
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <ListItemButton centerRipple>
                    <ListItemIcon>
                      <item.icon />
                    </ListItemIcon>
                    <ListItemText primary={item.name} />
                  </ListItemButton>
                </Link>
              </ListItem>
            </Tooltip>
          ))}
        </List>
        <Divider />
      </Drawer>
      <Box>
        <DrawerHeader />
        <BasicBreadcrumbs />
        {isMatchingUrl() && (
          <IconButton
            sx={{ position: "absolute", top: 80, right: 15, fontSize: 16 }}
            onClick={handleOpen}
          >
            <Person fontSize="small" /> {members.length} members
          </IconButton>
        )}
      </Box>
      <Modal
        open={openModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h5" component="h2">
            Project Membership
          </Typography>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {members.length} members
          </Typography>
          <ChildModal />
          {members.map((member, index) => (
            <List key={index} sx={{ height: "95%" }}>
              <ListItem>
                <ListItemAvatar>
                  <Avatar>
                    <Person />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText>
                  {member.first_name} {member.last_name}
                </ListItemText>
              </ListItem>
            </List>
          ))}
        </Box>
      </Modal>
    </Box>
  );
};

export default PersistentDrawerLeft;
