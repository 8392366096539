import axios from "axios";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../images/logo.png";

const Activate = () => {
  const navigate = useNavigate();
  const { encoded_email, otp_code } = useParams(); // Retrieve email from URL params
  const [loading, setLoading] = useState(false); // State for loading
  const [verificationStatus, setVerificationStatus] = useState("");

  useEffect(() => {
    console.log("useEffect triggered");
    const verifyAccount = async (otp_code) => {
      setLoading(true); // Set loading state to true when request starts
      try {
        const response = await axios.post(
          `https://3ebingo.co.za/api/activate/${encoded_email}/`,
          { otp_code } // Send otp_code as data
        );
        setVerificationStatus(response.data.status);
        toast.success("Account verification success.");
        navigate("/login");
      } catch (error) {
        setVerificationStatus(error.response.data.error);
      } finally {
        setLoading(false); // Set loading state to false when request completes
      }
    };

    verifyAccount(otp_code);
  }, [otp_code, encoded_email]); // Dependency array as second argument

  const resendOTP = async () => {
    const decodedEmail = atob(encoded_email);
    console.log("Decoded email: ", decodedEmail);

    setLoading(true); // Set loading state to true when request starts
    try {
      const response = await axios.post(
        `https://3ebingo.co.za/api/resend-otp/`,
        {
          email: decodedEmail,
        }
      );
      console.log("Decoded email: ", decodedEmail);
      if (response.status === 200) {
        toast.success("Email has been sent");
      }
    } catch (error) {
      toast.error(error.response.data.error);
    } finally {
      setLoading(false); // Set loading state to false when request completes
    }
  };

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      height="100vh"
    >
      <img src={logo} alt="Logo" width={"30%"} height={120} marginBottom={10} />

      <Grid>
        <Typography variant="h5" gutterBottom>
          {verificationStatus}
        </Typography>
      </Grid>
      <Grid>
        <Button
          variant="contained"
          sx={{ textDecoration: "none", backgroundColor: "#007DAB" }}
          onClick={resendOTP}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            "Resend Verification email"
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

export default Activate;
