import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import GetAppIcon from "@mui/icons-material/GetApp";
import {
  FileExcelFilled,
  FileWordFilled,
  FileImageFilled,
  FileTextFilled,
  FilePdfFilled,
} from "@ant-design/icons";
import { Delete } from "@mui/icons-material";
import { useLocation, useParams } from "react-router-dom";
import useAxios from "./utils/useAxios";

const ClientDocs = () => {
  const api = useAxios();
  const { projectId, projectType } = useParams();
  const [files, setFiles] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [project, setProject] = useState(null);
  const [projectNumber, setProjectNumber] = useState("");
  const [projectName, setProjectName] = useState("");
  const fileBaseUrl = "https://3ebingo.co.za";

  useEffect(() => {
    const fetchProject = async () => {
      try {
        let response;
        if (projectType === "closed") {
          response = await api.get(`/closedProjects/${projectId}/`);
        } else {
          response = await api.get(`/projects/${projectId}/`);
        }
        setProject(response.data);
        setProjectName(response.data.projectName);
        setProjectNumber(response.data.projectNumber);
      } catch (error) {
        console.error("Error fetching project:", error);
      }
    };

    const fetchFiles = async () => {
      try {
        const response = await api.get(`/clientdocs/?project=${projectId}`);
        setFiles(response.data);
        console.log("clientdocs data", response.data)
      } catch (error) {
        console.error("Error fetching files:", error);
      }
    };

    fetchProject();
    fetchFiles();
  }, [projectId]);

  const handleFileChange = async (event) => {
    const filesArray = Array.from(event.target.files);
    
    // Create a new FormData instance
    const formData = new FormData();
    
    // Add files to FormData and prepare file objects with URLs
    const newFiles = filesArray.map((file) => {
      formData.append("file", file);
      formData.append("name", file.name);
      formData.append("project", projectId);
  
      // Create a URL for the local file
      return {
        name: file.name,
        type: file.type,
        url: URL.createObjectURL(file),
      };
    });
  
    try {
      // Send the FormData to the server
      const response = await api.post("/clientdocs/", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
  
      // Update state with the new files including server response
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    } catch (error) {
      console.error("Error uploading files:", error);
    }
  };
  

  const handleFileClick = (file) => {
    if (selectedFiles.some((f) => f.id === file.id)) {
      setSelectedFiles(selectedFiles.filter((f) => f.id !== file.id));
    } else {
      setSelectedFiles([...selectedFiles, file]);
    }
  };

  const handleDownload = () => {
    selectedFiles.forEach((file) => {
      const link = document.createElement("a");
      link.href = `${fileBaseUrl}${file.file}`; // Construct the full URL if needed
      link.download = file.name;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    });
  };

  const handleDelete = async () => {
    try {
      // Ensure each file has an ID and is included in the selectedFiles
      const validSelectedFiles = selectedFiles.filter(file => file.id);
  
      if (validSelectedFiles.length === 0) {
        console.warn("No valid files selected for deletion.");
        return;
      }
  
      // Delete files from the server
      await Promise.all(
        validSelectedFiles.map((file) =>
          api.delete(`/clientdocs/${file.id}/`)
        )
      );
  
      // Update the local state to remove deleted files
      setFiles(prevFiles => prevFiles.filter(file => !validSelectedFiles.some(selected => selected.id === file.id)));
      setSelectedFiles([]);
    } catch (error) {
      console.error("Error deleting files:", error);
    }
  };
  

  const getThumbnail = (file) => {
    if (!file.file) {
      // Handle the case where file.file is undefined or null
      return <FileTextFilled style={{ fontSize: 100, color: "#6c757d" }} />;
    }
  
    if (file.file.includes("image")) {
      return (
        <img
          src={file.file}
          alt={file.name}
          style={{ width: 100, height: 100, objectFit: "cover" }}
        />
      );
    } else if (file.file.includes("pdf")) {
      return <FilePdfFilled style={{ fontSize: 100, color: "#D32F2F" }} />;
    } else if (file.file.includes("spreadsheet") || file.file.includes("excel")) {
      return <FileExcelFilled style={{ fontSize: 100, color: "#28a745" }} />;
    } else if (file.file.includes("wordprocessingml")) {
      return <FileWordFilled style={{ fontSize: 100, color: "#0071c1" }} />;
    } else {
      return <FileTextFilled style={{ fontSize: 100, color: "#6c757d" }} />;
    }
  };
  

  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          width: "88%",
          margin: 4,
          flexDirection: "column",
        }}
      >
        <Typography variant="h4" sx={{ textAlign: "center" }}>
          {projectNumber} {projectName}
        </Typography>

        {selectedFiles.length > 0 ? (
          <Paper
            elevation={1}
            sx={{ width: "auto", alignItems: "center", display: "flex" }}
          >
            <IconButton onClick={handleDelete}>
              <Delete color="error" />
            </IconButton>
            <IconButton onClick={handleDownload}>
              <GetAppIcon />
            </IconButton>
          </Paper>
        ) : (
          <>
            <input
              type="file"
              accept="*/*"
              onChange={handleFileChange}
              multiple
              style={{ display: "none" }}
              id="fileInput"
            />
            <div
              className="flex justify-between my-4"
              style={{ width: 250, margin: "normal" }}
            >
              <label htmlFor="fileInput">
                <Button
                  sx={{
                    marginBottom: "10px",
                    backgroundColor: "#007DAB",
                    color: "#FFFFFF",
                  }}
                  variant="contained"
                  component="span"
                >
                  <Tooltip title="Upload file">
                    <FileUploadIcon /> New
                  </Tooltip>
                </Button>
              </label>
            </div>
          </>
        )}
      </Box>
      <Grid
        container
        spacing={3}
        justifyContent="start"
        sx={{
          display: "flex",
          flexDirection: "row",
          marginTop: "4px",
          width: "88vw",
          marginBottom: 4,
        }}
      >
        {files.map((file, index) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={3}
            lg={2}
            align="center"
            key={index}
            onClick={() => handleFileClick(file)}
            style={{
              cursor: "pointer",
              border: selectedFiles.includes(file)
                ? "2px solid #007DAB"
                : "none",
            }}
          >
            {getThumbnail(file)}
            <Typography variant="body1" className="text-black" align="center">
              {file.name}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ClientDocs;
