export const statuses = [
  { title: "Not Started" },
  { title: "IDR" },
  { title: "ICR" },
  { title: "IFC" },
  { title: "As Built" },
];

export const disciplineTypes = [
  {
    title: "Process",
  },
  {
    title: "Control & Instrumentation",
  },
  {
    title: "Electrical",
  },
];

export const deliverableTypes = [
  {
    title: "Block Flow Diagram",
    value: "bfd",
  },
  {
    title: "Circuit Diagram",
    value: "cd",
  },
  {
    title: "Equipment Datasheets",
    value: "PE Datasheets",
  },
  {
    title: "Equipment List",
    value: "PE List",
  },
  {
    title: "Front Page",
    value: "fp",
  },
  {
    title: "Instrument Index",
    value: "Inst Idx",
  },
  {
    title: "Load List",
    value: "PL List",
  },
  {
    title: "P&ID",
    value: "pid",
  },
  {
    title: "Panel General Arrangement",
    value: "pga",
  },
  {
    title: "PFD",
    value: "pfd",
  },
  {
    title: "Pipe List",
    value: "PP List",
  },
  {
    title: "Special Pipe Item List",
    value: "SPI List",
  },
  {
    title: "Panel Schedule",
    value: "ps",
  },
  {
    title: "Table of Contents",
    value: "toc",
  },
  {
    title: "Terminal Diagram",
    value: "td",
  },
  {
    title: "Valve List",
    value: "PV List",
  },

  //C&I
  {
    title: "Control Valve List",
    value: "CV List",
  },
  {
    title: "Functional Diagram",
    value: "Func Diagram",
  },
  {
    title: "Instrument Power Distribution Drawing",
    value: "IPD Drawing",
  },
  {
    title: "IO List",
    value: "IO List",
  },
  {
    title: "Instrument List",
    value: "CI List",
  },
  {
    title: "Instrument Location Drawing",
    value: "IL Drawing",
  },
  {
    title: "Instrument Datasheets",
    value: "CI Datasheets",
  },
  {
    title: "Hookup Drawing",
    value: "CH Drawing",
  },
  {
    title: "Loop Drawing",
    value: "CL Drawing",
  },
  {
    title: "Network Drawing",
    value: "Net Drawing",
  },
  {
    title: "PLC Panel Drawing",
    value: "PLCP Drawing",
  },
  {
    title: "Remote IO Panel Drawing",
    value: "RIOP Drawing",
  },
  {
    title: "System Confiuration",
    value: "CS Configuration",
  },
  {
    title: "UPS Power Distribution Drawing",
    value: "UPSPD Drawing",
  },
  // Electrical
  {
    title: "Cable Block Diagram",
    value: "CBD",
  },
  {
    title: "Cable Schedule List",
    value: "CS List",
  },
  {
    title: "MCC Datasheet",
    value: "MCCDS",
  },
  {
    title: "Symbol Library",
    value: "SL",
  },
  {
    title: "Cubicle List",
    value: "CL",
  },
  {
    title: "Cable Schedule",
    value: "CS",
  },
  {
    title: "Earthing Drawing",
    value: "Earth Drawing",
  },
  {
    title: "Lighting And Small Power",
    value: "L&SP",
  },
  {
    title: "MCC Circuit Diagram",
    value: "MCCC Diagram",
  },
  {
    title: "MCC General Arrangement",
    value: "MCCG Arrangement",
  },
  {
    title: "MCC Single Line",
    value: "MCCS Line",
  },
  {
    title: "MV Single Line Drawing",
    value: "MVSL Drawing",
  },
  {
    title: "Power Distribution",
    value: "P Dist",
  },
];

export const countries = [
    { title: "Afghanistan", code: "+93" },
    { title: "Albania", code: "+355" },
    { title: "Algeria", code: "+213" },
    { title: "American Samoa", code: "+1-684" },
    { title: "Andorra", code: "+376" },
    { title: "Angola", code: "+244" },
    { title: "Anguilla", code: "+1-264" },
    { title: "Antarctica", code: "+672" },
    { title: "Antigua and Barbuda", code: "+1-268" },
    { title: "Argentina", code: "+54" },
    { title: "Armenia", code: "+374" },
    { title: "Aruba", code: "+297" },
    { title: "Australia", code: "+61" },
    { title: "Austria", code: "+43" },
    { title: "Azerbaijan", code: "+994" },
    { title: "Bahamas", code: "+1-242" },
    { title: "Bahrain", code: "+973" },
    { title: "Bangladesh", code: "+880" },
    { title: "Barbados", code: "+1-246" },
    { title: "Belarus", code: "+375" },
    { title: "Belgium", code: "+32" },
    { title: "Belize", code: "+501" },
    { title: "Benin", code: "+229" },
    { title: "Bermuda", code: "+1-441" },
    { title: "Bhutan", code: "+975" },
    { title: "Bolivia", code: "+591" },
    { title: "Bosnia and Herzegovina", code: "+387" },
    { title: "Botswana", code: "+267" },
    { title: "Brazil", code: "+55" },
    { title: "British Indian Ocean Territory", code: "+246" },
    { title: "British Virgin Islands", code: "+1-284" },
    { title: "Brunei", code: "+673" },
    { title: "Bulgaria", code: "+359" },
    { title: "Burkina Faso", code: "+226" },
    { title: "Burundi", code: "+257" },
    { title: "Cabo Verde", code: "+238" },
    { title: "Cambodia", code: "+855" },
    { title: "Cameroon", code: "+237" },
    { title: "Canada", code: "+1" },
    { title: "Cayman Islands", code: "+1-345" },
    { title: "Central African Republic", code: "+236" },
    { title: "Chad", code: "+235" },
    { title: "Chile", code: "+56" },
    { title: "China", code: "+86" },
    { title: "Christmas Island", code: "+61" },
    { title: "Cocos (Keeling) Islands", code: "+61" },
    { title: "Colombia", code: "+57" },
    { title: "Comoros", code: "+269" },
    { title: "Congo (Congo-Brazzaville)", code: "+242" },
    { title: "Cook Islands", code: "+682" },
    { title: "Costa Rica", code: "+506" },
    { title: "Croatia", code: "+385" },
    { title: "Cuba", code: "+53" },
    { title: "Curaçao", code: "+599" },
    { title: "Cyprus", code: "+357" },
    { title: "Czech Republic", code: "+420" },
    { title: "Denmark", code: "+45" },
    { title: "Djibouti", code: "+253" },
    { title: "Dominica", code: "+1-767" },
    { title: "Dominican Republic", code: "+1-809" },
    { title: "Ecuador", code: "+593" },
    { title: "Egypt", code: "+20" },
    { title: "El Salvador", code: "+503" },
    { title: "Equatorial Guinea", code: "+240" },
    { title: "Eritrea", code: "+291" },
    { title: "Estonia", code: "+372" },
    { title: "Eswatini", code: "+268" },
    { title: "Ethiopia", code: "+251" },
    { title: "Falkland Islands", code: "+500" },
    { title: "Faroe Islands", code: "+298" },
    { title: "Fiji", code: "+679" },
    { title: "Finland", code: "+358" },
    { title: "France", code: "+33" },
    { title: "French Guiana", code: "+594" },
    { title: "French Polynesia", code: "+689" },
    { title: "Gabon", code: "+241" },
    { title: "Gambia", code: "+220" },
    { title: "Georgia", code: "+995" },
    { title: "Germany", code: "+49" },
    { title: "Ghana", code: "+233" },
    { title: "Gibraltar", code: "+350" },
    { title: "Greece", code: "+30" },
    { title: "Greenland", code: "+299" },
    { title: "Grenada", code: "+1-473" },
    { title: "Guadeloupe", code: "+590" },
    { title: "Guam", code: "+1-671" },
    { title: "Guatemala", code: "+502" },
    { title: "Guernsey", code: "+44-1481" },
    { title: "Guinea", code: "+224" },
    { title: "Guinea-Bissau", code: "+245" },
    { title: "Guyana", code: "+592" },
    { title: "Haiti", code: "+509" },
    { title: "Honduras", code: "+504" },
    { title: "Hong Kong", code: "+852" },
    { title: "Hungary", code: "+36" },
    { title: "Iceland", code: "+354" },
    { title: "India", code: "+91" },
    { title: "Indonesia", code: "+62" },
    { title: "Iran", code: "+98" },
    { title: "Iraq", code: "+964" },
    { title: "Ireland", code: "+353" },
    { title: "Isle of Man", code: "+44-1624" },
    { title: "Israel", code: "+972" },
    { title: "Italy", code: "+39" },
    { title: "Ivory Coast", code: "+225" },
    { title: "Jamaica", code: "+1-876" },
    { title: "Japan", code: "+81" },
    { title: "Jersey", code: "+44-1534" },
    { title: "Jordan", code: "+962" },
    { title: "Kazakhstan", code: "+7" },
    { title: "Kenya", code: "+254" },
    { title: "Kiribati", code: "+686" },
    { title: "Kosovo", code: "+383" },
    { title: "Kuwait", code: "+965" },
    { title: "Kyrgyzstan", code: "+996" },
    { title: "Laos", code: "+856" },
    { title: "Latvia", code: "+371" },
    { title: "Lebanon", code: "+961" },
    { title: "Lesotho", code: "+266" },
    { title: "Liberia", code: "+231" },
    { title: "Libya", code: "+218" },
    { title: "Liechtenstein", code: "+423" },
    { title: "Lithuania", code: "+370" },
    { title: "Luxembourg", code: "+352" },
    { title: "Macao", code: "+853" },
    { title: "Madagascar", code: "+261" },
    { title: "Malawi", code: "+265" },
    { title: "Malaysia", code: "+60" },
    { title: "Maldives", code: "+960" },
    { title: "Mali", code: "+223" },
    { title: "Malta", code: "+356" },
    { title: "Marshall Islands", code: "+692" },
    { title: "Martinique", code: "+596" },
    { title: "Mauritania", code: "+222" },
    { title: "Mauritius", code: "+230" },
    { title: "Mayotte", code: "+262" },
    { title: "Mexico", code: "+52" },
    { title: "Micronesia", code: "+691" },
    { title: "Moldova", code: "+373" },
    { title: "Monaco", code: "+377" },
    { title: "Mongolia", code: "+976" },
    { title: "Montenegro", code: "+382" },
    { title: "Montserrat", code: "+1-664" },
    { title: "Morocco", code: "+212" },
    { title: "Mozambique", code: "+258" },
    { title: "Myanmar", code: "+95" },
    { title: "Namibia", code: "+264" },
    { title: "Nauru", code: "+674" },
    { title: "Nepal", code: "+977" },
    { title: "Netherlands", code: "+31" },
    { title: "New Caledonia", code: "+687" },
    { title: "New Zealand", code: "+64" },
    { title: "Nicaragua", code: "+505" },
    { title: "Niger", code: "+227" },
    { title: "Nigeria", code: "+234" },
    { title: "Niue", code: "+683" },
    { title: "Norfolk Island", code: "+672" },
    { title: "North Korea", code: "+850" },
    { title: "North Macedonia", code: "+389" },
    { title: "Northern Mariana Islands", code: "+1-670" },
    { title: "Norway", code: "+47" },
    { title: "Oman", code: "+968" },
    { title: "Pakistan", code: "+92" },
    { title: "Palau", code: "+680" },
    { title: "Palestine", code: "+970" },
    { title: "Panama", code: "+507" },
    { title: "Papua New Guinea", code: "+675" },
    { title: "Paraguay", code: "+595" },
    { title: "Peru", code: "+51" },
    { title: "Philippines", code: "+63" },
    { title: "Pitcairn Islands", code: "+64" },
    { title: "Poland", code: "+48" },
    { title: "Portugal", code: "+351" },
    { title: "Puerto Rico", code: "+1-787" },
    { title: "Qatar", code: "+974" },
    { title: "Réunion", code: "+262" },
    { title: "Romania", code: "+40" },
    { title: "Russia", code: "+7" },
    { title: "Rwanda", code: "+250" },
    { title: "Saint Barthélemy", code: "+590" },
    { title: "Saint Helena", code: "+290" },
    { title: "Saint Kitts and Nevis", code: "+1-869" },
    { title: "Saint Lucia", code: "+1-758" },
    { title: "Saint Martin", code: "+590" },
    { title: "Saint Pierre and Miquelon", code: "+508" },
    { title: "Saint Vincent and the Grenadines", code: "+1-784" },
    { title: "Samoa", code: "+685" },
    { title: "San Marino", code: "+378" },
    { title: "São Tomé and Príncipe", code: "+239" },
    { title: "Saudi Arabia", code: "+966" },
    { title: "Senegal", code: "+221" },
    { title: "Serbia", code: "+381" },
    { title: "Seychelles", code: "+248" },
    { title: "Sierra Leone", code: "+232" },
    { title: "Singapore", code: "+65" },
    { title: "Sint Maarten", code: "+1-721" },
    { title: "Slovakia", code: "+421" },
    { title: "Slovenia", code: "+386" },
    { title: "Solomon Islands", code: "+677" },
    { title: "Somalia", code: "+252" },
    { title: "South Africa", code: "+27" },
    { title: "South Korea", code: "+82" },
    { title: "South Sudan", code: "+211" },
    { title: "Spain", code: "+34" },
    { title: "Sri Lanka", code: "+94" },
    { title: "Sudan", code: "+249" },
    { title: "Suriname", code: "+597" },
    { title: "Svalbard and Jan Mayen", code: "+47" },
    { title: "Sweden", code: "+46" },
    { title: "Switzerland", code: "+41" },
    { title: "Syria", code: "+963" },
    { title: "Taiwan", code: "+886" },
    { title: "Tajikistan", code: "+992" },
    { title: "Tanzania", code: "+255" },
    { title: "Thailand", code: "+66" },
    { title: "Timor-Leste", code: "+670" },
    { title: "Togo", code: "+228" },
    { title: "Tokelau", code: "+690" },
    { title: "Tonga", code: "+676" },
    { title: "Trinidad and Tobago", code: "+1-868" },
    { title: "Tunisia", code: "+216" },
    { title: "Turkey", code: "+90" },
    { title: "Turkmenistan", code: "+993" },
    { title: "Tuvalu", code: "+688" },
    { title: "Uganda", code: "+256" },
    { title: "Ukraine", code: "+380" },
    { title: "United Arab Emirates", code: "+971" },
    { title: "United Kingdom", code: "+44" },
    { title: "United States", code: "+1" },
    { title: "Uruguay", code: "+598" },
    { title: "Uzbekistan", code: "+998" },
    { title: "Vanuatu", code: "+678" },
    { title: "Vatican City", code: "+379" },
    { title: "Venezuela", code: "+58" },
    { title: "Vietnam", code: "+84" },
    { title: "Wallis and Futuna", code: "+681" },
    { title: "Western Sahara", code: "+212" },
    { title: "Yemen", code: "+967" },
    { title: "Zambia", code: "+260" },
    { title: "Zimbabwe", code: "+263" }
  ];
  

