import useAxios from "../utils/useAxios";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "react-toastify/dist/ReactToastify.css";
import { countries } from "../../menuItems";

const MyProfile = () => {
  const api = useAxios();
  const [authTokens, setAuthTokens] = useState(
    localStorage.getItem("authTokens")
  );
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [userId, setUserId] = useState(null);
  const [mobileNumber, setMobileNumber] = useState("");
  const [countryCode, setCountryCode] = useState(""); // New state for country code

  useEffect(() => {
    if (authTokens) {
      const decode = jwtDecode(authTokens);
      setFirstName(decode.first_name);
      setLastName(decode.last_name);
      setEmail(decode.email);
      setUserId(decode.id);
      setMobileNumber(decode.mobile_number); // Assuming token has mobile_number
    }
  }, [authTokens]);

  const contactInfoValidationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    country: Yup.string().required("Country is required"),
    mobileNumber: Yup.string().required("Mobile Number is required"),
  });

  const passwordChangeValidationSchema = Yup.object().shape({
    oldPassword: Yup.string().required("Old Password is required"),
    newPassword: Yup.string().required("New Password is required"),
    confirmNewPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Passwords must match")
      .required("Confirm New Password is required"),
  });

  const handleUpdateContactInfo = async (
    values,
    { setSubmitting, setErrors }
  ) => {
    try {
      const formField = new FormData();
      formField.append("first_name", values.firstName);
      formField.append("last_name", values.lastName);
      formField.append("email", values.email);
      formField.append("mobile_number", `${countryCode}${values.mobileNumber}`); // Include country code

      const response = await api({
        method: "PUT",
        url: `/users/${userId}/`,
        data: formField,
      });

      // Assuming the backend returns new tokens
      // const { access, refresh } = response.data;
      // localStorage.setItem("authTokens", access);
      // setAuthTokens(access);

      // Optionally, update local state with decoded user details from new token
      // const decode = jwtDecode(access);
      // setFirstName(decode.first_name);
      // setLastName(decode.last_name);
      // setEmail(decode.email);
      // setMobileNumber(decode.mobile_number);
    } catch (error) {
      console.error("Error updating contact info", error);
      // Handle error appropriately
    } finally {
      setSubmitting(false);
    }
  };

  const handleChangePassword = async (
    values,
    { setSubmitting, setErrors, resetForm }
  ) => {
    try {
      const response = await api.put("/change-password/", {
        old_password: values.oldPassword,
        new_password: values.newPassword,
      });

      console.log("Password changed successfully", response.data);
      resetForm();
      // Handle success (e.g., show a notification or redirect)
    } catch (error) {
      if (error.response && error.response.data) {
        if (error.response.data.old_password) {
          setErrors({ oldPassword: error.response.data.old_password[0] });
        } else if (error.response.data.new_password) {
          setErrors({ newPassword: error.response.data.new_password[0] });
        } else {
          setErrors({
            general: "An error occurred while changing the password",
          });
        }
      } else {
        console.error("Error changing password", error);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <Paper
      sx={{
        minWidth: "30vw",
        position: "absolute",
        top: "120px",
        left: "50%",
        transform: "translateX(-50%)",
        padding: 4,
      }}
    >
      <Typography variant="h4" sx={{ marginBottom: 2 }}>
        My Profile
      </Typography>

      <Box
        sx={{
          width: "100%",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 1 }}>
          Contact Details
        </Typography>

        <Formik
          initialValues={{
            firstName: firstName,
            lastName: lastName,
            email: email,
            country: "",
            mobileNumber: mobileNumber,
          }}
          enableReinitialize
          validationSchema={contactInfoValidationSchema}
          onSubmit={handleUpdateContactInfo}
        >
          {({ isSubmitting, setFieldValue }) => (
            <Form>
              <Field name="firstName">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="First Name"
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="lastName">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="Last Name"
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="email">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="Email"
                    variant="outlined"
                    sx={{ marginBottom: 1, flexGrow: 1 }}
                    fullWidth
                    InputProps={{ readOnly: true }}
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="country">
                {({ field, meta }) => (
                  <Autocomplete
                    options={countries}
                    getOptionLabel={(option) => option.title}
                    fullWidth
                    onChange={(event, newValue) => {
                      setFieldValue("country", newValue?.title || "");
                      setCountryCode(newValue?.code || "");
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        {...field}
                        label="Choose Country"
                        variant="outlined"
                        sx={{ marginBottom: 1 }}
                        error={meta.touched && !!meta.error}
                        helperText={meta.touched && meta.error}
                      />
                    )}
                  />
                )}
              </Field>
              <Field name="mobileNumber">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="Mobile Number"
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                    fullWidth
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Button
                type="submit"
                sx={{ backgroundColor: "#007dab", marginBottom: 4 }}
                variant="contained"
                disabled={isSubmitting}
              >
                {isSubmitting ? <CircularProgress size={24} /> : "Save"}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>

      <Box
        sx={{
          width: "100%",
          borderRadius: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Typography variant="h5" sx={{ marginBottom: 2 }}>
          Change Password
        </Typography>

        <Formik
          initialValues={{
            oldPassword: "",
            newPassword: "",
            confirmNewPassword: "",
          }}
          validationSchema={passwordChangeValidationSchema}
          onSubmit={handleChangePassword}
        >
          {({ isSubmitting, errors }) => (
            <Form>
              <Field name="oldPassword">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="Old Password"
                    type="password"
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                    fullWidth
                    autoComplete="new-password"
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="newPassword">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="New Password"
                    type="password"
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                    fullWidth
                    autoComplete="new-password"
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              <Field name="confirmNewPassword">
                {({ field, meta }) => (
                  <TextField
                    {...field}
                    label="Confirm New Password"
                    type="password"
                    variant="outlined"
                    sx={{ marginBottom: 1 }}
                    fullWidth
                    autoComplete="new-password"
                    error={meta.touched && !!meta.error}
                    helperText={meta.touched && meta.error}
                  />
                )}
              </Field>
              {errors.general && (
                <Typography color="error" sx={{ marginBottom: 2 }}>
                  {errors.general}
                </Typography>
              )}
              <Button
                type="submit"
                variant="outlined"
                sx={{
                  color: "#007dab",
                  borderColor: "#007dab",
                  marginBottom: 4,
                }}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={24} />
                ) : (
                  "Change Password"
                )}
              </Button>
            </Form>
          )}
        </Formik>
      </Box>
    </Paper>
  );
};

export default MyProfile;
