import useAxios from "./utils/useAxios";
import React, { useState, useEffect, useRef } from "react";
import { Link, Link as RouterLink, useNavigate } from "react-router-dom";
import {
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Box,
  Typography,
  Grid,
  CircularProgress,
  useMediaQuery,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  Tabs,
  Tab,
  TextField,
  Modal,
  Button,
  Autocomplete,
  Checkbox,
  FormControlLabel,
  Paper,
} from "@mui/material";
import { deliverableTypes, disciplineTypes } from "../menuItems";
import AddIcon from "@mui/icons-material/Add";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import { SearchOutlined } from "@ant-design/icons";
import {
  Input,
  Space,
  Table,
  Button as Button2,
  Divider,
  Modal as Modal2,
} from "antd";
import Highlighter from "react-highlight-words";
import { ArrowBack, Delete, Edit, Info, PersonAdd } from "@mui/icons-material";
import { jwtDecode } from "jwt-decode";
import Tooltip from "@mui/material/Tooltip";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import * as yup from "yup";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "90vh",
  overflow: "auto",
};

const styleEditProject = {
  position: "absolute",
  top: "0",
  right: "0",
  width: "40vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
  maxHeight: "100vh",
  overflow: "auto",
};

const styleModal = {
  position: "absolute",
  top: "25%",
  left: "50%",
  transform: "translate(-50%, -15%)",
  width: "30vw",
  minHeight: "80vh",
  maxHeight: "80vh",
  overflow: "auto",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const { confirm } = Modal2;

const schema = yup.object().shape({
  projectNumber: yup.string().required("Project Number is required"),
  projectName: yup.string().required("Project Name is required"),
  customer: yup.string().required("Customer is required"),
  epcm: yup.string().required("EPCM is required"),
  site: yup.string().required("Site is required"),
  projectAreas: yup.string().required("Add at least one Area"),
});

const ShowProjects = () => {
  const api = useAxios();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [closedProjects, setClosedProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openEditProject, setOpenEditProject] = useState(false);
  const [deliverables, setDeliverables] = useState([]);
  const [selectedProjectDeliverables, setSelectedProjectDeliverables] =
    useState([]);
  const [tabValue, setTabValue] = useState(0);
  const [openDialog, setOpenDialog] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedProjectRows, setSelectedProjectRows] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const searchInput = useRef(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 25,
    },
  });
  const checkedIcon = <CheckBoxIcon fontSize="small" />;
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
  const [users, setUsers] = useState([]);

  const [projectNumber, setNumber] = useState("");
  const [projectName, setName] = useState("");
  const [salesOrderNumber, setSalesOrderNumber] = useState("");
  const [invoicingProgress, setInvoicingProgress] = useState("");
  const [customer, setCustomer] = useState("");
  const [epcm, setEPCM] = useState("");
  const [site, setSite] = useState("");
  const [projectVoteNo, setProjectVoteNo] = useState("");
  const [pcn, setPCN] = useState("");
  const [projectAreas, setprojectAreas] = useState("");
  const [is_closed, setClosed] = useState(false);
  const [errors, setErrors] = useState({});
  const [projectType, setProjectType] = useState("");
  const navigate = useNavigate();

  const token = localStorage.getItem("authTokens");

  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [isLoading, setIsLoading] = useState(false);

  let email = "";
  let emailDomain = "";
  if (token) {
    const decode = jwtDecode(token);
    email = decode.email;
    emailDomain = email.split("@")[1].split(".")[0];
  }

  const handleOpenModal = () => {
    setOpenModal(true);
  };
  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Prepare user ids from selectedUsers array
        const user_ids = selectedUsers.map((user) => user.id);

        for (const project of selectedProjectRows) {
          const response = await api.get(`/projects/${project.id}/`);
          const projectData = response.data;
          setSelectedUsers(projectData.users); // Initialize selected users
          console.log("Selected users: ", selectedUsers);
        }

        // Fetch all users
        const usersResponse = await api.get("/users/");
        setUsers(usersResponse.data);
      } catch (error) {
        console.error("Error loading data:", error);
      } finally {
        setLoading(false); // Set loading to false after data is fetched
      }
    };

    fetchData();
  }, []);

  const handleAddMembers = async () => {
    try {
      // Prepare user ids from selectedUsers array
      const user_ids = selectedUsers.map((user) => user.id);

      // Iterate over each selected project and assign selected users
      for (const project of selectedProjectRows) {
        await api.put(`/projects/${project.id}/`, { users: user_ids });
      }

      // Close modal after successful update
      handleCloseModal();
    } catch (error) {
      console.error("Error adding members:", error);
    }
  };

  const fetchDeliverables = async () => {
    try {
      const response = await api.get(`/deliverables/`);
      setDeliverables(response.data);
    } catch (error) {
      console.error("Error fetching Deliverables:", error);
    }
  };

  const fetchProjects = async () => {
    try {
      const response = await api.get("/projects/");
      let projectsData = response.data;

      setProjects(projectsData);
    } catch (error) {
      console.error("Error fetching Projects:", error);
    }
  };

  const fetchClosedProjects = async () => {
    try {
      const response = await api.get("/closedProjects/");
      let closedProjectsData = response.data;

      setClosedProjects(closedProjectsData);
    } catch (error) {
      console.error("Error fetching Closed Projects:", error);
    }
  };

  const fetchPermissions = async () => {
    try {
      // Make the API request with the access token included in the headers
      const response = await api.get("/user/permissions/");

      // Set the permissions state with the data received from the API
      setPermissions(response.data.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    fetchProjects();
    fetchDeliverables();
    setLoading(false);
    fetchPermissions();
  }, []);

  useEffect(() => {
    fetchClosedProjects();
  }, []);

  useEffect(() => {
    const updateProjectStatus = async (project) => {
      const progress = calculateProjectProgress(project.id);
      if (progress === 100 && !project.is_closed) {
        try {
          // Update project status
          await api.put(`/projects/${project.id}/`, {
            is_closed: true,
            projectNumber: project.projectNumber,
            projectName: project.projectName,
            salesOrderNumber: project.salesOrderNumber,
            invoicingProgress: project.invoicingProgress,
            customer: project.customer,
            projectVoteNo: project.projectVoteNo,
            pcn: project.pcn,
            projectAreas: project.projectAreas,
          });
        } catch (error) {
          console.error("Error updating project:", error);
        }
      }
    };

    projects.forEach(updateProjectStatus);
  }, [projects]);

  const deleteProject = async (projectId) => {
    try {
      await api.delete(`/projects/${projectId}/`);
      // Filter out the deleted project from the projects state
      setProjects((prevProjects) =>
        prevProjects.filter((project) => project.id !== projectId)
      );

      setOpenDialog(false);
    } catch (error) {
      toast.error("Error deleting project:", error);
    } finally {
      toast.success("Projects successfully moved to Recycle Bin");
    }
  };

  const handlePreviewClick = (projectId) => {
    const project = projects.find((project) => project.id === projectId);
    setSelectedProject(project);
    setSelectedProjectDeliverables(
      deliverables.filter((deliverable) => deliverable.project === projectId)
    );
    setOpenDrawer(true);
  };

  const handlePreviewClickClosed = (projectId) => {
    const project = closedProjects.find((project) => project.id === projectId);
    setSelectedProject(project);
    setSelectedProjectDeliverables(
      deliverables.filter((deliverable) => deliverable.project === projectId)
    );
    setOpenDrawer(true);
  };

  const handleEditClick = (projectId) => {
    const project = projects.find((project) => project.id === projectId);
    setProjectType("open");
    setSelectedProject(project);
    setOpenEditProject(true);
    setNumber(project.projectNumber);
    setName(project.projectName);
    setSalesOrderNumber(project.salesOrderNumber);
    setInvoicingProgress(project.invoicingProgress);
    setCustomer(project.customer);
    setEPCM(project.epcm);
    setSite(project.site);
    setProjectVoteNo(project.projectVoteNo);
    setPCN(project.pcn);
    setprojectAreas(project.projectAreas);
    setClosed(project.is_closed);
  };

  const handleEditClickClosed = (projectId) => {
    const project = closedProjects.find((project) => project.id === projectId);
    setProjectType("closed");
    setSelectedProject(project);
    setOpenEditProject(true);
    setNumber(project.projectNumber);
    setName(project.projectName);
    setSalesOrderNumber(project.salesOrderNumber);
    setInvoicingProgress(project.invoicingProgress);
    setCustomer(project.customer);
    setEPCM(project.epcm);
    setSite(project.site);
    setProjectVoteNo(project.projectVoteNo);
    setPCN(project.pcn);
    setprojectAreas(project.projectAreas);
    setClosed(project.is_closed);
  };

  const handleEditClose = () => {
    setOpenEditProject(false);
    setSelectedProject(null);
    setProjectType("");
  };

  const handleDrawerClose = () => {
    setOpenDrawer(false);
    setSelectedProject(null);
    setSelectedProjectDeliverables([]);
  };

  const calculateProjectProgress = (projectId) => {
    const filteredDeliverables = deliverables.filter(
      (deliverable) => deliverable.project === projectId
    );
    if (filteredDeliverables.length === 0) return 0;
    const totalProgress = filteredDeliverables.reduce(
      (acc, deliverable) => acc + getStatusProgress(deliverable.status),
      0
    );
    return Math.round(totalProgress / filteredDeliverables.length);
  };

  const getStatusProgress = (status) => {
    switch (status) {
      case "IDR":
        return 45;
      case "ICR":
        return 75;
      case "IFC":
        return 95;
      case "As Built":
        return 100;
      default:
        return 0;
    }
  };

  const getType = (deliverableType) => {
    const deliverable = deliverableTypes.find((item) => item.value === deliverableType);
    return deliverable ? deliverable.title : "P&ID"; // Default to "P&ID" if not found
  };

  useEffect(() => {
    if (projectType) {
      console.log("ProjectType: ", projectType);
      // Call your update function here if needed
      // updateProjectInfo(projectType);
    }
  }, [projectType]);

  const updateProjectInfo = async () => {
    setIsLoading(true); // Start loading
    try {
      await schema.validate(
        { projectNumber, projectName, projectAreas, customer, site, epcm },
        { abortEarly: false }
      );

      const formField = new FormData();
      formField.append("projectNumber", projectNumber);
      formField.append("projectName", projectName);
      formField.append("salesOrderNumber", salesOrderNumber);
      formField.append("invoicingProgress", invoicingProgress);
      formField.append("customer", customer);
      formField.append("epcm", epcm);
      formField.append("site", site);
      formField.append("projectVoteNo", projectVoteNo);
      formField.append("pcn", pcn);
      formField.append("projectAreas", projectAreas);
      formField.append("is_closed", is_closed);

      if (projectType === "open") {
        await api({
          method: "PUT",
          url: `/projects/${selectedProject.id}/`,
          data: formField,
        });
      } else if (projectType === "closed") {
        await api({
          method: "PUT",
          url: `/closedProjects/${selectedProject.id}/`,
          data: formField,
        });
      }

      await fetchProjects();
      await fetchClosedProjects();

      toast.success("Update Success!");
      navigate("/projects");
      handleEditClose();
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        const newErrors = {};
        error.inner.forEach((e) => {
          newErrors[e.path] = e.message;
        });
        setErrors(newErrors);
      } else {
        toast.error("Update Failure!");
      }
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  const handleDeleteClick = (projectId) => {
    setSelectedProject(projectId);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setSelectedProject(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (selectedProject) {
      deleteProject(selectedProject);
    }
  };

  const isLargeScreen = useMediaQuery("(min-width:600px)");

  const calculateProgressColor = (progress) => {
    if (progress >= 0 && progress <= 44) {
      return "red"; // Color for 0-44 progress
    } else if (progress >= 45 && progress <= 75) {
      return "orange"; // Color for 45-75 progress
    } else {
      return "green"; // Color for 76-100 progress
    }
  };

  const onSelectChange = (newSelectedRowKeys) => {
    setSelectedRowKeys(newSelectedRowKeys);
    const selectedProjects = projects.filter((project) =>
      newSelectedRowKeys.includes(project.id)
    );
    setSelectedProjectRows(selectedProjects);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  const hasSelected = selectedRowKeys.length > 0;

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={{
          padding: 8,
        }}
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{
            marginBottom: 8,
            display: "block",
          }}
        />
        <Space>
          <Button2
            onClick={() => clearFilters && handleReset(clearFilters)}
            size="small"
            style={{
              width: 90,
            }}
          >
            Reset
          </Button2>
          <Button2
            type="link"
            size="small"
            onClick={() => {
              confirm({
                closeDropdown: false,
              });
              setSearchText(selectedKeys[0]);
              setSearchedColumn(dataIndex);
            }}
          >
            Filter
          </Button2>
          <Button2
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
          >
            close
          </Button2>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1677ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  const openProjectColumns = [
    {
      title: "No.",
      dataIndex: "no",
      rowScope: "row",
      width: 60,
      fixed: "left",
    },
    {
      title: "Project Number",
      width: 200,
      dataIndex: "projectNumber",
      key: "projectNumber",
      fixed: "left",
      ...getColumnSearchProps("projectNumber"),
      sorter: (a, b) =>
        a.projectNumber.localeCompare(b.projectNumber, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],

      render: (text, params) => (
        <Link
          style={{ textDecoration: "none", color: "#007FFF" }}
          to={`/projects/dashboard/${params.id}/open`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Project Name",
      width: 200,
      dataIndex: "projectName",
      key: "projectName",
      fixed: "left",
      ...getColumnSearchProps("projectName"),
      sorter: (a, b) =>
        a.projectName.localeCompare(b.projectName, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "PCN",
      dataIndex: "pcn",
      key: "pcn",
      width: 100,
      ...getColumnSearchProps("pcn"),
      sorter: (a, b) =>
        a.pcn.localeCompare(b.pcn, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project Areas",
      dataIndex: "projectAreas",
      key: "projectAreas",
      width: 200,
      ...getColumnSearchProps("projectAreas"),
      sorter: (a, b) => a.projectAreas.length - b.projectAreas.length,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project Vote No.",
      dataIndex: "projectVoteNo",
      key: "projectVoteNo",
      width: 200,
      ...getColumnSearchProps("projectVoteNo"),
      sorter: (a, b) =>
        a.projectVoteNo.localeCompare(b.projectVoteNo, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Sales Order Number",
      dataIndex: "salesOrderNumber",
      key: "salesOrderNumber",
      width: 200,
      ...getColumnSearchProps("salesOrderNumber"),
      sorter: (a, b) =>
        a.salesOrderNumber.localeCompare(b.salesOrderNumber, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Invoicing Progress %",
      dataIndex: "invoicingProgress",
      key: "invoicingProgress",
      width: 120,
      sorter: (a, b) =>
        a.invoicingProgress.localeCompare(b.invoicingProgress, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project Progress %",
      dataIndex: "projectProgress",
      key: "projectProgress",
      width: 120,
      sorter: (a, b) =>
        a.projectProgress.localeCompare(b.projectProgress, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 200,
      ...getColumnSearchProps("customer"),
      sorter: (a, b) =>
        a.customer.localeCompare(b.customer, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "EPCM",
      dataIndex: "epcm",
      key: "epcm",
      width: 200,
      ...getColumnSearchProps("epcm"),
      sorter: (a, b) =>
        a.epcm.localeCompare(b.epcm, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      width: 200,
      ...getColumnSearchProps("site"),
      sorter: (a, b) =>
        a.site.localeCompare(b.site, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Modified",
      dataIndex: "projectModified",
      key: "projectModified",
      width: 200,
      ...getColumnSearchProps("projectModified"),
      sorter: (a, b) =>
        a.projectModified.localeCompare(b.projectModified, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Modified by",
      dataIndex: "modified_by",
      key: "modified_by",
      width: 200,
      ...getColumnSearchProps("modified_by"),
      sorter: (a, b) =>
        a.modified_by.localeCompare(b.modified_by, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created",
      dataIndex: "projectCreated",
      key: "projectCreated",
      width: 200,
      ...getColumnSearchProps("projectCreated"),
      sorter: (a, b) =>
        a.projectCreated.localeCompare(b.projectCreated, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      width: 200,
      ...getColumnSearchProps("created_by"),
      sorter: (a, b) =>
        a.created_by.localeCompare(b.created_by, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          {permissions.length > 0 && (
            <>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleEditClick(record.id)}>
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => handleDeleteClick(record.id)}>
                  <Delete color="error" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {isLargeScreen && (
            <Tooltip title="View project">
              <IconButton onClick={() => handlePreviewClick(record.id)}>
                <Info sx={{ color: "#007dab" }} />
              </IconButton>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const closedProjectColumns = [
    {
      title: "No.",
      dataIndex: "no",
      rowScope: "row",
      width: 60,
      fixed: "left",
    },
    {
      title: "Project Number",
      width: 200,
      dataIndex: "projectNumber",
      key: "projectNumber",
      fixed: "left",
      ...getColumnSearchProps("projectNumber"),
      sorter: (a, b) =>
        a.projectNumber.localeCompare(b.projectNumber, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
      render: (text, params) => (
        <Link
          style={{ textDecoration: "none", color: "#007FFF" }}
          to={`/projects/dashboard/${params.id}/closed`}
        >
          {text}
        </Link>
      ),
    },
    {
      title: "Project Name",
      width: 200,
      dataIndex: "projectName",
      key: "projectName",
      fixed: "left",
      ...getColumnSearchProps("projectName"),
      sorter: (a, b) =>
        a.projectName.localeCompare(b.projectName, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "PCN",
      dataIndex: "pcn",
      key: "pcn",
      width: 100,
      ...getColumnSearchProps("pcn"),
      sorter: (a, b) =>
        a.pcn.localeCompare(b.pcn, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project Areas",
      dataIndex: "projectAreas",
      key: "projectAreas",
      width: 200,
      ...getColumnSearchProps("projectAreas"),
      sorter: (a, b) => a.projectAreas.length - b.projectAreas.length,
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project Vote No.",
      dataIndex: "projectVoteNo",
      key: "projectVoteNo",
      width: 200,
      ...getColumnSearchProps("projectVoteNo"),
      sorter: (a, b) =>
        a.projectVoteNo.localeCompare(b.projectVoteNo, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Sales Order Number",
      dataIndex: "salesOrderNumber",
      key: "salesOrderNumber",
      width: 200,
      ...getColumnSearchProps("salesOrderNumber"),
      sorter: (a, b) =>
        a.salesOrderNumber.localeCompare(b.salesOrderNumber, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Invoicing Progress",
      dataIndex: "invoicingProgress",
      key: "invoicingProgress",
      width: 200,
      ...getColumnSearchProps("invoicingProgress"),
      sorter: (a, b) =>
        a.invoicingProgress.localeCompare(b.invoicingProgress, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Project Progress %",
      dataIndex: "projectProgress",
      key: "projectProgress",
      width: 100,
      sorter: (a, b) =>
        a.projectProgress.localeCompare(b.projectProgress, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Customer",
      dataIndex: "customer",
      key: "customer",
      width: 200,
      ...getColumnSearchProps("customer"),
      sorter: (a, b) =>
        a.customer.localeCompare(b.customer, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "EPCM",
      dataIndex: "epcm",
      key: "epcm",
      width: 200,
      ...getColumnSearchProps("epcm"),
      sorter: (a, b) =>
        a.epcm.localeCompare(b.epcm, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Site",
      dataIndex: "site",
      key: "site",
      width: 200,
      ...getColumnSearchProps("site"),
      sorter: (a, b) =>
        a.site.localeCompare(b.site, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Modified",
      dataIndex: "projectModified",
      key: "projectModified",
      width: 200,
      ...getColumnSearchProps("projectModified"),
      sorter: (a, b) =>
        a.projectModified.localeCompare(b.projectModified, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Modified by",
      dataIndex: "modified_by",
      key: "modified_by",
      width: 200,
      ...getColumnSearchProps("modified_by"),
      sorter: (a, b) =>
        a.modified_by.localeCompare(b.modified_by, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created",
      dataIndex: "projectCreated",
      key: "projectCreated",
      width: 200,
      ...getColumnSearchProps("projectCreated"),
      sorter: (a, b) =>
        a.projectCreated.localeCompare(b.projectCreated, undefined, {
          numeric: true,
        }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Created by",
      dataIndex: "created_by",
      key: "created_by",
      width: 200,
      ...getColumnSearchProps("created_by"),
      sorter: (a, b) =>
        a.created_by.localeCompare(b.created_by, undefined, { numeric: true }),
      sortDirections: ["ascend", "descend"],
    },
    {
      title: "Action",
      key: "operation",
      width: 150,
      render: (_, record) => (
        <Space size="middle">
          {permissions.length > 0 && (
            <>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleEditClickClosed(record.id)}>
                  <Edit color="primary" />
                </IconButton>
              </Tooltip>
            </>
          )}
          {isLargeScreen && (
            <Tooltip title="View project">
              <IconButton onClick={() => handlePreviewClickClosed(record.id)}>
                <Info sx={{ color: "#007dab" }} />
              </IconButton>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  const deliverableColumns = [
    {
      title: "No.",
      dataIndex: "no",
      rowScope: "row",
      width: 60,
      fixed: "left",
    },
    {
      title: "Drawing No.",
      width: 200,
      dataIndex: "name",
      key: "name",
      fixed: "left",
      render: (text, record) => (
        <>
          {record.file ? (
            <Link
              style={{ textDecoration: "none", color: "#007FFF" }}
              to={`/pdfviewer?fileUrl=${encodeURIComponent(
                `https://3ebingo.co.za${record.file}`
              )}`}
            >
              {text}
            </Link>
          ) : (
            <Typography sx={{ textDecoration: "none" }}>{text}</Typography>
          )}
        </>
      ),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 200,
    },
    {
      title: "Deliverable Type",
      dataIndex: "deliverableType",
      key: "deliverableType",
      width: 150,
      render: (text, record) => getType(record.deliverableType),
    },
    {
      title: "Progress %",
      dataIndex: "progress",
      key: "progress",
      width: 100,
      render: (text, record) => `${getStatusProgress(record.status)}%`,
    },
    {
      title: "Revision Number",
      dataIndex: "revNumber",
      key: "revNumber",
      width: 200,
    },
    {
      title: "Modified",
      dataIndex: "modified",
      key: "modified",
      width: 100,
    },
    {
      title: "Modified by",
      dataIndex: "modified_by",
      key: "modified_by",
      width: 200,
    },
  ];

  const deliverableRows = selectedProjectDeliverables.map(
    (deliverable, index) => ({
      no: index + 1 + ".",
      ...deliverable,
    })
  );

  const openProjectRows = projects.map((project, index) => ({
    no: index + 1 + ".",
    ...project,
    projectProgress: calculateProjectProgress(project.id) + "%",
  }));

  const closedProjectRows = closedProjects.map((closedProject, index) => ({
    no: index + 1 + ".",
    ...closedProject,
    projectProgress: calculateProjectProgress(closedProject.id) + "%",
  }));

  // const handleDeleteSelected = () => {
  //   confirm({
  //     title: "Are you sure you want to delete these projects?",
  //     content: "Once deleted, the projects will be moved to the Recycle Bin.",
  //     okText: "Yes",
  //     okType: "danger",
  //     cancelText: "No",
  //     onOk() {
  //       selectedRowKeys.forEach((key) => deleteProject(key));
  //       setSelectedRowKeys([]);
  //     },
  //     onCancel() {
  //       console.log("Cancel");
  //     },
  //   });
  // };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <Typography variant="h4" sx={{ textAlign: "center", marginBottom: 4 }}>
        My Projects
      </Typography>
      <Tabs
        value={tabValue}
        sx={{ marginBottom: 4 }}
        onChange={(e, newValue) => setTabValue(newValue)}
        style={{ width: "88vw" }}
      >
        <Tab label="Open Projects" component={RouterLink} to={``} />
        <Tab label="Closed Projects" component={RouterLink} to="" />
      </Tabs>

      {/* Render content based on active tab */}
      {tabValue === 0 ? (
        <div style={{ width: "88%", marginTop: "10px" }}>
          <Box sx={{ display: "flex", width: "100%", direction: "column" }}>
            {permissions.length > 0 && (
              <Button
                sx={{
                  marginBottom: "10px",
                  backgroundColor: "#007DAB",
                  color: "#FFFFFF",
                }}
                component={RouterLink}
                to="/addProject"
                variant="contained"
              >
                <Tooltip title={`Add new project`}>
                  <AddIcon /> New
                </Tooltip>
              </Button>
            )}
            {selectedRowKeys.length > 0 && (
              <div style={{ marginBottom: 16 }}>
                {/* <Button onClick={handleDeleteSelected} style={{color: "red", border: 0}}>
              <DeleteIcon color="error" /> Delete
            </Button> */}
                <React.Fragment>
                  <Button color="primary" onClick={handleOpenModal}>
                    <PersonAdd /> Add members
                  </Button>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <Box sx={{ ...styleModal }}>
                      <h2 id="child-modal-title">Add Members</h2>
                      <Typography variant="h6" sx={{ marginBottom: 2 }}>
                        Add members to this project.
                      </Typography>
                      <Typography sx={{ marginBottom: 2 }}>
                        Project owners have full control of site content, theme,
                        permission settings, site settings, and hub
                        associations.
                      </Typography>
                      <Typography sx={{ marginBottom: 2 }}>
                        Project members can edit and view site content,
                        including files, pages, lists, and navigation.
                      </Typography>
                      <Autocomplete
                        limitTags={3}
                        multiple
                        disableCloseOnSelect
                        id="users"
                        options={users}
                        value={selectedUsers} // Ensure this matches the initial selectedUsers state
                        onChange={(event, newValue) => {
                          setSelectedUsers(newValue);
                        }}
                        getOptionLabel={(option) =>
                          `${option.first_name} ${option.last_name}`
                        }
                        renderOption={(props, option, { selected }) => {
                          const isChecked =
                            selected ||
                            selectedUsers.some((user) => user.id === option.id);
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={isChecked}
                              />
                              {option.first_name} {option.last_name}
                            </li>
                          );
                        }}
                        sx={{ width: "95%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Add members" />
                        )}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          marginTop: 2,
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          onClick={handleCloseModal}
                          variant="outlined"
                          sx={{ color: "gray", borderColor: "gray" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "#007DAB" }}
                          onClick={handleAddMembers}
                        >
                          Save
                        </Button>
                      </Box>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 6,
                          left: 8,
                          color: "#007dab",
                        }}
                        onClick={handleCloseModal}
                      >
                        <ArrowBack />
                      </IconButton>
                    </Box>
                  </Modal>
                </React.Fragment>
              </div>
            )}
          </Box>
          <Divider />
          <Paper style={{ width: "93vw", marginLeft: 10 }} overflow="hidden">
            <Table
              style={{ width: "100%", marginBottom: 8 }}
              rowSelection={rowSelection}
              columns={openProjectColumns}
              dataSource={openProjectRows}
              pagination={false}
              loading={loading}
              rowKey={(record) => record.id}
              size="small"
              scroll={{
                y: 1000,
                x: 1300,
              }}
            />
          </Paper>
        </div>
      ) : (
        <div style={{ width: "88vw", marginTop: "10px" }}>
          <Box sx={{ display: "flex", width: "88%", direction: "column" }}>
            {selectedRowKeys.length > 0 && (
              <div style={{ marginBottom: 16 }}>
                {/* <Button onClick={handleDeleteSelected} style={{color: "red", border: 0}}>
              <DeleteIcon color="error" /> Delete
            </Button> */}
                <React.Fragment>
                  <Button color="primary" onClick={handleOpenModal}>
                    <PersonAdd /> Add members
                  </Button>
                  <Modal
                    open={openModal}
                    onClose={handleCloseModal}
                    aria-labelledby="child-modal-title"
                    aria-describedby="child-modal-description"
                  >
                    <Box sx={{ ...styleModal }}>
                      <h2 id="child-modal-title">Add Members</h2>
                      <Typography variant="h6" sx={{ marginBottom: 2 }}>
                        Add members to this project.
                      </Typography>
                      <Typography sx={{ marginBottom: 2 }}>
                        Project owners have full control of site content, theme,
                        permission settings, site settings, and hub
                        associations.
                      </Typography>
                      <Typography sx={{ marginBottom: 2 }}>
                        Project members can edit and view site content,
                        including files, pages, lists, and navigation.
                      </Typography>
                      <Autocomplete
                        limitTags={3}
                        multiple
                        disableCloseOnSelect
                        id="users"
                        options={users}
                        value={selectedUsers} // Ensure this matches the initial selectedUsers state
                        onChange={(event, newValue) => {
                          setSelectedUsers(newValue);
                        }}
                        getOptionLabel={(option) =>
                          `${option.first_name} ${option.last_name}`
                        }
                        renderOption={(props, option, { selected }) => {
                          const isChecked =
                            selected ||
                            selectedUsers.some((user) => user.id === option.id);
                          return (
                            <li {...props}>
                              <Checkbox
                                icon={icon}
                                checkedIcon={checkedIcon}
                                style={{ marginRight: 8 }}
                                checked={isChecked}
                              />
                              {option.first_name} {option.last_name}
                            </li>
                          );
                        }}
                        sx={{ width: "95%" }}
                        renderInput={(params) => (
                          <TextField {...params} label="Add members" />
                        )}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          width: "100%",
                          marginTop: 2,
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          onClick={handleCloseModal}
                          variant="outlined"
                          sx={{ color: "gray", borderColor: "gray" }}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="contained"
                          sx={{ backgroundColor: "#007DAB" }}
                          onClick={handleAddMembers}
                        >
                          Save
                        </Button>
                      </Box>
                      <IconButton
                        sx={{
                          position: "absolute",
                          top: 6,
                          left: 8,
                          color: "#007dab",
                        }}
                        onClick={handleCloseModal}
                      >
                        <ArrowBack />
                      </IconButton>
                    </Box>
                  </Modal>
                </React.Fragment>
              </div>
            )}
          </Box>
          <Divider />
          <Paper style={{ width: "93vw", marginLeft: 10 }} overflow="hidden">
            <Table
              style={{ width: "100%", marginBottom: 8 }}
              rowSelection={rowSelection}
              columns={closedProjectColumns}
              dataSource={closedProjectRows}
              pagination={false}
              loading={loading}
              rowKey={(record) => record.id}
              size="small"
              scroll={{
                y: 1000,
                x: 1300,
              }}
            />
          </Paper>
        </div>
      )}

      {/* Drawer for edit project */}
      <Modal open={openEditProject} onClose={handleEditClose}>
        <Box sx={styleEditProject}>
          {/* Close button */}
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleEditClose}
          >
            <CloseIcon />
          </IconButton>

          <div className="flex flex-col justify-between">
            <h2 className="flex w-full justify-center">Update Project</h2>
            <div className="flex justify-between my-4">
              <TextField
                label="Project Number"
                value={projectNumber}
                onChange={(e) => setNumber(e.target.value)}
                variant="outlined"
                fullWidth
                required
                error={!!errors.projectNumber}
                helperText={errors.projectNumber}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Project Name"
                value={projectName}
                onChange={(e) => setName(e.target.value)}
                variant="outlined"
                required
                fullWidth
                error={!!errors.projectName}
                helperText={errors.projectName}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Sales Order Number"
                value={salesOrderNumber}
                onChange={(e) => setSalesOrderNumber(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.salesOrderNumber}
                helperText={errors.salesOrderNumber}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Invoicing Progress"
                value={invoicingProgress}
                onChange={(e) => setInvoicingProgress(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.invoicingProgress}
                helperText={errors.invoicingProgress}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Customer Name"
                value={customer}
                onChange={(e) => setCustomer(e.target.value)}
                variant="outlined"
                required
                fullWidth
                error={!!errors.customer}
                helperText={errors.customer}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="EPCM"
                value={epcm}
                onChange={(e) => setEPCM(e.target.value)}
                variant="outlined"
                required
                fullWidth
                error={!!errors.epcm}
                helperText={errors.epcm}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Site"
                value={site}
                onChange={(e) => setSite(e.target.value)}
                variant="outlined"
                required
                fullWidth
                error={!!errors.site}
                helperText={errors.site}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Project Vote Number"
                value={projectVoteNo}
                onChange={(e) => setProjectVoteNo(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.projectVoteNo}
                helperText={errors.projectVoteNo}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="PCN"
                value={pcn}
                onChange={(e) => setPCN(e.target.value)}
                variant="outlined"
                fullWidth
                error={!!errors.pcn}
                helperText={errors.pcn}
              />
            </div>
            <div className="flex justify-between my-4">
              <TextField
                label="Project Areas"
                value={projectAreas}
                onChange={(e) => setprojectAreas(e.target.value)}
                variant="outlined"
                required
                fullWidth
                error={!!errors.projectAreas}
                helperText={errors.projectAreas}
              />
            </div>
            <div className="flex justify-between my-4">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={is_closed} // Corrected prop
                    onChange={(e) => setClosed(e.target.checked)} // Corrected handler
                  />
                }
                label="Closed"
              />
            </div>
            <div className="flex justify-around my-4">
              <Button
                variant="contained"
                onClick={handleEditClose}
                color="error"
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                onClick={updateProjectInfo}
                disabled={isLoading} // Disable button when loading
              >
                {isLoading ? (
                  <CircularProgress size={24} color="inherit" />
                ) : (
                  "Update Project"
                )}
              </Button>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Drawer for Project Preview */}
      <Modal open={openDrawer} onClose={handleDrawerClose}>
        <Box sx={style}>
          {/* Close button */}
          <IconButton
            sx={{ position: "absolute", top: 8, right: 8 }}
            onClick={handleDrawerClose}
          >
            <CloseIcon />
          </IconButton>
          {selectedProject && (
            <div style={{ margin: 10 }}>
              <Typography
                variant="h4"
                sx={{ textAlign: "center", marginBottom: 4 }}
              >
                Project Details
              </Typography>
              <Grid container spacing={2} sx={{ marginBottom: 4 }}>
                <Grid container spacing={2} item xs={8}>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Project Name: {selectedProject.projectName}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Project Number: {selectedProject.projectNumber}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Customer: {selectedProject.customer}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      EPCM: {selectedProject.epcm}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Site: {selectedProject.site}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Project Vote Number: {selectedProject.projectVoteNo}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      PCN: {selectedProject.pcn}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography variant="h6">
                      Project Areas: {selectedProject.projectAreas}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container item xs={4} direction="column">
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    <Typography
                      variant="h5"
                      sx={{ marginRight: 2 }}
                      gutterBottom
                    >
                      Project Progress
                    </Typography>
                    <Box
                      position="relative"
                      display="flex"
                      alignItems="center"
                      justifyContent="center"
                    >
                      <CircularProgress
                        variant="determinate"
                        value={calculateProjectProgress(selectedProject.id)}
                        size={150} // Adjust the size as needed
                        sx={{
                          color: calculateProgressColor(
                            calculateProjectProgress(selectedProject.id)
                          ),
                        }}
                      />
                      <Box
                        position="absolute"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                      >
                        <Typography
                          variant="caption, h6"
                          component="div"
                          color="text.secondary"
                        >
                          {calculateProjectProgress(selectedProject.id)}%
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1-content"
                  id="panel1-header"
                >
                  <Typography variant="h5">
                    Deliverables({selectedProjectDeliverables.length})
                  </Typography>
                </AccordionSummary>

                <AccordionDetails>
                  <Table
                    loading={loading}
                    columns={deliverableColumns}
                    dataSource={deliverableRows}
                    pagination={tableParams.pagination}
                    onChange={(pagination) => setTableParams({ pagination })}
                    rowKey={(record) => record.id}
                    size="small"
                    scroll={{
                      x: 1300,
                    }}
                  />
                </AccordionDetails>
              </Accordion>
            </div>
          )}
        </Box>
      </Modal>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Delete Project</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this project?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleCancelDelete}
            variant="contained"
            style={{ backgroundColor: "#007DAB", color: "#FFFFFF" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ShowProjects;
