import useAxios from "./utils/useAxios";
import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { TextField, Button, CircularProgress, Checkbox, FormControlLabel } from '@mui/material';
import * as yup from 'yup';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const schema = yup.object().shape({
    pcn: yup.string().required('PCN is required'),
});

const UpdatePCN = () => {
    const api = useAxios();
    const [project, setProject] = useState({});
    const [pcn, setPCN] = useState("");
    const [status, setStatus] = useState(false); // Change to boolean
    const [projectAreas, setProjectAreas] = useState("");
    const [comment, setComment] = useState("");
    const [errors, setErrors] = useState({});
    
    const navigate = useNavigate();
    const { id } = useParams();

    const loadPCN = async () => {
        try {
            const response = await api.get(`/projectchangenotification/${id}/`);
            const data = response.data;
            
            // Fetch project details using the project ID from PCN data
            const projectResponse = await api.get(`/projects/${data.project}/`);
            const projectData = projectResponse.data;
            
            setProject(projectData);
            setPCN(data.pcn);
            setProjectAreas(data.projectAreas);
            setStatus(data.status);
            setComment(data.comment);
        } catch (error) {
            console.error("Error loading project:", error);
        }
    };
    

    useEffect(() => {
        loadPCN();
    }, []);

    const CancelTransaction = () => {
        navigate('/projectchangenotification');
    }

    const [isLoading, setIsLoading] = useState(false);

    const updatePCNInfo = async () => {
        setIsLoading(true); // Start loading
        try {
            await schema.validate({ pcn }, { abortEarly: false });
            
            const formField = new FormData();
            formField.append('project', project.id); // Send only project id
            formField.append('pcn', pcn);
            formField.append('projectAreas', projectAreas);
            formField.append('status', status);
            formField.append('comment', comment);

            await api({
                method: 'PUT',
                url: `/projectchangenotification/${id}/`,
                data: formField
            });

            toast.success("Update Success!");
            navigate('/projectchangenotification');
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const newErrors = {};
                error.inner.forEach((e) => {
                    newErrors[e.path] = e.message;
                });
                setErrors(newErrors);
            } else {
                toast.error("Update Failure!");
            }
        } finally {
            setIsLoading(false); // Stop loading
          }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100%', position: 'absolute', top: '120px', alignItems: 'center' }}>
            <h2 className='flex w-full justify-center'>Update PCN</h2>
            <div className='flex w-full justify-center'>
                <div className="w-4/5 md:w-1/3">
                    <div className="flex flex-col justify-between">
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project'
                                value={`${project.projectNumber} - ${project.projectName}`}
                                variant='outlined'
                                fullWidth
                                InputProps={{
                                    readOnly: true,
                                }}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='PCN'
                                value={pcn}
                                onChange={(e) => setPCN(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.pcn}
                                helperText={errors.pcn}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Add Project Areas'
                                value={projectAreas}
                                onChange={(e) => setProjectAreas(e.target.value)}
                                variant='outlined'
                                required
                                fullWidth
                                error={!!errors.projectAreas}
                                helperText={errors.projectAreas}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <FormControlLabel 
                                control={
                                    <Checkbox
                                        checked={status} // Corrected prop
                                        onChange={(e) => setStatus(e.target.checked)} // Corrected handler
                                    />
                                }
                                label="Status"
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Comment'
                                value={comment}
                                onChange={(e) => setComment(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.comment}
                                helperText={errors.comment}
                            />
                        </div>
                        <div className='flex justify-around my-4'>
                            <Button
                                variant='contained'
                                onClick={CancelTransaction}
                                color='error'
                            >
                                Cancel
                            </Button>
                            <Button
                                variant='contained'
                                onClick={updatePCNInfo}
                                disabled={isLoading} // Disable button when loading
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Update'}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpdatePCN;
