import { Grid, Typography } from '@mui/material';
import React from 'react';
import logo from "../images/logo.png";

const ResetPasswordMail = () => {
    
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <img src={logo} alt="Logo" width={"30%"} height={120} marginBottom={10} />
            <Typography variant="h6" gutterBottom>
                An email with instructions has been sent. If the provided email exists, you will receive an email shortly. Check your Spam mail too.
            </Typography>
            
        </Grid>
    );
};

export default ResetPasswordMail;
