import axios from 'axios';
import useAxios from "./utils/useAxios"
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TextField, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Container, Select, MenuItem, FormControl, InputLabel, IconButton, CircularProgress, Box, Autocomplete } from '@mui/material';
import { deliverableTypes, disciplineTypes } from "../menuItems";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import * as yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from '@mui/material/Tooltip';

const schema = yup.object().shape({
    projectNumber: yup.string().required("Project Number is required"),
    projectName: yup.string().required("Project Name is required"),
    customer: yup.string().required("Customer is required"),
    epcm: yup.string().required("EPCM is required"),
    site: yup.string().required("Site is required"),
    projectAreas: yup.string().required("At least one area is required"),
  });

const AddProject = () => {
    const api = useAxios();
    const [rows, setRows] = useState([{ name: '', area: '', voteNo: '', invoicingProgress: '', description: '', disciplineType: '', deliverableType: '', status: '', revNumber: '', file: null }]);
    const [projectNumber, setProjectNumber] = useState('');
    const [salesOrderNumber, setSalesOrderNumber] = useState('');
    const [invoicingProgress, setInvoicingProgress] = useState('');
    const [projectName, setProjectName] = useState('');
    const [customer, setCustomer] = useState('');
    const [epcm, setEPCM] = useState('');
    const [site, setSite] = useState('');
    const [projectVoteNo, setVoteNo] = useState('');
    const [pcn, setPCN] = useState('');
    const [projectAreas, setProjectAreas] = useState([]);
    const [fileNames, setFileNames] = useState([""]); // Array to store file names for each row
    const [errors, setErrors] = useState({});
    const [rowCount, setRowCount] = useState(1);
    const [selectedOption, setSelectedOption] = useState('blank');

    const statusOptions = [
        { title: 'Not Started', value: 'Not Started' },
        { title: 'IDR', value: 'IDR' },
        { title: 'ICR', value: 'ICR' },
        { title: 'IFC', value: 'IFC' },
        { title: 'As Built', value: 'As Built' },
    ];

    const navigate = useNavigate();
    const DELIVERABLES_URL = `/deliverables/`;

    const handleFileChange = (event, index) => {
        const selectedFile = event.target.files[0];
        updateRow(index, selectedFile);
    };

    const handleDrop = (event) => {
        event.preventDefault();
        const files = event.dataTransfer.files;
        const newFileNames = [...fileNames];
        for (let i = 0; i < files.length; i++) {
            const index = rows.length + i;
            const selectedFile = files[i];
            const fileNameWithExtension = selectedFile.name;
            newFileNames[index] = fileNameWithExtension;
            updateRow(index, selectedFile);
        }
        setFileNames(newFileNames);
    };
    

    const updateRow = (index, selectedFile) => {
        const fileNameWithExtension = selectedFile.name;
        
        // Extract the discipline type number from the file name
        const disciplineMatch = fileNameWithExtension.match(/(?:\s|_|-)(\d+)(?:\s|_|-)(\d+)/); // Match the numbers after the first and second space, underscore, or hyphen
        let disciplineType = '';
        if (disciplineMatch) {
            switch(disciplineMatch[2]) {
                case '08':
                    disciplineType = 'Control & Instrumentation';
                    break;
                case '05':
                case '15':
                    disciplineType = 'Process';
                    break;
                case '09':
                    disciplineType = 'Electrical';
                    break;
                default:
                    disciplineType = '';
                    break;
            }
        }
        
        // Extract the area number from the file name
        const areaMatch = fileNameWithExtension.match(/(?:\s|_|-)(\d+)(?:\s|_|-)/); // Match the numbers after the first space, underscore, or hyphen
        const area = areaMatch ? areaMatch[1] : ''; // Extract the first matched number as area number
        
        // Remove file extension
        const fileName = fileNameWithExtension.replace(/\.[^/.]+$/, "");
        
        const newFileNames = [...fileNames];
        newFileNames[index] = fileNameWithExtension;
        setFileNames(newFileNames);
        setRows(prevRows => {
            const newRows = [...prevRows];
            newRows[index] = { 
                ...newRows[index], 
                file: selectedFile,
                name: fileName,
                area: area,
                disciplineType: disciplineType,
                description: fileName,
                invoicingProgress: '0%',
                status: 'Not Started',
                voteNo: '',
                revNumber: '00'
            };
            return newRows;
        });
    };

    const CancelTransaction = () => {
        navigate('/projects');
    }

    const [isLoading, setIsLoading] = useState(false);

    const addProjectInfo = async () => {
        setIsLoading(true); // Start loading

        try {
            await schema.validate(
                {
                projectNumber,
                projectName,
                customer,
                projectAreas,
                epcm,
                site
            },
            { abortEarly: false}
            );

            const projectData = new FormData();
            projectData.append('projectNumber', projectNumber);
            projectData.append('projectName', projectName);
            projectData.append('salesOrderNumber', salesOrderNumber);
            projectData.append('invoicingProgress', invoicingProgress);
            projectData.append('customer', customer);
            projectData.append('epcm', epcm);
            projectData.append('site', site);
            projectData.append('projectVoteNo', projectVoteNo);
            projectData.append('pcn', pcn);
            projectData.append('projectAreas', projectAreas);

            const projectResponse = await api.post('/projects/', projectData);
            toast.success("Project added successfully");
            const projectId = projectResponse.data.id;

            const deliverablesData = rows.map((row) => {
                const deliverable = new FormData();
                deliverable.append("project", projectId);
                deliverable.append("name", row.name);
                deliverable.append("area", row.area);
                deliverable.append("voteNo", row.voteNo);
                deliverable.append("invoicingProgress", row.invoicingProgress);
                deliverable.append("description", row.description);
                deliverable.append("disciplineType", row.disciplineType);
                deliverable.append("deliverableType", row.deliverableType);
                deliverable.append("status", row.status);
                deliverable.append("revNumber", row.revNumber);
                if (row.file !== null) {
                    deliverable.append("file", row.file);
                }
                return deliverable;
            });

            const deliverablesResponse = await axios.all(
                deliverablesData.map((deliverable) => api.post(DELIVERABLES_URL, deliverable))
            );

            console.log("Project added successfully:", projectResponse.data);
            
            console.log("Deliverables added successfully:", deliverablesResponse.map((res) => res.data));
            toast.success("Deliverables added successfully");
            navigate('/projects');
        } catch (error) {
            if (error instanceof yup.ValidationError) {
                const newErrors = {};
                error.inner.forEach((e) => {
                  newErrors[e.path] = e.message;
                });
                setErrors(newErrors);
              } else {
                toast.error("Error adding deliverables", error);
              }
        } finally {
            setIsLoading(false); // Stop loading
          }
    };

    const handleChange = (index, field, value) => {
        const newRows = [...rows];
        newRows[index][field] = value;
        setRows(newRows);
    };

    const handleAddRow = () => {
        setRows([...rows, { name: '', area: '', voteNo: '', invoicingProgress: '', description: '', disciplineType: '', deliverableType: '', status: '', revNumber: '', file: null }]);
        setFileNames([...fileNames, ""]); // Add an empty string for the new row
    };

    const handleDuplicateRow = () => {
        const lastRow = rows[rows.length - 1];
        if (lastRow) {
            const newRows = Array.from({ length: rowCount }, () => ({ ...lastRow }));

            setRows([...rows, ...newRows]);
            setFileNames([...fileNames, ...Array(rowCount).fill('')]); // Add empty strings for the new rows
        }
    };
    
    const handleAddMultipleRows = () => {
        if (selectedOption === 'blank') {
            const newRows = Array.from({ length: rowCount }, () => ({
                name: '',
                area: '',
                voteNo: '',
                invoicingProgress: '',
                description: '',
                disciplineType: '',
                deliverableType: '',
                status: '',
                revNumber: '',
                file: null
            }));

            setRows([...rows, ...newRows]);
            setFileNames([...fileNames, ...Array(rowCount).fill('')]); // Add empty strings for the new rows
        } else if (selectedOption === 'duplicate') {
            const lastRow = rows[rows.length - 1];
            if (lastRow) {
                const newRows = Array.from({ length: rowCount }, () => ({ ...lastRow }));

                setRows([...rows, ...newRows]);
                setFileNames([...fileNames, ...Array(rowCount).fill('')]); // Add empty strings for the new rows
            }
        }
    };

    const handleRemoveRow = (index) => {
        const newRows = [...rows];
        newRows.splice(index, 1);
        setRows(newRows);
        const newFileNames = [...fileNames];
        newFileNames.splice(index, 1); // Remove file name at corresponding index
        setFileNames(newFileNames);
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'column', width: '100vw', position: 'absolute', top: '72px' }}>
            <h2 className='flex w-full justify-center'>Add Project</h2>
            <div className='flex flex-col items-center'>
                <div className='w-4/5 md:w-1/3'>
                    <div className='flex flex-col justify-between'>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Number'
                                value={projectNumber}
                                onChange={(e) => setProjectNumber(e.target.value)}
                                variant='outlined'
                                fullWidth
                                required
                                error={!!errors.projectNumber}
                                helpertext={errors.projectNumber}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Name'
                                value={projectName}
                                onChange={(e) => setProjectName(e.target.value)}
                                variant='outlined'
                                fullWidth
                                required
                                error={!!errors.projectName}
                                helpertext={errors.projectName}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Sales Order Number'
                                value={salesOrderNumber}
                                onChange={(e) => setSalesOrderNumber(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.salesOrderNumber}
                                helpertext={errors.salesOrderNumber}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Invoicing Progress %'
                                value={invoicingProgress}
                                onChange={(e) => setInvoicingProgress(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.invoicingProgress}
                                helpertext={errors.invoicingProgress}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='customer Name'
                                value={customer}
                                onChange={(e) => setCustomer(e.target.value)}
                                variant='outlined'
                                fullWidth
                                required
                                error={!!errors.customer}
                                helpertext={errors.customer}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='EPCM'
                                value={epcm}
                                onChange={(e) => setEPCM(e.target.value)}
                                variant='outlined'
                                fullWidth
                                required
                                error={!!errors.epcm}
                                helpertext={errors.epcm}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Site'
                                value={site}
                                onChange={(e) => setSite(e.target.value)}
                                variant='outlined'
                                fullWidth
                                required
                                error={!!errors.site}
                                helpertext={errors.site}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Vote Number'
                                value={projectVoteNo}
                                onChange={(e) => setVoteNo(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.projectVoteNo}
                                helpertext={errors.projectVoteNo}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Change Notification'
                                value={pcn}
                                onChange={(e) => setPCN(e.target.value)}
                                variant='outlined'
                                fullWidth
                                error={!!errors.pcn}
                                helpertext={errors.pcn}
                            />
                        </div>
                        <div className='flex justify-between my-4'>
                            <TextField
                                label='Project Areas'
                                value={projectAreas}
                                onChange={(e) => setProjectAreas(e.target.value)}
                                variant='outlined'
                                required
                                fullWidth
                                error={!!errors.projectAreas}
                                helpertext={errors.projectAreas}
                            />
                        </div>
                    </div>
                </div>
                <Container sx={{ width: '100vw', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <h3>{'Deliverables'}</h3>
                    <TableContainer  onDrop={handleDrop} onDragOver={(e) => e.preventDefault()} component={Paper} sx={{ width: "90vw" }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Client Drawing No.</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Area</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Vote No.</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Description</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Discipline Type</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Deliverable Type</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Invoicing Progress</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Status</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Revision No.</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>File</TableCell>
                                    <TableCell style={{ fontWeight: 'bold', whiteSpace: 'nowrap' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {rows.map((row, index) => (
                                    <TableRow key={index}>
                                        <TableCell style={{width: '100vw'}}>
                                            <TextField
                                                label="Deliverable"
                                                value={row.name}
                                                onChange={(e) => handleChange(index, 'name', e.target.value)}
                                                sx={{ width: 250 }}
                                                variant="filled"
                                                margin="normal"
                                                required
                                            />
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <TextField
                                                label="Area"
                                                value={row.area}
                                                onChange={(e) => handleChange(index, 'area', e.target.value)}
                                                sx={{ width: 250 }}
                                                variant="filled"
                                                margin="normal"
                                            />
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <TextField
                                                label="Vote No."
                                                value={row.voteNo}
                                                onChange={(e) => handleChange(index, 'voteNo', e.target.value)}
                                                sx={{ width: 250 }}
                                                variant="filled"
                                                margin="normal"
                                            />
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <TextField
                                                label="Description"
                                                value={row.description}
                                                onChange={(e) => handleChange(index, 'description', e.target.value)}
                                                sx={{ width: 250 }}
                                                variant="filled"
                                                margin="normal"
                                                required
                                            />
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <FormControl fullWidth margin="normal">
                                                <Autocomplete
                                                    disablePortal
                                                    id="disciplineType"
                                                    options={disciplineTypes}
                                                    getOptionLabel={(option) => option.title}
                                                    sx={{ width: 250 }}
                                                    value={disciplineTypes.find(opt => opt.title === row.disciplineType) || null}
                                                    onChange={(event, newValue) => handleChange(index, 'disciplineType', newValue ? newValue.title : '')}
                                                    renderInput={(params) => <TextField {...params} label="Discipline Type" variant="filled" />}
                                                />
                                            </FormControl>

                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <FormControl fullWidth margin="normal"
                                                helpertext={errors.deliverableType}>
                                                <Autocomplete
                                                    key={index}
                                                    disablePortal
                                                    id={`deliverableType-${index}`}
                                                    options={deliverableTypes}
                                                    getOptionLabel={(option) => option.title}
                                                    value={deliverableTypes.find(option => option.value === row.deliverableType) || null}
                                                    onChange={(event, newValue) => handleChange(index, 'deliverableType', newValue ? newValue.value : '')}
                                                    sx={{ width: 250 }}
                                                    renderInput={(params) => <TextField {...params} label="Deliverable Type" variant="filled" />}
                                                />
                                            </FormControl>
                                            
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <TextField
                                                label="Invoicing Progress"
                                                value={row.invoicingProgress}
                                                onChange={(e) => handleChange(index, 'invoicingProgress', e.target.value)}
                                                sx={{ width: 250 }}
                                                variant="filled"
                                                margin="normal"
                                                required
                                            />
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <FormControl sx={{ width: 250 }} margin="normal">
                                                <Autocomplete
                                                    disablePortal
                                                    id={`status-${index}`}
                                                    options={statusOptions}
                                                    getOptionLabel={(option) => option.title}
                                                    value={statusOptions.find(option => option.value === row.status) || null}
                                                    onChange={(event, newValue) => handleChange(index, 'status', newValue ? newValue.value : '')}
                                                    sx={{ width: 250, margin: 'normal' }}
                                                    renderInput={(params) => <TextField {...params} label="Status" variant="filled" />}
                                                />
                                            </FormControl>
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <TextField
                                                label="Revision No."
                                                value={row.revNumber}
                                                onChange={(e) => handleChange(index, 'revNumber', e.target.value)}
                                                sx={{ width: 150 }}
                                                variant="filled"
                                                margin="normal"
                                            />
                                        </TableCell>
                                        <TableCell style={{width: '100vw'}}>
                                            <input
                                                type="file"
                                                accept=".pdf,.doc,.docx"
                                                onChange={(e) => handleFileChange(e, index)}
                                                style={{ display: 'none' }}
                                                id={`fileInput-${index}`}
                                            />
                                            <div className="flex justify-between my-4" style={{ width: 250, margin: 'normal' }}>
                                                <label htmlFor={`fileInput-${index}`}>
                                                    <IconButton
                                                        variant="contained"
                                                        component="span"
                                                        style={{ backgroundColor: "#007DAB", color: "white" }}
                                                    >
                                                        <Tooltip title={`Upload file`}>
                                                            <FileUploadIcon />
                                                        </Tooltip>
                                                    </IconButton>
                                                </label>
                                                {row.file && <div className="">{fileNames[index]}</div>}
                                            </div>
                                        </TableCell>
                                        <TableCell style={{width: '20vw'}}>
                                            <IconButton onClick={handleAddRow}>
                                                <Tooltip title={`Add new row`}>
                                                    <AddCircleIcon color='primary' />
                                                </Tooltip>
                                            </IconButton>
                                            <IconButton onClick={handleDuplicateRow}>
                                            <Tooltip title={`Duplicate row`}>
                                                <ContentCopyIcon color='primary' />
                                            </Tooltip>
                                            </IconButton>
                                            <IconButton onClick={() => handleRemoveRow(index)}>
                                            <Tooltip title={`Delete row`}>
                                                <RemoveCircleIcon color='error' />
                                            </Tooltip>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                        <div className='flex justify-around my-4'>
                        <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center', // Align horizontally
                                    alignItems: 'center', // Align vertically if needed
                                    borderRadius: 1,
                                }}
                            >
                                <FormControl fullWidth margin="normal" variant="standard" sx={{marginRight: 2}}>
                                    <InputLabel>State</InputLabel>
                                    <Select
                                        label="State"
                                        value={selectedOption}
                                        onChange={(e) => setSelectedOption(e.target.value)}
                                    >
                                        <MenuItem value="blank">Blank</MenuItem>
                                        <MenuItem value="duplicate">Duplicate above</MenuItem>
                                    </Select>
                                </FormControl>
                                <TextField
                                    label="Row count"
                                    margin="normal"
                                    width="30px"
                                    type="number"
                                    variant="standard"
                                    value={rowCount}
                                    onChange={(e) => setRowCount(parseInt(e.target.value))}
                                />
                                <IconButton
                                    variant='contained'
                                    color='primary'
                                    onClick={handleAddMultipleRows}
                                >
                                    <Tooltip title={`Add rows`}>
                                        <AddCircleIcon />
                                    </Tooltip>
                                </IconButton>
                            </Box>
                        </div>
                    </TableContainer>
                    <div style={{ width: '50vw', display: 'flex', flexDirection: 'row', margin: '10px', justifyContent: 'space-evenly'}}>
                        <Button
                            variant='contained'
                            color='error'
                            onClick={CancelTransaction}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={addProjectInfo}
                            variant="contained"
                            disabled={isLoading} // Disable button when loading
                            >
                            {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Save Project'}
                            </Button>
                    </div>
                </Container>
            </div>
        </div>
    );
};

export default AddProject;
