import axios from "axios";
import { Button, CircularProgress, Grid, InputLabel, Typography } from '@mui/material';
import React, { useState } from 'react';
import { Form, Formik } from 'formik';
import * as Yup from "yup";
import TextFieldWrapper from '../FormComponents/TextFieldWrapper';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../images/logo.png";

const ResetPasswordConfirm = () => {
    const navigate = useNavigate();
    const { uid, token } = useParams();
    const [loading, setLoading] = useState(false); // State for loading

    const resetPassword = async (values) => {
        setLoading(true); // Set loading state to true when request starts
        try {
            const response = await axios.post(`https://3ebingo.co.za/api/password-reset-confirm/${uid}/${token}/`, values);
            if (response.status === 200) {
                toast.success("Password reset success. Login!");
                navigate("/login");
            }
        } catch (error) {
            toast.error(error.response.data.error);
        } finally {
            setLoading(false); // Set loading state to false when request completes
        }
    };
    
    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            height="100vh"
        >
            <img src={logo} alt="Logo" width={"30%"} height={120} marginBottom={10} />
            <Typography variant="h6" gutterBottom>
                Password Reset.
            </Typography>
            <Formik
                initialValues={{
                    new_password: "",
                    re_password: ""
                }}
                validationSchema={Yup.object().shape({
                    new_password: Yup.string().required("This field cannot be empty"),
                    re_password: Yup.string()
                        .required("Passwords do not match")
                        .oneOf([Yup.ref('new_password'), null], 'Passwords must match')
                })}
                onSubmit={(values) => {
                    resetPassword(values);
                }}
            >
                {() => (
                    <Form style={{ width: '100%' }}>
                        <Grid
                            container
                            direction="column"
                            justifyContent="center"
                            alignItems="center"
                            spacing={2}
                        >
                            <Grid item xs={12} sx={{flexDirection: 'column', width: "30vw"}}>
                                <InputLabel sx={{ mb: 1 }}>Password</InputLabel>
                                <TextFieldWrapper
                                    name="new_password"
                                    label="New Password"
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12} sx={{flexDirection: 'column', width: "30vw"}}>
                                <InputLabel sx={{ mb: 1 }}>Confirm Password</InputLabel>
                                <TextFieldWrapper
                                    name="re_password"
                                    label="Confirm Password"
                                    type="password"
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    sx={{backgroundColor: "#007DAB"}}
                                    disabled={loading} // Disable button when loading
                                >
                                    {loading ? (
                                        <CircularProgress size={24} color="inherit" />
                                    ) : (
                                        "Reset Password"
                                    )}
                                </Button>
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Grid>
    );
};

export default ResetPasswordConfirm;
