/* eslint-disable @typescript-eslint/no-use-before-define */
import React, { useState, useEffect } from "react";
import { Link as RouterLink, useParams } from "react-router-dom";
import useAxios from "./utils/useAxios";
import {
  IconButton,
  Paper,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Fab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import { useNavigate } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Queries = () => {
  const api = useAxios();
  const [queries, setQueries] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedDeliverableId, setSelectedDeliverableId] = useState();
  const { projectId } = useParams();
  const [openDialog, setOpenDialog] = useState(false);
  const [search] = useState(projectId);
  const [project, setProject] = useState({});
  const navigate = useNavigate();
  const [permissions, setPermissions] = useState([]);

  const fetchPermissions = async () => {
    try {
      // Make the API request with the access token included in the headers
      const response = await api.get("/user/permissions/");

      // Set the permissions state with the data received from the API
      setPermissions(response.data.permissions);
    } catch (error) {
      console.error("Error fetching permissions:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const projectResponse = await api.get(`/projects/${projectId}/`);
        setProject(projectResponse.data);
        if (projectId) {
          const queryResponse = await api.get(`/projectqueries/`);
          setQueries(queryResponse.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchPermissions();
  }, [projectId]);

  const deleteDeliverable = async (id) => {
    try {
      await api.delete(`/projectqueries/${id}/`);
      setQueries((prevDeliverables) =>
        prevDeliverables.filter((deliverable) => deliverable.id !== id)
      );
      console.log("Deliverable deleted successfully");
      toast.success("Deliverable has been moved to Recycle Bin");
      setOpenDialog(false);
    } catch (error) {
      console.error("Error deleting deliverable:", error);
      toast.error("Error Deleting deliverable!");
    }
  };

  const handleDeleteClick = (deliverableId) => {
    setSelectedDeliverableId(deliverableId);
    setOpenDialog(true);
  };

  const handleCancelDelete = () => {
    setSelectedDeliverableId(null);
    setOpenDialog(false);
  };

  const handleConfirmDelete = () => {
    if (selectedDeliverableId) {
      deleteDeliverable(selectedDeliverableId);
    }
  };

  const columns = [
    {
      field: "ticket",
      headerName: "Deliverable",
      width: 180,
      sortable: true,
      renderHeader: () => <strong>Ticket No.</strong>,
      renderCell: (params) => {
        if (params.row.file) {
          return (
            <RouterLink
              style={{ textDecoration: "none" }}
              onClick={(e) => {
                e.preventDefault();
                const fileUrl = `https://3ebingo.co.za${params.row.file}`;
                navigate(`/pdfviewer?fileUrl=${encodeURIComponent(fileUrl)}`);
              }}
            >
              {params.value}
            </RouterLink>
          );
        } else {
          return params.value;
        }
      },
    },
    {
      field: "project",
      width: 180,
      renderHeader: () => <strong>{"Project "}</strong>,
      editable: true,
    },
    {
      field: "request",
      width: 180,
      renderHeader: () => <strong>{"Request "}</strong>,
      editable: true,
    },
    {
      field: "requestTo",
      width: 180,
      renderHeader: () => <strong>{"Request to"}</strong>,
      editable: true,
    },
    {
      field: "requestby",
      width: 180,
      renderHeader: () => <strong>{"Request by"}</strong>,
      editable: false,
    },
    {
      field: "status",
      width: 180,
      renderHeader: () => <strong>{"Status"}</strong>,
      editable: true,
    },
    {
      field: "notes",
      width: 180,
      renderHeader: () => <strong>{"Notes"}</strong>,
      editable: true,
    },
    {
      field: "priority",
      width: 180,
      renderHeader: () => <strong>{"Priority"}</strong>,
      editable: true,
    },
    {
      field: "action",
      width: 180,
      sortable: false,
      renderHeader: () => (
        <>{permissions.length > 0 && <strong>{"Action"}</strong>}</>
      ),
      renderCell: (params) => (
        <>
          {permissions.length > 0 && (
            <>
              <IconButton
                color="primary"
                size="small"
                component={RouterLink}
                to={`/deliverables/${projectId}/${params.row.id}/update`}
              >
                <EditIcon />
              </IconButton>
              <IconButton
                color="error"
                size="small"
                onClick={() => handleDeleteClick(params.row.id)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          )}
        </>
      ),
    },
  ];

  const rows = queries
    .filter((deliverable) => deliverable.project.toString() === search)
    .map((deliverable) => ({
      id: deliverable.id,
      ticket: 1,
      project: "RBMR",
      request: "I need that thing",
      requestTo: "Mpho Kolobe",
      requestby: "Tshepo Dlhomo",
      status: "Resolved",
      notes: "yeah maybe",
      priority: "High",
    }));

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        position: "absolute",
        top: "120px",
        alignItems: "center",
      }}
    >
      <h2 className="text-xl">
        {project.projectNumber} {project.projectName}
      </h2>

      <>
        {loading ? (
          <p>
            <CircularProgress />
          </p>
        ) : (
          <div style={{ width: "80%", marginTop: "10px" }}>
            {permissions.length > 0 && (
              <Fab
                sx={{ alignItems: "center", marginBottom: "8px" }}
                component={RouterLink}
                to={`/deliverables/${projectId}/addDeliverable`}
                variant="contained"
                style={{
                  backgroundColor: "#007DAB",
                  color: "#FFFFFF",
                  marginTop: "10px",
                }}
              >
                <AddIcon />
              </Fab>
            )}
            <Paper style={{ width: "100%", overflow: "hidden" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[10, 25, 100]}
                disableSelectionOnClick
                slotProps={{
                  toolbar: { projectId },
                }}
              />
            </Paper>
          </div>
        )}
      </>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDialog} onClose={handleCancelDelete}>
        <DialogTitle>Delete Deliverable</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this deliverable?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ justifyContent: "space-around" }}>
          <Button
            onClick={handleCancelDelete}
            variant="contained"
            style={{ backgroundColor: "#007DAB", color: "#FFFFFF" }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleConfirmDelete}
            variant="contained"
            color="error"
          >
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Queries;
