import React, { useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import Link from "@mui/material/Link";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import image from "../images/AdobeStock_90470782.jpeg";
import logo from "../images/logo.png";
import { Button, CircularProgress, Stack } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import TextFieldWrapper from "../FormComponents/TextFieldWrapper";

const SignUp = () => {
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false); // State for loading

  const navigate = useNavigate();

  const handleSubmitForm = async (values) => {
    const { email, password, re_password } = values;

    if (password !== re_password) {
      setErrors({ re_password: "Passwords do not match" });
      return;
    }

    setLoading(true); // Set loading state to true when request starts

    try {
      const lowercaseEmail = email.toLowerCase(); // Convert email to lowercase
      const response = await axios.post("https://3ebingo.co.za/api/register/", {
        ...values,
        email: lowercaseEmail, // Replace email with the lowercase version
      });
      if (response.status === 201) {
        toast.success(
          "Account created successfully, please check your email for OTP."
        );
        navigate(`/check-mail`);
      }
    } catch (err) {
      if (err.response) {
        setErrors(err.response.data);
        toast.error(err.response.statusText);
      }
    } finally {
      setLoading(false); // Set loading state to false when request completes
    }
  };

  return (
    <Grid
      container
      component="main"
      sx={{ height: "100vh", backgroundColor: "secondary.main" }}
    >
      <CssBaseline />
      <Grid
        item
        xs={false}
        sm={4}
        md={7}
        sx={{
          backgroundImage: `url(${image})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      />
      <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
        <Grid>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <img src={logo} alt="logo" />

            <Typography component="h1" variant="h5">
              Sign up
            </Typography>
            <Stack width="100%">
              <Formik
                initialValues={{
                  first_name: "",
                  last_name: "",
                  email: "",
                  password: "",
                  re_password: "",
                }}
                validationSchema={Yup.object().shape({
                  email: Yup.string().required("Please enter your email"),
                  password: Yup.string().required("Password required"),
                  re_password: Yup.string()
                    .required("Confirm Password required")
                    .oneOf([Yup.ref("password"), null], "Passwords must match"),
                  first_name: Yup.string().required("First Name is required"),
                  last_name: Yup.string().required("Last Name is required"),
                })}
                onSubmit={(values, { setErrors }) => {
                  handleSubmitForm(values, { setErrors });
                }}
              >
                {() => {
                  return (
                    <Form sx={{ mt: 3 }}>
                      <Grid container spacing={2}>
                        <Grid item xs={12} sm={6}>
                          <TextFieldWrapper
                            autoComplete="given-name"
                            name="first_name"
                            required
                            fullWidth
                            id="first_name"
                            label="First Name"
                            autoFocus
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <TextFieldWrapper
                            required
                            fullWidth
                            id="lastName"
                            label="Last Name"
                            name="last_name"
                            autoComplete="family-name"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldWrapper
                            required
                            fullWidth
                            id="email"
                            label="Email Address"
                            name="email"
                            autoComplete="email"
                          />
                          <Typography sx={{ color: "red" }}>
                            {errors.email}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldWrapper
                            required
                            fullWidth
                            name="password"
                            label="Password"
                            type="password"
                            id="password"
                            autoComplete="new-password"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextFieldWrapper
                            required
                            fullWidth
                            name="re_password"
                            label="Confirm Password"
                            type="password"
                            id="re_password"
                            autoComplete="confirm-password"
                          />
                        </Grid>
                      </Grid>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2, bgcolor: "secondary.main" }}
                        disabled={loading} // Disable button when loading
                      >
                        {loading ? (
                          <CircularProgress size={24} color="inherit" />
                        ) : (
                          "Sign Up"
                        )}
                      </Button>
                      <Grid container justifyContent="flex-end">
                        <Grid item>
                          <Link
                            sx={{ textDecoration: "none", cursor: "pointer" }}
                            onClick={() => navigate("/login")}
                            variant="body2"
                          >
                            Already have an account? Sign in
                          </Link>
                        </Grid>
                      </Grid>
                    </Form>
                  );
                }}
              </Formik>
            </Stack>
          </Box>
        </Grid>
        <Typography
          variant="h7"
          sx={{
            position: "absolute",
            bottom: "0",
            left: "80%",
            display: "flex",
            textAlign: "center",
          }}
        >
          <a
            href="mailto:samkelom@frequencyshift.co.za?subject=Support Request&cc=makhabanem@frequencyshift.co.za"
            style={{ textDecoration: "none", color: "#007DAB" }}
          >
            Support
          </a>
        </Typography>
      </Grid>
    </Grid>
  );
};

export default SignUp;
